.a-meta-link {
  align-items: center;
  background: transparent;
  border: 0;
  color: $color-metal;
  display: flex;
  font-size: $font-size-metalink;
  line-height: 17/14;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-red-125;
  }

  > .a-icon {
    margin-right: em(4, 14);
  }
}
