.plf-login {
  .plf-login-title {
    color: $colorRed;
    font-size: $fontSizeLoginTitle;
    font-weight: 700;
  }

  .plf-login-content {
    a,
    input {
      display: block;
      max-width: $loginButtonMaxWidth;
      width: 100%;
    }

    a {
      margin-top: rem(20);
    }

    a,
    button {
      background-color: $colorRed;
      border: 0;
      height: $loginButtonHeight;
      line-height: $loginButtonHeight;
      text-align: center;
      vertical-align: middle;

      &,
      * {
        color: $colorWhite;
        font-family: $sbb;
        font-size: $fontSizeLoginButton;
        font-weight: 200;
        text-decoration: none;
      }

      &:hover {
        background-color: $colorRedDark;
      }
    }

    span {
      font-size: $fontSizeLoginText;
    }

    h3 {
      margin-bottom: 0;
    }

    div:not(.plf-login-external) h3 {
      margin-top: rem(45);
    }

    i {
      font-size: rem(22);
      position: relative;
      top: rem(4);
    }

    .plf-login-form {
      padding-top: rem(45);

      label {
        font-size: $fontSizeLoginText;
        font-weight: 200;
      }

      input {
        border: rem(2) solid $colorAlmostGray;
        border-radius: rem(3);
        height: $loginButtonHeight;
        padding: 0 rem(10);
      }

      button {
        width: $loginButtonWidth;
      }

      h3,
      > div:first-of-type {
        margin-bottom: rem(20);
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  @import 'mobile/login';
}
