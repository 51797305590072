.plf-diagram-wrapper {
  padding: 0;

  hr {
    padding: 0 rem(-4);
  }

  > div {
    display: inline-block;
  }

  .plf-diagram-text-wrapper {
    text-align: center;
  }

  .plf-diagram-container {
    margin: 0 auto;
  }
}
