// < 421px
//--------------------------------------------------------------------------------------------------
@media (max-width: $screenXsMax) {
  .container-fluid {
    margin: 0 $gridViewPortSpacingXSmall;
    padding: 0;
    width: auto;
  }

  .row {
    margin-left: -$gridGutterXSmall/2;
    margin-right: -$gridGutterXSmall/2;
  }

  .column {
    padding-left: $gridGutterXSmall/2;
    padding-right: $gridGutterXSmall/2;
  }
}

// 421px - 642px
//--------------------------------------------------------------------------------------------------
@media (min-width: $screenSmMin) and (max-width: $screenSmMax) {
  .container-fluid {
    margin: 0 $gridViewPortSpacingSmall;
    padding: 0;
    width: auto;
  }

  .row {
    margin-left: -$gridGutterSmall/2;
    margin-right: -$gridGutterSmall/2;
  }

  .column {
    padding-left: $gridGutterSmall/2;
    padding-right: $gridGutterSmall/2;
  }
}

// 769px - 1024px
//--------------------------------------------------------------------------------------------------
@media (min-width: $screenSmlMin) and (max-width: $screenSmlMax) {
  .container-fluid {
    margin: 0 $gridViewPortSpacingSmallLandscape;
    padding: 0;
    width: auto;
  }

  .row {
    margin-left: -$gridGutterSmallLandscape/2;
    margin-right: -$gridGutterSmallLandscape/2;
  }

  .column {
    padding-left: $gridGutterSmallLandscape/2;
    padding-right: $gridGutterSmallLandscape/2;
  }
}

// 1025px - 1280px
//--------------------------------------------------------------------------------------------------
@media (min-width: $screenMdMin) and (max-width: $screenMdMax) {
  .container-fluid {
    margin: 0 $gridViewPortSpacingMedium;
    padding: 0;
    width: auto;
  }

  .row {
    margin-left: -$gridGutterMedium/2;
    margin-right: -$gridGutterMedium/2;
  }

  .column {
    padding-left: $gridGutterMedium/2;
    padding-right: $gridGutterMedium/2;
  }
}

// 1281px - 1920px
//--------------------------------------------------------------------------------------------------
@media (min-width: $screenNormalMin) and (max-width: $screenNormalMax) {
  .container-fluid {
    margin: 0 $gridViewPortSpacingNormal;
    padding: 0;
    width: auto;
  }

  .row {
    margin-left: -$gridGutterNormal/2;
    margin-right: -$gridGutterNormal/2;
  }

  .column {
    padding-left: $gridGutterNormal/2;
    padding-right: $gridGutterNormal/2;
  }
}

// 1921px - 2560px
//--------------------------------------------------------------------------------------------------
@media (min-width: $screenLgMin) {
  .container-fluid {
    margin: 0 auto;
    max-width: $gridViewPortWidthLarge;
    padding: 0;
    width: auto;
  }

  .row {
    margin-left: -$gridGutterLarge/2;
    margin-right: -$gridGutterLarge/2;
  }

  .column {
    padding-left: $gridGutterLarge/2;
    padding-right: $gridGutterLarge/2;
  }
}

@media (max-width: $screenMdMin) {
  .hidden-small {
    display: none !important;
  }
}

@media (min-width: $screenMdMin) {
  .hidden-large {
    display: none !important;
  }
}
