.a-link {
  @include link;

  &--footer {
    color: $color-granite;
    font-size: em(14, $footer-section-base-size);
    line-height: (21 / 14);

    .a-icon {
      color: currentColor !important;
    }
  }

  &--footer-bottom {
    color: $color-granite;
    font-size: em(12, $font-size-regular-px);
    line-height: (18 / 12);
    text-decoration: none;
  }
}
