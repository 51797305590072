.o-footer {
  &__sections-container {
    background: $color-cloud;
    z-index: z-index(footer, sectionsContainer);

    @media (min-width: $screen-md-min) {
      padding: rem(80) 0;
    }
  }

  &--fixed {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    max-height: calc(100vh - #{$header-height-mobile});
    padding-top: rem($footer-fixed-padding-top) !important;
    position: fixed;
    right: 0;
    z-index: z-index(page-layout, footer);

    @media (min-width: $screen-sm-min) {
      max-height: calc(100vh - #{$header-height-tablet});
    }

    @media (min-width: $screen-sml-min) {
      max-height: calc(100vh - #{$header-height-desktop});
    }

    .o-footer__sections-container {
      @media (min-width: $screen-md-min) {
        padding: rem(80 - $footer-fixed-padding-top) 0;
      }
    }
  }

  &__toggable {
    display: none;
    flex: 1 1 auto;
    overflow: auto;

    &--active {
      display: block;
    }
  }

  &__toTopButton {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: bottom 0.3s ease;
    z-index: z-index(footer, toTopButton);

    .container-fluid {
      position: relative;
      text-align: right;
    }

    &[data-fixed='false'] {
      bottom: 0;
      height: 0;
      position: fixed;
      top: auto;
    }

    .m-toTopLabel {
      bottom: rem(80);
      position: absolute;
      right: 0;

      @media (max-width: $screen-sm-max) {
        &__content {
          padding: 0 rem(10);

          > span {
            display: none;
          }
        }
      }
    }
  }

  .o-footer-bottom {
    z-index: z-index(footer, footerBottom);
  }
}
