.plf-dashboard-wrapper {
  background-color: $color-white;

  // Grid resets to get a full width row
  //------------------------------------------------------------------------
  @media (max-width: $screenSmMax) {
    .plf-dashboard-tile-wrapper {
      width: auto;

      @media (max-width: $screenXsMax) {
        margin-left: -$gridGutterXSmall / 2;
        margin-right: -$gridGutterXSmall / 2;
      }

      @media (min-width: $screenSmMin) and (max-width: $screenSmMax) {
        margin-left: -$gridGutterSmall / 2;
        margin-right: -$gridGutterSmall / 2;
      }

      @media (min-width: $screenSmlMin) and (max-width: $screenSmlMax) {
        margin-left: -$gridGutterSmallLandscape / 2;
        margin-right: -$gridGutterSmallLandscape / 2;
      }
    }
  }

  .plf-dashboard-title {
    padding: $dashboardTitleSpacingBottomSmall 0;
  }

  .plf-dashboard-tiles-wrapper {
    background-color: $color-white;

    .plf-dashboard-tile-wrapper {
      .plf-dashboard-tile {
        .plf-dashboard-tile-meta-wrapper {
          .plf-dashboard-tile-meta {
            bottom: $dashboardTileSpacingVSmall;
            left: $dashboardTileSpacingHSmall;
            position: absolute;
            right: $dashboardTileSpacingHSmall;
          }
        }
      }
    }
  }
}
