//------------------------------------------------------------------------
// Help function
//------------------------------------------------------------------------
.plf-help-tables-wrapper {
  input[type='checkbox'] {
    left: rem(-9999);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: rem(-9999);
    z-index: $zIndex100Negative;

    &:checked {
      ~ .plf-help-tables {
        * {
          color: $colorRedDark;
        }
      }

      ~ .plf-help-tables-overlay {
        display: block;
      }
    }
  }

  .plf-help-tables-overlay {
    bottom: 0;
    display: none;
    font-size: 0;
    height: 80%;
    left: 0;
    margin: auto;
    max-height: rem(540);
    position: fixed;
    right: 0;
    top: 0;
    width: 40%;
    z-index: $zIndex10000;

    &::before {
      background-color: rgba($colorGrayLight, 0.8);
      content: ' ';
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: $zIndex1;
    }

    .plf-help-overlay-content {
      background-color: $colorWhite;
      display: flex;
      flex-direction: column;
      height: 100%;

      padding: rem(20) rem(25);
      position: relative;

      text-align: left;
      width: 100%;
      z-index: $zIndex3000;

      button {
        background-color: $colorGrayAlmostDark;
        border: 0;
        border-radius: 0;
        height: rem(35);
        transition: background 0.3s;
        width: 100%;

        &.plf-help-close-overlay {
          background-color: transparent;
          position: absolute;
          right: rem(25);
          top: rem(20);
          width: rem(35);

          i {
            color: $colorBlack;
            font-size: rem(25);
            left: rem(-3);
            position: relative;
            top: rem(3);
          }

          &:hover {
            background-color: transparent;

            i {
              color: $colorRedDark;
            }
          }
        }


        span {
          color: $colorWhite;
          font-size: rem(16);
          line-height: rem(35);
        }

        &:hover {
          background-color: $colorRed;
        }

        &[data-disabled='true'] {
          background-color: $colorGrayLight;
          opacity: 0.8;
          pointer-events: none;

          &:hover {
            cursor: not-allowed;
          }
        }
      }

      ul {
        flex-grow: 2;
        list-style: none;
        margin: 0;
        padding: 0;


        li {
          padding: rem(5) 0;

          input[type='checkbox'] {
            &:checked {
              ~ label {
                .plf-cbx-square {
                  i {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }

      .plf-help-popup-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;

        button {
          bottom: rem(30);
          left: rem(30);
          position: absolute;
          width: calc(100% - #{rem(60)
        });
        }

        @media screen and (min-width: $screenSmlMin) {
          button {
            left: 37.5%;
            width: 25%;
          }
        }
      }
    }
  }

  .plf-help-overlay {
    height: 85%;
    max-height: 90vh;
    max-width: rem(1400);
    width: 50%;
  }

  .plf-help-tables {
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }

    * {
      color: $colorGray;
    }

    i {
      font-size: rem(24);
      top: rem(1);
    }
  }

  @media screen and (max-width: $screenMdMax) {
    .plf-help-overlay {
      height: 85%;
      max-height: 90vh;
      max-width: rem(1200);
      width: 75%;
    }
  }

  @media screen and (max-width: $screenSmlMax) {
    .plf-help-overlay {
      height: 90%;
      max-height: 90vh;
      max-width: rem(1000);
      width: 85%;
    }
  }

  @media screen and (max-width: $screenSmMax) {
    .plf-help-overlay {
      height: auto;
      max-height: 98vh;
      max-width: 98vw;
      width: auto;
    }
  }

}
//
////------------------------------------------------------------------------
//// Print function
////------------------------------------------------------------------------
//.plf-print-tables-wrapper {
//  input[type='checkbox'] {
//    left: rem(-9999);
//    opacity: 0;
//    pointer-events: none;
//    position: absolute;
//    top: rem(-9999);
//    z-index: $zIndex-100;
//
//    &:checked {
//      ~ .plf-print-tables {
//        * {
//          color: $colorRedDark;
//        }
//      }
//
//      ~ .plf-print-tables-overlay {
//        display: block;
//      }
//    }
//  }
//
//  .plf-print-tables-overlay {
//    bottom: 0;
//    display: none;
//    font-size: 0;
//    height: 80%;
//    left: 0;
//    margin: auto;
//    max-height: rem(540);
//    position: fixed;
//    right: 0;
//    top: 0;
//    width: 40%;
//    z-index: $zIndex10000;
//
//    &::before {
//      background-color: rgba($colorGrayLight, 0.8);
//      content: ' ';
//      height: 100%;
//      left: 0;
//      position: fixed;
//      top: 0;
//      width: 100%;
//      z-index: $zIndex1;
//    }
//
//    .plf-print-overlay-content {
//      background-color: $colorWhite;
//      display: flex;
//      flex-direction: column;
//      height: 100%;
//
//      padding: rem(20) rem(25);
//      position: relative;
//
//      text-align: left;
//      width: 100%;
//      z-index: $zIndex3000;
//
//      button {
//        background-color: $colorGrayAlmostDark;
//        border: 0;
//        border-radius: 0;
//        height: rem(35);
//        transition: background 0.3s;
//        width: 100%;
//
//        &.plf-print-close-overlay {
//          background-color: transparent;
//          position: absolute;
//          right: rem(25);
//          top: rem(20);
//          width: rem(35);
//
//          i {
//            color: $colorBlack;
//            font-size: rem(25);
//            left: rem(-3);
//            position: relative;
//            top: rem(3);
//          }
//
//          &:hover {
//            background-color: transparent;
//
//            i {
//              color: $colorRedDark;
//            }
//          }
//        }
//
//
//        span {
//          color: $colorWhite;
//          font-size: rem(16);
//          line-height: rem(35);
//        }
//
//        &:hover {
//          background-color: $colorRed;
//        }
//
//        &[data-disabled='true'] {
//          background-color: $colorGrayLight;
//          opacity: 0.8;
//          pointer-events: none;
//
//          &:hover {
//            cursor: not-allowed;
//          }
//        }
//      }
//
//      ul {
//        flex-grow: 2;
//        list-style: none;
//        margin: 0;
//        padding: 0;
//
//
//        li {
//          padding: rem(5) 0;
//
//          input[type='checkbox'] {
//            &:checked {
//              ~ label {
//                .plf-cbx-square {
//                  i {
//                    opacity: 1;
//                  }
//                }
//              }
//            }
//          }
//
//          label {
//            margin: 0;
//
//            * {
//              color: $colorBlack;
//              display: inline-block;
//              font-size: rem(16);
//              line-height: rem(20);
//              vertical-align: middle;
//            }
//
//            .plf-cbx-square {
//              border: rem(2) solid $colorGray;
//              height: rem(20);
//              width: rem(20);
//
//              i {
//                color: $colorRedDark;
//                font-size: rem(28);
//                left: rem(-6);
//                opacity: 0;
//                position: relative;
//                top: rem(-6);
//                transition: opacity 0.2s;
//              }
//            }
//
//            .plf-cbx-text {
//              padding-left: rem(10);
//            }
//          }
//        }
//      }
//    }
//  }
//
//  .plf-help-overlay {
//    height: 80%;
//    max-height: 70vh;
//    width: 60%;
//  }
//
//  .plf-print-tables {
//    background-color: transparent;
//    margin-bottom: 0;
//
//    &:hover {
//      cursor: pointer;
//    }
//
//    * {
//      color: $colorGray;
//    }
//
//    i {
//      font-size: rem(24);
//      top: rem(1);
//    }
//  }
//
//}
