@charset "utf-8";

@import 'global/functions';
@import 'app/settings/z-Indices';
@import 'app/settings/spacings';
@import 'app/settings/gridconfig';
@import 'app/settings/colors';

$link-hover-decoration: none;
$line-height-base: 1.73333;

@import '../templates/ui/src/styles/index';

@import 'app/settings/fonts/sbb-bootstrap-breakpoins';
@import '~bootstrap-sass/assets/stylesheets/bootstrap-sprockets';
@import '~toastr';
@import '~image-picker/image-picker/image-picker.css';

// Core variables and mixins
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Reset and dependencies
@import '~bootstrap-sass/assets/stylesheets/bootstrap/normalize';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/print';

// Core CSS
@import '~bootstrap-sass/assets/stylesheets/bootstrap/type';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/code';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/grid';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/tables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/forms';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/buttons';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/carousel';

// Utility classes
@import '~bootstrap-sass/assets/stylesheets/bootstrap/utilities';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';

// Include fonts at root, because they'll not work if wrapped
@import 'app/settings/fonts';

// view modules
@import 'app/components/interlay';
@import '../../../platform/modules/common/systeminfo/scss/systeminfo';

html {
  scroll-padding-top: rem(144);

  @media (min-width: $screenMdMin) {
    scroll-padding-top: rem(210);
  }
}

body {
  box-sizing: border-box;
  font-family: $sbb;
  height: 100%;

  &[data-scroll='false'] {
    overflow: hidden;
  }

  #toast-container > div {
    opacity: 1;
  }

  hr {
    margin: rem(20) 0;
  }
}


.sv-view-wrapper {
  @import 'app/grid';
  @import 'app/settings/dimensions';

  min-height: 100%;
  overflow-x: hidden;
  position: relative;

  .sv-widget + .sv-widget {

    @media (max-width: $screenXsMax) {
      margin-top: $gridGutterXSmall / 2;
    }

    @media (min-width: $screenSmMin) and (max-width: $screenSmMax) {
      margin-top: $gridGutterSmall / 2;
    }

    @media (min-width: $screenSmlMin) and (max-width: $screenSmlMax) {
      margin-top: $gridGutterSmallLandscape;
    }

    @media (min-width: $screenMdMin) and (max-width: $screenMdMax) {
      margin-top: $gridGutterMedium;
    }

    @media (min-width: $screenNormalMin) and (max-width: $screenNormalMax) {
      margin-top: $gridGutterNormal;
    }

    @media (min-width: $screenLgMin) and (max-width: $screenLgMax) {
      margin-top: $gridGutterLarge;
    }

    @media (min-width: $screenXlgMin) and (max-width: $screenXlgMax) {
      margin-top: $gridGutterXLarge;
    }

    @media (min-width: $screenXxlMin) {
      margin-top: $gridGutterXXLarge;
    }
  }

  .plf-content {
    // Settings import
    @import 'app/settings/typo';
    @import 'app/pdf-content';

    @import 'app/tools/mixins';
    @import 'app/settings/resets';

    // Component import
    @import 'app/components/help_dialogue';

    @import 'app/components/sbb-checkbox';
    @import 'app/components/accordion';
    @import 'app/components/comments';
    @import 'app/components/login';
    @import 'app/components/dashboard';
    @import 'app/components/slider';
    @import 'app/components/zafa-widget';
    @import 'app/components/zafa-widget-popup';
    @import 'app/components/error-pages';
    @import 'app/components/search';
    @import 'app/components/tooltip';
    @import 'app/components/zafa-row-tooltip';
    @import 'app/components/zafa-deeplink';

    // Widget imports
    @import '../../../platform/widgets/view/accordion/scss/widget';
    @import '../../../platform/widgets/view/linklist/scss/linklist';

    padding-bottom: rem(40);
    padding-top: rem(94);

    * {
      font-weight: 300;
    }

    a:hover,
    a:focus {
      color: $color-red-125;

      font {
        color: $color-red-125 !important;
      }
    }
  }

  @media (min-width: $screenMdMin) {
    .plf-content {
      padding-bottom: $contentSpacingBottom;
      padding-top: rem(124);
    }
  }

  @media (min-width: $zafaBeginDesktopBreakpoint) {
    .plf-content {
      padding-top: rem(190);
    }
  }

  .plf-content {
    .plf-article-lead {
      text-align: center;
    }

    .plf-zafa-table-init-warning {
      background-color: #e5e5e5;
      border-radius: rem(5);
      left: 20%;
      padding: rem(10);
      position: fixed;
      top: 50%;
      z-index: $zIndex1000;
    }

    @import 'app/components/user-survey';
  }
}

.sv-inpage-wrapper ~ .sv-view-wrapper {
  .plf-header {
    position: static;

    &,
    * {
      transition: none;
    }
  }
}

@media print {
  @import 'app/settings/colors';
  @import 'app/print';
}
