//---------------------------------
// PDF Content
//---------------------------------
&[data-pdf='true' ] {
  margin: 0 auto;
  max-width: rem(1024);
  padding: 0;

  .page-break-before {
    page-break-before: always;
  }

  //---------------------------------
  // Hide elements
  //---------------------------------
  #cluetip,
  #cluetip-waitimage,
  .sv-widget-debugbar,
  .plf-zafa-head,
  .plf-zafa-functions-cell,
  .plf-zafa-collapse-cell,
  .plf-dashboard-wrapper {
    display: none;
  }

  //---------------------------------
  // Reset Grid
  //---------------------------------
  .container-fluid,
  .container {
    margin: 0;
    max-width: none;
    width: 100%;
  }

  .plf-content {
    padding: 0;
  }

  .plf-pdf-header {
    margin-bottom: rem(50);

    h2 {
      font-size: rem(21);
    }

    img {
      width: rem(225);
    }
  }

  .footer-text-reset {
    margin-bottom: rem(-48) !important;
    padding: 0;

  }

  //---------------------------------
  // Title page
  //---------------------------------
  .plf-zafa-pdf-titlepage {
    page-break-after: always;

    img {
      width: 100%;
    }

    .plf-zafa-pdf-titlepage-meta {
      text-align: center;

      h1 {
        padding: rem(140) 0 rem(80);
      }
    }
  }

  // Imprint
  .plf-zafa-pdf-imprint {
    padding-top: rem(25);
    page-break-after: always;

    h1 {
      margin-bottom: rem(60);
    }

    .plf-pdf-zafa-imprint-meta {
      padding-bottom: rem(25);

      strong {
        font-weight: 600;
      }
    }

    .is-block {
      display: block;
      padding-bottom: rem(8);
    }
  }

  //---------------------------------
  // Footnotes
  //---------------------------------
  .plf-footnote-references {
    padding-top: rem(25);
    page-break-before: always;

    h1 {
      margin-bottom: rem(60);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {

        display: flex;
        flex-direction: row;
        padding: rem(10);
        page-break-inside: avoid;

        &:nth-of-type(2n) {
          background-color: $colorGrayLighter;
        }

        span {
          font-size: rem(16);

          &:first-of-type {
            flex-grow: 2;
            font-weight: 500;
            padding-right: rem(20);
          }

          &:last-of-type {
            width: 100%;
          }
        }
      }
    }
  }

  //---------------------------------
  // Format ZaFa Table
  //---------------------------------
  .plf-zafa-table {
    margin: 0;
    width: 100%;

    .plf-zafa-row {
      border-color: transparent;
      page-break-inside: avoid;

      .hidden-large {
        display: none;
      }

      .plf-zafa-cell {
        &.plf-zafa-footnote-reference {
          font-size: rem(16);
          font-weight: 200;
        }

        &[data-last-visible='true'] {
          padding-right: rem(25);
        }
      }

      td {
        &[data-type='position'] {
          padding-left: rem(35);
        }
      }

      &[data-level='1'] {
        td {
          &[data-type='position'] {
            padding-left: rem(40);
          }
        }
      }

      &[data-level='2'] {
        td {
          &[data-type='position'] {
            padding-left: rem(55);
          }
        }
      }

      &[data-level='3'] {
        td {
          &[data-type='position'] {
            padding-left: rem(70);
          }
        }
      }

      &[data-level='4'] {
        td {
          &[data-type='position'] {
            padding-left: rem(85);
          }
        }
      }

      &.odd {
        background-color: darken($colorGrayLighter, 0.2);
      }
    }
  }
}
