.m-header-actions {
  @media (min-width: $screen-sm-min) {
    padding: rem(8) 0;
  }

  &__item {
    margin-left: rem(16);

    @media (max-width: $screen-xs-header-break) {
      margin-left: rem(4);
    }
  }

  > ul {
    display: flex;
    margin: 0;

    li {
      align-items: center;
      list-style: none;
    }

    @media (max-width: $screen-s-max) {
      padding: 0;
    }
  }

  &__additionalItem {
    height: rem(48);
    padding: rem(11) 0 rem(11) 0;

    @media (max-width: $screen-sm-header-break) {
      .a-meta-link__label {
        display: none;
      }
    }
  }
}

