.o-header-top__collapse-trigger {
  color: $color-iron;

  > div {
    @include valign;
    height: 100%;
  }

  button {
    @include valign;
    background: none;
    border: 0;
    padding: 0;

    svg {
      float: left;
      margin-right: rem(5);
    }
  }
}
