.m-usermenu {
  background: transparent;
  border: 0;
  color: $color-metal;
  display: flex;
  font-size: $font-size-header-base;
  height: rem(48);
  padding: rem(11) 0;
  text-decoration: none;

  @media (min-width: $zafaBeginDesktopBreakpoint) {
    padding-right: rem(8);
  }

  &:hover,
  &:focus {
    color: $color-red-125;
  }

  > .a-icon {
    margin-right: em(4, 14);
  }

  span {
    display: flex;

    @media (max-width: $screen-sm-header-break) {
      &.m-usermenu__label {
        display: none;
      }
    }
  }

}

.tippy-box[data-theme~='userlogin'],
.tippy-box[data-theme~='userlogin'][data-placement^='bottom'],
.tippy-box[data-theme~='userlogin'][data-placement^='top'] {
  .tippy-content {
    background-color: $color-milk;
    border: 0;
    box-shadow: none;
    padding: $user-detail-padding;
  }

  .m-tooltip-content {
    color: $color-red-125;
    padding: 0;
  }

  .tippy-arrow {
    height: rem(26);
    width: rem(26);

    &::before {
      border: 0;
    }

    &::after {
      border-bottom-color: $color-milk;
      border-top-color: $color-milk;
    }
  }
}


