$main-nav-link-vertical-padding: rem(24);
$main-nav-link-side-padding: rem(20);

header .o-header-bottom__mainnav {
  height: 100%;
}

.o-header-bottom__mainnav {
  height: $header-height-desktop/2;
  position: relative;

  .m-header-main-nav {
    bottom: 0;
    position: absolute;

    > ul {
      @include flatten-list($margin: 0 0 0 -#{$main-nav-link-side-padding});

      > li {
        margin-bottom: rem(-1);

        &[data-active='true'] {
          .m-header-mainnav-link {
            transition: none;

            &::after {
              left: $main-nav-link-side-padding;
              right: $main-nav-link-side-padding;
            }

          }
        }

        // STORYBOOK CHANGE
        &:hover {
          .m-header-main-nav__subMenu {
            display: block;
          }
        }
      }
    }

    .m-header-mainnav-link {
      color: $color-iron;
      display: block;
      font-size: $font-size-main-nav;
      font-weight: $font-weight-main-nav;
      margin: 0;
      padding: $main-nav-link-vertical-padding $main-nav-link-side-padding;
      position: relative;
      text-decoration: none;
      transition: color 0.2s ease 0.2s;

      &:hover,
      &:focus {
        color: $color-red-125;

        &::after {
          left: $main-nav-link-side-padding;
          right: $main-nav-link-side-padding;
        }
      }

      &::after {
        background-color: currentColor;
        bottom: 0;
        content: ' ';
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        right: 50%;
        transition: left 0.2s ease 0.2s, right 0.2s ease 0.2s;
      }
    }

    .sr-only {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: rem(-1);
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    // STORYBOOK CHANGE
    /* stylelint-disable order/order */
    $colorGrayLighter: #f7f7f7;

    .m-linkList__item {
      position: relative;
    }

    &__subMenu {
      background-color: $colorGrayLighter;
      display: none;
      padding: rem(20);
      position: absolute;
      width: rem(220);

      ul {
        padding: 0;

        li {
          width: 100%;

          .m-header-main-nav__subMenuLink {
            color: $color-iron;
            font-size: rem(16);
            position: relative;
            text-decoration: none;
            transition: color 0.2s ease 0.2s;

            &:hover,
            &:focus {
              color: $color-red-125;
            }
          }

          &:not([data-active='true']) .m-header-main-nav__subMenuLink {
            &:hover,
            &:focus {
              color: $color-red-125;

              &::after {
                left: 0;
                right: 0;
              }
            }

            &::after {
              background-color: currentColor;
              bottom: 0;
              content: ' ';
              display: block;
              height: 1px;
              left: 50%;
              position: absolute;
              right: 50%;
              transition: left 0.2s ease 0.2s, right 0.2s ease 0.2s;
            }
          }

          &[data-active='true'] .m-header-main-nav__subMenuLink {
            text-decoration: underline;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

