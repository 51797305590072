// Dashboard spacings
//--------------------------------------------------
$dashboardTitleSpacingBottom: rem(55);
$dashboardTitleSpacingTop: rem(25);
$dashboardTitleSpacingBottomSmall: rem(25);
$dashboardTileSpacingV: rem(25);
$dashboardTileSpacingVSmall: rem(15);
$dashboardTileSpacingH: rem(20);
$dashboardTileSpacingHSmall: rem(10);
$pageTileSpacingV: rem(25);
$pageTileSpacingVSmall: rem(15);
$pageTileSpacingH: rem(20);
$pageTileSpacingHSmall: rem(10);


// Header and menu spacings
//--------------------------------------------------
$headerPadding: rem(5) 0 0;
$headerPaddingBottom: rem(25);
$headerPaddingSmall: rem(15) 0;
$menuItemSpacing: rem(25);
$metanavItemSpacing: rem(30);
$sidebarPadding: rem(24);
$langswitchItemSpacing: rem(29);
$userDetailPadding: rem(7) rem(15);

// Footer spacings
//--------------------------------------------------
$footerTextSpacing: rem(50);
$footerPaddingSmall: rem(15) 0;
$footerTopSpacing: rem(35);

// Content spacings
//--------------------------------------------------
$contentSpacingTop: rem(45);
$contentSpacingBottom: rem(140);

// Breadcrumb spacing
//--------------------------------------------------
$breadcrumbItemSpacing: rem(10);
$breadcrumbBottomSpacing: rem(35);
$breadcrumbBottomSpacingSmall: rem(20);

// Accordion spacings
//--------------------------------------------------
$accordionHeadPadding: rem(36) rem(25);
$accordionHeadPaddingSmall: 0 rem(15);
$accordionBodyPadding: rem(50) rem(25);
$accordionBodyPaddingSmall: rem(25) rem(15);

// Comments articles spacings
//--------------------------------------------------
$commentsTitleSpacing: rem(20);
$commentsTitleSpacingSmall: rem(15);

$commentsItemSpacing: rem(20);
$commentsItemSpacingSmall: rem(15);
$commentsTopSpacing: rem(30);
$commentsTopSpacingSmall: rem(20);
$commentsItemPadding: rem(25) rem(40);
$commentsItemPaddingSmall: rem(20) rem(15);
$commentsItemTitleSpacing: rem(10);
$commentsItemTitleSpacingSmall: rem(5);

// Social spacings
//--------------------------------------------------
$socialItemSpacing: rem(20);
$elementBottomSpacing: rem(10);


$mnuDropdownPadding: rem(20);
$mnuDropdownTopSpacing: rem(1);
$mnuDropdownItemSpacing: rem(18);

// Search spacings
//--------------------------------------------------
$searchSpacerBig: rem(40);
$searchSpacerSmall: rem(20);
$smallSearchSpacerBig: rem(20);
$smallSearchSpacerSmall: rem(10);
