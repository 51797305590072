$colorWhite: #fff;
$colorWhiteTransparent: rgba($colorWhite, 0.45);
$colorGrayLighter: #f7f7f7;
$colorGrayLight: #e5e5e5;
$colorGrayMedium: #cdcdcd;
$colorAlmostGray: #a8a8a8;
$colorGray: #767676;
$colorGrayAlmostDark: #666;
$colorGrayDark: #444;
$colorBlack: #000;
$colorRedLight: #c90009;
$colorRed: #eb0000;
$colorRedDark: #c60018;
$colorRedVeryDark: #a40008;
$colorCtrlHover: #a20013;
$colorRedPersonalia: #d70000;
$colorCtrlBorder: #b7b7b7;
$colorGreen: #24a657;
$colorWarning: #ffa500;
$colorWarningDark: #ff8c00;

$colorBlue: #0079c7;

$mobileGrays: (
  dark:#bdbdbd,
  medium:#d2d2d2,
  light: #e5e5e5,
  lighter: #f2f2f2,
  lightest: #fcfcfc
);
