// ZaFa Head settings
//--------------------------------------------
$pageHeadPadding: rem(10) 0;
$fontSizeHeadIcons: rem(30);
$fontSizeFunctionIcon: rem(24);
$smallFunctionsWrapperWidth: rem(150);
$smallFunctionsWrapperHeight: rem(45);
$functionsCellWidth: rem(165);

// General table spacings
//--------------------------------------------
$tableLeftPadding: rem(50);
$tableLeftPaddingSmall: rem(10);
$tableRightPadding: rem(20);
$tableRightPaddingSmall: rem(10);
$tableIndent: rem(20);
$triggerRadius: rem(32);
$triggerRadiusSmall: rem(18);
$fontSizeTriggerIcon: rem(24);
$fontSizeTriggerIconSmall: rem(14);
$tableRowOffset: rem(20);
$mobileRowHeight: rem(60);
$fontSizeTableFunctions: rem(14);
$smallValueCellWidth: rem(50);
$scrollBarOffset: rem(10);
$mobileOptionDiameter: rem(40);
$mobileOptionsHeight: rem(65);
$fontSizeSettingsIcon: rem(26);

// Table head
//--------------------------------------------
$fontSizeTableHead: rem(16);
$fontSizeTableHeadSmall: rem(12);
$tableHeadHeight: rem(40);

// Table heading
//--------------------------------------------
$fontSizeTableHeading: rem(22);
$fontSizeTableHeadingSmall: rem(16);
$tableHeadingHeight: rem(80);

// Table values
//--------------------------------------------
$fontSizeTableValues: rem(17);
$fontSizeTableValuesSmall: rem(13);
$fontWeightTableValuesOpen: bold;
$tableValuesRowHeight: rem(65);

// Diagram and cell options
//--------------------------------------------
$fontSizeCellDiagramTitle: rem(18);
$fontSizeCellDiagramTitleSmall: rem(16);
$fontWeightCellDiagramTitle: 300;

$fontSizeCellDiagramText: rem(16);
