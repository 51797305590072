.m-footer-section {
  border-top: rem(1) solid $color-platinum;
  color: $color-granite;
  font-size: em($footer-section-base-size, 15);

  @include media-breakpoint-up(tablet) {
    border-top: 0;
  }

  &__title {
    display: none;

    @include media-breakpoint-up(tablet) {
      border-bottom: rem(1) solid $color-platinum;
      display: block;
      font-size: em(16, $footer-section-base-size);
      font-weight: $font-light;
      line-height: (19 / 16);
      margin-bottom: em(24, 16);
      padding-bottom: em(24, 16);
    }
  }

  &:first-child {
    border-top: 0;
  }

  &__body {
    padding: em(16, $footer-section-base-size) em(24, $footer-section-base-size)
      em(36, $footer-section-base-size);

    @include media-breakpoint-up(mobile) {
      padding-left: em(48, $footer-section-base-size);
      padding-right: em(48, $footer-section-base-size);
    }

    @include media-breakpoint-up(tablet) {
      padding: 0;
    }

    > *:first-child {
      margin-top: 0;
    }
  }

  .m-social-links {
    margin: rem(24) 0 rem(16);
  }
}
