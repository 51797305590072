.l-login-panel {
  padding-top: em(36, 15);

  @include media-breakpoint-up(mobile) {
    padding-top: em(48, 15);
  }
}

.l-constrainer {
  margin-left: 1.6rem;
  margin-right: 1.6rem;

  @include media-breakpoint-up(mobile) {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }

  .o-footer__sections-container & {
    margin: 0;
  }

  &,
  .o-footer__sections-container & {
    @include media-breakpoint-up(tablet) {
      margin-left: 5.5%;
      margin-right: 5.5%;
    }

    @include media-breakpoint-up(desktop) {
      margin-left: 8.5%;
      margin-right: 8.5%;
    }

    @include media-breakpoint-up(desktop-lg) {
      margin-left: auto;
      margin-right: auto;
      max-width: rem(1594);
    }
  }
}

.container-fluid {
  height: 100%;
  margin: 0 rem(8);
  padding: 0;
  width: auto;

  @media (min-width: $screen-sm-min) {
    margin: 0 rem(24);
  }

  @media (min-width: $screen-md-min) {
    margin: 0 rem(56);
  }

  @media (min-width: $screen-normal-min) {
    margin: 0 rem(108);
  }

  @media (min-width: $screen-normal-max) {
    margin-left: auto;
    margin-right: auto;
    max-width: 106rem;
  }
}

.l-divider-thin {
  @include divider-thin;
}

.l-divider-small {
  @include divider-small;
}

.l-divider-medium {
  @include divider-medium;
}

.l-divider-big {
  @include divider-big;
}
