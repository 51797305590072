@import 'zafa-widget/settings';

.plf-tooltip-wrapper.zafa-button {
  cursor: pointer;
  display: inline-block;

  .plf-tooltip {
    top: calc(#{rem(13)} + 100%);
  }
  // sbb notebook hover fix
  i.icon-040-drei-punkte-menu-106 {
    background: transparent;
    padding: rem(10) 0;
  }
}

.plf-zafa-document-invalid-import-warning {
  background: #faa;
  border: 1px dotted #a00;
  padding: rem(10);
}

.plf-zafa-document-invalid-import-warning * {
  color: #a00 !important;
}

.plf-zafa-head {
  padding: $pageHeadPadding;
  position: relative;

  &[data-fixed='true'] {
    background-color: $colorWhite;
    display: block;
    width: 100%;

    + .plf-zafa-table-wrapper {
      padding-top: rem(100);
    }
  }

  .plf-functions-wrapper {
    text-align: right;

    .plf-table-ctrl-wrapper {
      text-align: right;

      .plf-table-ctrl {
        display: inline-block;
        padding: 0 rem(20);

        button.plf-save-table i.icon-030-ok-112 {
          margin-top: rem(-6);
        }
      }
    }

    button,
    .plf-tooltip-wrapper.zafa-button {
      background-color: transparent;
      border: 0;
      text-align: right;
      width: auto;

      * {
        display: inline-block;
        line-height: 100%;
        pointer-events: none;
        vertical-align: middle;
      }

      span {
        font-size: $fontSizeTableFunctions;
      }

      i {
        font-size: $fontSizeHeadIcons;
      }

      &.plf-expand-table {
        i {
          font-size: $fontSizeHeadIcons;
        }
      }

      &:hover {
        * {
          color: $colorRedDark;
        }
      }

      &.plf-print-table {
        position: relative;

        &[data-loading='true'] {
          * {
            color: $colorRedDark;
          }

          &::before {
            animation: leftToRight 2s infinite alternate;
            background-color: $colorRedDark;
            bottom: 0;
            content: ' ';
            height: rem(2);
            left: 0;
            position: absolute;
            right: 100%;
          }
        }
      }

      &.plf-save-table {
        position: relative;

        &[data-edited='true'] {
          * {
            animation: pulse 2s infinite;

          }
        }

        &::before {
          background-color: $colorRedDark;
          bottom: 0;
          content: ' ';
          height: rem(2);
          left: 0;
          position: absolute;
          right: 100%;
          transition: 1s ease 0.1s;
        }

        &[data-loading='true'] {
          * {
            color: $colorRedDark;
          }

          &::before {
            right: 0;
          }
        }
      }
    }
  }
}

.plf-zafa-table-wrapper {
  margin-bottom: rem(40);
  position: relative;
}

.plf-zafa-table {
  border: 0;
  border-collapse: collapse;
  width: 100%;

  &[data-loading='true'] {
    position: relative;

    &::before {
      background-color: rgba($colorWhite, 0.8);
      bottom: 0;
      content: ' ';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: $zIndex200;
    }
  }

  // If in edit mode show all years
  &[data-edit='true'] {

    //.plf-zafa-cell:not(.plf-zafa-functions-cell.hidden-large,.plf-zafa-collapse-cell.hidden-large) {
    .plf-zafa-cell {
      display: table-cell !important;

      // Show empty cells for disabled elements
      &:not([data-sort-order='0'], [data-sort-order='1']) {
        &[data-selected='false'] {
          span {
            opacity: 0;
          }
        }
      }

      &.plf-zafa-functions-cell.hidden-large,
      &.plf-zafa-collapse-cell.hidden-large {
        display: none !important;

        * {
          display: none;
        }
      }
    }

    .plf-zafa-row {

      // Edit style of the head element
      &[data-structure='head'] {
        outline: rem(2) solid $colorRedDark;
        transform: scale(1.03) translateY(rem(-3));

        .plf-zafa-cell {
          span {
            opacity: 1 !important;
          }

          .plf-year-handler,
          span {
            display: inline-block;
          }
        }
      }

      &:not([data-structure='head']) {
        opacity: 0.4;

        &,
        * {
          pointer-events: none;

          &:hover {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  // Global row styling
  .plf-zafa-row {
    border-left: $tableRowOffset solid $colorWhite;
    height: $tableValuesRowHeight;
    position: static;

    // Add padding to first (second because of the hidden td for smaller devices) and last cell
    td {
      &[data-type='position'] {
        max-width: rem(300);
        padding-left: $tableLeftPadding;
        text-align: left; // tooltip (three dots) for downloa
      }

      &:last-of-type {
        border-right: $tableRowOffset solid $colorWhite;
        padding-right: $tableRightPadding;
        text-align: right;
      }
    }

    // Remove unit, value and delta from table row for parent elements
    &[data-level='0'] {
      &:not([data-structure='head']) {
        .plf-zafa-cell {
          &[data-type='unit'],
          &[data-type='value'],
          &[data-type='delta'] {
            span {
              display: none;
            }
          }
        }
      }
    }

    &[data-internal='true'] {
      .plf-zafa-functions-cell {
        position: relative;

        &::before {
          color: $colorRedDark;
          content: '\39';
          font-family: 'sbbicons';
          font-size: rem(30);
          left: auto;
          pointer-events: none;
          position: absolute;
          right: rem(10);
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &[data-level='2'] {
        .plf-zafa-collapse-cell {
          &::before {
            font-size: rem(25);
            right: rem(20);
          }
        }
      }

      &[data-level='3'] {
        .plf-zafa-collapse-cell {
          &::before {
            font-size: rem(20);
            right: rem(30);
          }
        }
      }

      &[data-level='4'] {
        .plf-zafa-collapse-cell {
          &::before {
            font-size: rem(20);
            right: rem(40);
          }
        }
      }
    }

    &[data-highlighted='true'] {

      @media (min-width: $screenSmlMax) {
        td {
          &[data-type='position'] {
            display: inline-flex;
            height: rem(65);
            vertical-align: top;

            span {
              align-self: center;
              display: block;
            }
          }

          &:nth-of-type(3)::before {
            border: rem(1) solid $colorRed;
            content: '';
            display: block;
            height: rem(65);
            left: rem(10);
            pointer-events: none;
            position: absolute;
            right: rem(10);
          }
        }

        &[data-structure='heading1'] {
          td {
            &[data-type='position'] {
              height: rem(80);
              vertical-align: middle;
            }

            &:nth-of-type(3)::before {
              height: rem(80);
              left: 0;
              right: 0;
            }
          }
        }
      }
    }

    &[data-structure='empty'] {
      height: rem(2);
      line-height: rem(2);
    }

    // Hide collapsed rows
    &[data-collapsed='true'] {
      display: none;
    }

    // Head row styling
    &[data-structure='head'] {
      background-color: $colorGrayLight;
      border: 0;
      height: $tableHeadHeight;

      td {
        &:last-of-type {
          border: 0;
        }
      }

      .plf-zafa-cell {
        span {
          font-size: $fontSizeTableHead;
        }

        // Edit mode visibility controll
        .plf-year-handler {
          background-color: transparent;
          border: 0;
          display: none;
          height: 100%;
          line-height: 100%;
          padding: 0;
          vertical-align: middle;

          i {
            color: $colorRedDark;
            font-size: $fontSizeTriggerIcon;
          }

          &[data-selected='false'] {
            i {
              color: $colorBlack;

              &::before {
                content: '\24';
              }
            }
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      &[data-fixed='true'] {
        box-shadow: rgba($colorBlack, 0.7)  rem(0) rem(4) rem(17)  rem(-9);

        .plf-zafa-cell:not([data-selected='false']),
        .plf-is-absolute {
          display: table-cell;
        }
      }
    }

    // Heading row styling
    &[data-structure^='heading'] {
      background-color: $colorGrayLighter;
      border: 0;
      height: $tableHeadingHeight;

      td {
        &:last-of-type {
          border: 0;
        }
      }

      .plf-zafa-cell {
        span {
          font-size: $fontSizeTableHeading;
        }
      }

      .plf-zafa-collapse-cell {
        button,
        .plf-tooltip-wrapper.zafa-button {
          margin-right: $tableIndent/2;
        }
      }
    }

    &:not([data-structure^='head']) {
      &[data-children-collapsed='false'] {
        *:not(i) {
          font-weight: bold;
        }
      }
    }

    &[data-level='2'] {
      td {
        &[data-type='position'] {
          padding-left: $tableLeftPadding + $tableIndent;
        }
      }
    }

    &[data-level='3'] {
      td {
        &[data-type='position'] {
          padding-left: $tableLeftPadding + 2 * $tableIndent;
        }
      }
    }

    &[data-level='4'] {
      td {
        &[data-type='position'] {
          padding-left: $tableLeftPadding + 3 * $tableIndent;
        }
      }
    }

    &.odd {
      background-color: $colorGrayLighter;
    }
  }

  // Generall cell style
  .plf-zafa-cell {
    line-height: 1;
    text-align: right;
    vertical-align: middle;

    span {
      font-size: $fontSizeTableValues;
      padding: rem(7) 0;

      &.plf-underline {
        text-decoration: underline;
      }
    }

    // Disabled value cell style
    &:not([data-type='position']) {
      &[data-selected='false'] {
        display: none;
      }
    }
  }

  // Functions cell style
  .plf-zafa-functions-cell {
    line-height: rem(65);
    text-align: center;

    .plf-zafa-function {
      display: table;
      height: 100%;

      text-align: right;
      width: $functionsCellWidth;

      button,
      .plf-tooltip-wrapper.zafa-button {
        background-color: transparent;
        border: 0;
        line-height: 100%;
        padding: 0;
        vertical-align: middle;

        i {
          color: $colorBlack;
          font-size: $fontSizeFunctionIcon;
          width: rem(30);

          &.row-has-info {
            color: $colorBlue;
          }
        }
      }

      button {
        display: table-cell;
        height: $tableValuesRowHeight;
      }

      [data-icon] {
        height: auto;
      }
    }
  }

  // Collaps ctrl cell style
  .plf-zafa-collapse-cell {
    button,
    .plf-tooltip-wrapper.zafa-button {
      background-color: transparent;
      border: 0;
      display: block;
      float: right;
      min-height: $triggerRadius;
      min-width: $triggerRadius;
      padding: 0;

      i {
        color: $colorBlack;

        font-size: rem(46);
        //position: relative;
        //top: 1px;
        //left: -2px;
        line-height: 100%;
        vertical-align: middle;
      }

      &[data-collapsed='false'] {
        i::before {
          content: '\54';
        }
      }
    }
  }
}

@import 'social-media-links';
@import 'meta-row';
@import 'diagrams';

&:not([data-pdf='true']) {
  @media (max-width: $screenSmlMax) {
    @import 'mobile/zafa-widget';
  }
}

@keyframes leftToRight {
  from {
    right: 100%;
  }

  to {
    right: 0;
  }
}

@keyframes pulse {
  0% {
    color: $colorBlack;
  }

  50% {
    color: $colorRed;
  }

  100% {
    color: $colorBlack;
  }
}
