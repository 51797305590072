.a-highlighted-text {
  $opacity: 0.5;

  &--yellow {
    background-color: rgba($color-lemon, $opacity);
  }

  &--orange {
    background-color: rgba($color-orange, $opacity);
  }

  &--green {
    background-color: rgba($color-green, $opacity);
  }
}

