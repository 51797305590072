.a-chip {
  background-color: $color-cloud;
  border: 0;
  border-radius: 1em;
  height: 2em;
  padding: 0.2rem 0.5rem 0.2rem 0.9rem;
}

.a-chip:hover {
  background-color: $color-aluminum;
  cursor: pointer;
}

.a-chip-btn {
  background: transparent;
  border: 0;
  line-height: 1em;
  margin-left: 0.5rem;
  padding: 0;
  vertical-align: middle;
}

.a-chip-btn:hover {
  cursor: pointer;
}
