//--------------------------------------------------
// Base typo definition
//--------------------------------------------------

@import 'fonts/sbb';
@import 'fonts/sbbicons';
@import 'fonts/social-icons';

$icons: 'sbbicons';
$sbb: 'SBB', Helvetica, Arial, sans-serif;
