.plf-user-survey {
  background: $colorWarning;
  border-radius: rem(4);
  box-shadow: 0 rem(10) rem(15)  rem(-3) rgba(0, 0, 0, 0.1), 0 rem(4) rem(6)  rem(-2) rgba(0, 0, 0, 0.05);
  left: 0;
  margin: rem(48) 0 0;
  max-width: 40rem;
  padding: 2rem;
  position: fixed;
  right: 0;
  top: 0;
  transform: scale(0);
  transition:
    left 500ms ease,
    margin 500ms ease,
    transform 500ms ease,
    visibility 0ms ease 500ms;
  visibility: hidden;
  z-index: $zIndex2;

  @media (min-width: $screenSmlMin) {
    left: inherit;
    margin: rem(64) $gridViewPortSpacingSmallLandscape 0;
  }

  @media (min-width: $screenMdMin) {
    left: inherit;
    margin: rem(64) $gridViewPortSpacingMedium 0;
  }

  @media (min-width: $screenNormalMin) {
    left: inherit;
    margin: rem(64) $gridViewPortSpacingNormal 0;
  }

  @media (min-width: $zafaBeginDesktopBreakpoint) {
    left: inherit;
    margin: rem(130) $gridViewPortSpacingNormal 0;
  }

  @media (min-width: $screenLgMin) {
    left: 50%;
    margin: rem(130) 0 0 rem(156);
  }

  &.show {
    transform: scale(1);
    transition-delay: 0ms;
    visibility: visible;
  }

  &__label {
    color: $colorWhite;
  }

  button {
    background-color: $colorWarningDark;
    border: 0;
    border-radius: rem(5);
    color: $colorWhite;
    display: block;
    margin: 1rem 0;
    padding: 0.5rem;
    width: 100%;

    &:hover {
      background-color: darken($colorWarningDark, 10%);
    }
  }

  a,
  a:hover {
    text-decoration: none;
  }
}
