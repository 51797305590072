@font-face {
  font-display: swap;
  font-family: 'SBB';
  font-style: normal;
  font-weight: 100;
  src: url('https://cdn.app.sbb.ch/fonts/v1_6_subset/SBBWeb-UltraLight.woff2') format('woff2'),
    url('https://cdn.app.sbb.ch/fonts/v1_6_subset/SBBWeb-UltraLight.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'SBB';
  font-style: normal;
  font-weight: 200;
  src: url('https://cdn.app.sbb.ch/fonts/v1_6_subset/SBBWeb-Thin.woff2') format('woff2'),
    url('https://cdn.app.sbb.ch/fonts/v1_6_subset/SBBWeb-Thin.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'SBB';
  font-style: normal;
  font-weight: 300;
  src: url('https://cdn.app.sbb.ch/fonts/v1_6_subset/SBBWeb-Light.woff2') format('woff2'),
    url('https://cdn.app.sbb.ch/fonts/v1_6_subset/SBBWeb-Light.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'SBB';
  font-style: normal;
  font-weight: normal;
  src: url('https://cdn.app.sbb.ch/fonts/v1_6_subset/SBBWeb-Roman.woff2') format('woff2'),
    url('https://cdn.app.sbb.ch/fonts/v1_6_subset/SBBWeb-Roman.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'SBB';
  font-style: normal;
  font-weight: bold;
  src: url('https://cdn.app.sbb.ch/fonts/v1_6_subset/SBBWeb-Bold.woff2') format('woff2'),
    url('https://cdn.app.sbb.ch/fonts/v1_6_subset/SBBWeb-Bold.woff') format('woff');
}
