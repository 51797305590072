$fontSizeDashboardNumber: rem(40);
$fontSizeMagazinesIcon: rem(34);
$fontSizeDashboardUnit: rem(15);
$fontSizeHeadImage: rem(40);
$fontSizeHeadImageSmall: rem(24);

// Magazines overview font sizes
//--------------------------------------------------
$fontSizeMagazinesIcon: rem(34);

// Header and menu font sizes
//--------------------------------------------------
$fontSizeMenu: rem(18);
$fontSizeMenuSmall: rem(16);
$fontSizeMetanav: rem(14);
$fontSizeHeaderIcons: rem(28);
$fontSizeMenuTrigger: rem(24);
$leadLineHeight: rem(30);
$fontSizeSearchPaging: rem(16);
$fontSizeSearchButton: rem(13);

// Footer font sizes
//--------------------------------------------------
$fontSizeFooter: rem(18);
$fontSizeFooterIcons: rem(28);
$fontSizeFooterSmall: rem(16);

// Category lead font sizes
//--------------------------------------------------
$fontSizeLeadTitle: rem(24);
$fontSizeLeadTitleSmall: rem(22);
$fontSizeLeadSocial: rem(15);
$fontSizeLeadSocialSmall: rem(12);
$fontSizeLeadAuthor: rem(13);
$fontSizeLeadAuthorSmall: rem(11);
$fontSizeSocialIcons: rem(28);

$fontSizeCategoryTitle: rem(19);
$fontSizeCategoryTitleSmall: rem(18);

// Subject teaser font sizes
//--------------------------------------------------
$fontSizeSubjectTeaserTitle: rem(40);
$fontSizeSubjectTeaserTitleSmall: rem(28);

// Article preview style
//--------------------------------------------------
$fontSizeArticlePreviewTitle: rem(21);
$fontSizeArticlePreviewTitleSmall: rem(20);
$fontSizeArticlePreviewText: rem(15);
$fontSizeArticlePreviewTextSmall: rem(15);
$fontSizeArticlePreviewLink: rem(15);
$fontSizeArticlePreviewLinkSmall: rem(15);
$fontSizeArticlePreviewIcon: rem(26);

// Author font sizes
//--------------------------------------------------
$fontSizeAuthorPreviewTitle: rem(21);
$fontSizeAuthorPreviewTitleSmall: rem(20);
$fontSizeAuthorPreviewText: rem(15);
$fontSizeAuthorPreviewTextSmall: rem(15);
$fontSizeAuthorPreviewLink: rem(15);
$fontSizeAuthorPreviewLinkSmall: rem(15);
$fontSizeAuthorPreviewIcon: rem(26);
$fontSizeAuthorMail: rem(18);
$fontSizeAuthorMailSmall: rem(16);

// Breadcrumb font sizes
//--------------------------------------------------
$fontSizeBreadcrumb: rem(16);
$fontSizeBreadcrumbSmall: rem(13);
$fontSizeBreadcrumbHomeIcon: rem(24);
$fontSizeBreadcrumbIcon: rem(16);

// Article font sizes
//--------------------------------------------------

// Meta
$fontSizeArticleMeta: rem(15);

// Figure description
$fontSizeArticleFigcaption: rem(18);
$fontSizeArticleFigcaptionSmall: rem(15);
$lineHeightArticleFigcaptionSmall: rem(17);

// Quote
$fontSizeQuote: rem(32);
$lineHeightQuote: rem(42);
$fontSizeQuoteSmall: rem(26);
$lineHeightQuoteSmall: rem(34);
$fontSizeQuoteSource: rem(18);
$fontSizeQuoteSourceSmall: rem(16);

// Survey
$fontSizeSurveyOptions: rem(19);
$fontSizeSurveyOptionsSmall: rem(16);
$fontSizeSurveySubmit: rem(15);

// Audio button
$fontSizeAudioButton: rem(15);
$fontSizeAudioIcon: rem(30);

// List
$fontSizeListBottom: rem(18);
$fontSizeListBottomSmall: rem(16);

// Slider
$fontSizeSliderArrow: rem(80);
$fontSizeSliderCount: rem(16);
$fontSizeSliderCtrl: rem(35);
$fontSizeSliderCtrlSmall: rem(30);

// Sidebar
$fontSizeSidebarItemTitle: rem(21);
$fontSizeSidebarItemText: rem(19);
$fontSizeSidebarItemTitleSmall: rem(18);
$fontSizeSidebarItemTextSmall: rem(16);

// social
$fontSizeSocialCount: rem(22);
$fontSizeSocialIcons: rem(42);
$fontSizeShareIcons: rem(22);
$fontSizeSocialCountSmall: rem(18);
$fontSizeSocialIconsSmall: rem(36);

// anchor
$fontSizeAnchor: rem(16);
$lineHeightAnchor: rem(30);
$fontSizeAnchorSmall: rem(14);
$lineHeightAnchorSmall: rem(25);
$fontSizeAnchorIcon: rem(18);

// Testimony
$fontSizeTestimonyAuthor: rem(18);
$fontSizeTestimonyAuthorSmall: rem(16);

// file
$fontSizeFileInfo: rem(14);
$fontSizeFileIcon: rem(28);
$fontSizeFileInfoSmall: rem(12);
$fontSizeFileIconSmall: rem(22);

// Reader contribution font sizes
//--------------------------------------------------
$fontSizeLetterAuthor: rem(15);
$fontSizeLetterAnnotation: rem(16);

// Login form font sizes
//--------------------------------------------------
$fontSizeLoginTitle: rem(36);
$fontSizeLoginButton: rem(15);
$fontSizeLoginText: rem(21);
$fontSizeLoginTitleSmall: rem(28);
$fontSizeLoginButtonSmall: rem(13);
$fontSizeLoginTextSmall: rem(19);

// Accordion font sizes
//--------------------------------------------------
$fontSizeAccordionTitle: rem(21);
$fontSizeAccordionTitleSmall: rem(18);
$fontSizeAccordionIcon: rem(25);
$fontSizeAccordionIconSmall: rem(18);

// Related font sizes
//--------------------------------------------------
$fontSizeRelatedTitle: rem(40);
$fontSizeRelatedTitleSmall: rem(28);
$fontSizeRelatedItemText: rem(15);

// Comments font sizes
//--------------------------------------------------
$fontSizeCommentsTitle: rem(40);
$fontSizeCommentsTitleSmall: rem(28);
$fontSizeCommentsText: rem(18);
$fontSizeCommentsMeta: rem(16);
$fontSizeCommentsTextSmall: rem(16);
$fontSizeCommentsMetaSmall: rem(14);

// Editions
//--------------------------------------------------
$fontSizeEditions: rem(15);
$fontSizeEditionsSmall: rem(14);

$fontSizePostIt: rem(19);
$fontSizePostItSmall: rem(16);
// Input placeholder style
//--------------------------------------------------

::-webkit-input-placeholder { /* Chrome */
  color: $colorGray;
}

:-ms-input-placeholder { /* IE 10+ */
  color: $colorGray;
}

::-moz-placeholder { /* Firefox 19+ */
  color: $colorGray;
  opacity: 1;
}

:-moz-placeholder { /* Firefox 4 - 18 */
  color: $colorGray;
  opacity: 1;
}

a {
  color: $colorBlack;
  text-decoration: underline;

  &:hover {
    color: $colorRedDark;
    cursor: pointer;
  }
}

h1 {
  font-size: rem(48);
  font-weight: 200;
  line-height: rem(57.6);
  margin: 0 0 rem(24) 0;
}

h2 {
  font-size: rem(22);
  font-weight: 200;
  line-height: rem(27);
  margin-bottom: rem(15);
  margin-top: rem(5);
}

h3 {
  font-size: rem(22);
  font-weight: 300;
  line-height: rem(27);
  margin-bottom: rem(10);
  margin-top: rem(5);
}

h1,
h2,
h3 {
  u {
    font-weight: inherit;
  }
}

.plf-text-content {
  h2 {
    font-weight: 700;
    margin-bottom: rem(10);
    margin-top: rem(20);
  }

  h3 {
    font-weight: 300;
    margin-top: rem(20);
  }
}

h4 {
  font-size: rem(15);
  font-weight: 300;
}

p {
  font-size: rem(18);
  line-height: rem(30);
  margin-bottom: $elementBottomSpacing / 2;

  * {
    font-size: rem(18);
    line-height: rem(30) !important;
  }
}

@media (max-width: $screenSmlMax) {
  h1 {
    font-size: rem(32);
    line-height: rem(38.4);
  }

  h2 {
    font-size: rem(20);
  }

  h3 {
    font-size: rem(20);
  }

  p {
    font-size: rem(18);
    line-height: rem(24);

    * {
      font-size: rem(18) !important;
      line-height: rem(24) !important;
    }
  }
}
