$quiz-question-icon: 48;

.o-survey-question {
  background-color: $color-milk;
  min-height: em($quiz-question-icon, $font-size-h3-px);
  padding: em(8, $font-size-regular-px);

  @include media-breakpoint-up(mobile) {
    min-height: em($quiz-question-icon, $font-size-h3-mobile-px);
  }

  legend {
    border-bottom: 0;
  }

  &__legend {
    @include font-h3;
    display: block;
    margin-bottom: rem(12);
    position: relative;
  }

  &__answers {
    @include no-bullets;

    li {
      margin-top: em(8, $font-size-regular-px);
    }
  }
}
