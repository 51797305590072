$color-red: #eb0000;
$color-red-125: #c60018;
$color-white: #fff;
$color-milk: #f6f6f6;
$color-cloud: #e5e5e5;
$color-silver: #dcdcdc;
$color-aluminum: #d2d2d2;
$color-platinum: #cdcdcd;
$color-graphite: #b7b7b7;
$color-storm: #a8a8a8;
$color-metal: #767676;
$color-granite: #686868;
$color-gray40: #666;
$color-anthracite: #5a5a5a;
$color-iron: #444;
$color-black: #000;
$color-green: #00973b;
$color-autumn: #e84e10;
$color-orange: #f27e00;
$color-peach: #fcbb00;
$color-lemon: #ffde15;
$color-sky: #0079c7;
$color-night: #143a85;
$color-violet: #6f2282;
/* stylelint-disable */
:export {
  colorAluminum: $color-aluminum;
  colorGranite: $color-granite;
  colorGraphite: $color-graphite;
  colorMetal: $color-metal;
  colorRed: $color-red;
  colorRed125: $color-red-125;
  colorWhite: $color-white;
}
/* stylelint-enable */
