.plf-zafa-row[data-structure='heading1'] + .plf-meta-row,
.plf-zafa-row.odd + .plf-meta-row {
  background-color: $colorWhite;

  .plf-meta-tab-wrapper {
    .plf-meta-tab-trigger-wrapper {
      .plf-meta-tab-trigger {
        background-color: $colorGrayLighter;
        border-left: rem(1) solid $colorGrayLighter;
        border-right: rem(1) solid $colorWhite;

        &[data-selected='true'],
        &:hover {
          background-color: $colorGrayLight;
        }
      }
    }
  }
}

.plf-meta-tab-trigger {
  &[data-internal='true'] {
    position: relative;

    &::before {
      font-size: rem(18);
      left: auto;
      right: rem(10);
    }
  }
}


.plf-zafa-row[data-structure='heading1'] + .plf-meta-row {
  border: 0;

  td {
    border: 0;
  }
}

.plf-meta-row {
  border-left: 0;

  td {
    border-right: 0;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  &[data-level='2'] {
    td {
      &:first-of-type {
        padding-left: 0;
      }
    }
  }

  &[data-level='3'] {
    td {
      &:first-of-type {
        padding-left: 0;
      }
    }
  }

  &[data-level='4'] {
    td {
      &:first-of-type {
        padding-left: 0;
      }
    }
  }

  &[data-active='false'] {
    display: none;
  }

  // Tabs
  .plf-meta-tab-wrapper {
    background-color: $colorWhite;
    padding-bottom: 0;
    width: 100%;
    z-index: 1;

    .plf-meta-tab-trigger-wrapper {
      background-color: $colorWhite;
      font-size: 0;
      margin: 0;
      padding-left: 0;
      text-align: center;
      width: 100%;

      .plf-meta-tab-trigger {

        @include dynamicCountWidth(button, 9);
        background-color: $colorGrayLighter;
        border: 0;
        border-left: rem(1) solid $colorWhite;
        border-right: rem(1) solid $colorWhite;
        display: inline-block;
        font-size: rem(13);
        height: rem(30);
        position: relative;
        transition: 0.1s linear 0.1s;
        vertical-align: middle;

        &[data-selected='true'],
        &:hover {
          background-color: $colorGrayLight;
        }
      }
    }

    .plf-meta-tab {
      background-color: $colorWhite;
      border: 0;
      padding: rem(20) $tableRowOffset;

      &[data-selected='false'] {
        display: none;
      }
    }
  }

  .plf-file-list {
    li {
      a {
        i {
          font-size: $fontSizeTriggerIconSmall + rem(2);
          top: rem(2);
          width: $fontSizeTriggerIconSmall + rem(7);
        }
      }
    }
  }
}
