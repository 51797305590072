.plf-tooltip {
  background-color: $colorWhite;
  border: rem(1) solid $colorAlmostGray;
  display: none;
  font-size: rem(14);
  left: rem(6);
  padding: rem(20) rem(25);
  position: absolute;
  text-align: left;
  top: 100%;
  transform: translateX(calc(-86%));
  width: rem(230);
  z-index: $zIndex3000;

  .plf-tooltip-list {
    list-style: none;
    margin: 0;
    padding: 0;

    .plf-tooltip-list-element {
      padding: rem(4) 0;

      &:hover {
        span,
        i {
          color: $colorCtrlHover;
        }
      }

      span {
        font-size: rem(14);
        font-weight: 300;
        line-height: rem(20);
        padding-left: rem(4);
      }

      i {
        color: $colorBlack;
        font-size: rem(20);
      }
    }
  }

  @import 'social-media-links';

  &::before {
    border-bottom: rem(13) solid $colorAlmostGray;
    border-left: rem(21) solid transparent;
    border-right: rem(21) solid transparent;
    bottom: 100%;
    content: ' ';
    height: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 0;
    z-index: $zIndex1;
  }

  &::after {
    border-bottom: rem(12) solid $colorWhite;
    border-left: rem(19) solid transparent;
    border-right: rem(19) solid transparent;
    bottom: 100%;
    content: ' ';
    height: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 0;
    z-index: $zIndex1;
  }
}

.plf-tooltip-wrapper {
  position: relative;

  @include hover {
    .icon-040-drei-punkte-menu-106 {
      color: $color-red;
    }

    .plf-tooltip {
      display: block;
    }
  }

  .icon-040-drei-punkte-menu-106 {
    cursor: pointer;
    height: auto;
    padding: rem(5) 0;

    &::before {
      display: block;
      height: rem(25);
    }
  }

  .plf-tooltip {
    top: calc(100% + #{rem(13)});
  }
}
