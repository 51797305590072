$close-btn-size: rem(23);
$close-btn-spacing: rem(15);
$close-btn-full-width: $close-btn-size + ($close-btn-spacing * 2);
$content-padding: rem(10);
$padding-content: rem(53);

.interlay-item {
  position: fixed;
  z-index: 190030; // see .sv-main-header.sv-view

  /********************************
   * Type Basic                   *
   ********************************/
  &.interlay-type-basic {
    .interlay-content-wrapper {
      border: 1px solid $colorGray;
      box-shadow: rem(3) rem(3) 1px rgba($colorGrayMedium, 0.6);
      left: 50%;
      max-width: 90%;
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      width: rem(300);

      .interlay-content {
        text-align: center;
      }
    }
  }

  /********************************
   * Type Overlay                 *
   ********************************/
  &.interlay-type-overlay {
    bottom: 0;
    height: 100%;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;

    .interlay-overlay {
      background-color: rgba($colorGrayLight, 0.8);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    .interlay-content-wrapper {
      border: 1px solid $colorBlack;
      margin: 0 auto;
      max-width: rem(1200);
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 90%;

      .interlay-content {
        max-height: calc(80vh - (#{rem(100)} * 2));
        overflow-y: auto;
        text-align: left;

        .interlay-toprow-titles,
        .interlay-bottomrow-buttons {
          background: none;
          height: rem(53);
          line-height: rem(50);
          margin: 0;
          outline: 0;
          padding: 0;
          position: absolute;
          width: 100%;
        }

        .interlay-toprow-titles {
          max-width: calc(100% - #{$close-btn-full-width});
          top: 0;
        }

        .interlay-bottomrow-buttons {
          border-top: $colorGrayLight 1px solid;
          bottom: 0;
          margin-left: -$padding-content;
          margin-right: -$padding-content;
        }
      }
    }

    @media (max-width: $screenSmlMax) {
      .interlay-content-wrapper {
        height: 100%;
        margin-top: 0;
        max-height: 100%;
        max-width: 100%;
        width: 100%;

        .interlay-content {
          max-height: 100%;
        }
      }
    }
  }

  /********************************
   * Defaults                     *
   ********************************/

  .interlay-content-wrapper {
    background-color: $colorWhite;
    padding: $padding-content 0;
  }

  .interlay-toprow {
    background: none;
    border-bottom: $colorGrayLight 1px solid;
    margin: 0;
    outline: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .interlay-close-button {
    background: none;
    border: 1px solid $colorAlmostGray;
    border-radius: 100%;
    float: right;
    height: $close-btn-size;
    margin: $close-btn-spacing;
    outline: 0;
    padding: 0;
    position: relative;
    transition: color 0.3s ease;
    width: $close-btn-size;

    i[data-icon] {
      color: $colorAlmostGray;
      font-size: rem(18);

      &::before {
        left: rem(2);
        position: absolute;
        top: rem(2);
      }
    }

    &:hover {
      border-color: $colorRedDark;
      color: $colorRedDark;

      i[data-icon] {
        color: $colorRedDark;
      }
    }
  }

  .interlay-content {
    padding: rem(23) ($content-padding * 2) 0;
  }

  .interlay-controls {
    padding: 0 ($content-padding * 2);
    text-align: right;

    &:not(:empty) {
      margin-top: rem(20);
    }

    button {
      background: none;
      border: 1px solid $colorCtrlBorder;
      margin: 0;
      min-width: rem(100);
      outline: 0;
      padding: rem(15);

      &:not(:first-of-type) {
        margin-left: rem(10);
      }

      &:hover {
        border-color: $colorRedDark;
        color: $colorRedDark;
      }
    }
  }
}
