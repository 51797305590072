.plf-zafa-head {
  padding: 0;
  position: relative;
  z-index: $zIndex150;

  .plf-edit-mobile-wrapper {
    display: none;
  }
}

.plf-zafa-table-wrapper {
  width: auto;

  .plf-zafa-scroll-shadow {
    display: none;
  }

  &[data-scroll='true'] {
    overflow-x: scroll;
    overflow-y: hidden;
    // move scrollbar down
    padding-bottom: $scrollBarOffset;

    // Scroll shadow
    .plf-zafa-scroll-shadow {
      bottom: $scrollBarOffset;
      box-shadow: rem(2) 0 rem(2) 0 rgba(0, 0, 0, 0.1);
      display: block;
      position: absolute;
      top: 0;
      width: rem(2);
      z-index: $zIndex1;
    }

    // Special cellstyle for the scrollable table
    .plf-zafa-table {
      &.no-overflow {
        background: $colorRed;

        .plf-zafa-row {
          .plf-zafa-cell[data-selected='true'] {
            &:last-of-type {
              padding-right: rem(15);
            }
          }
        }
      }

      .plf-zafa-row {
        &[data-structure='head'] {
          &[data-fixed='true'] {
            .plf-is-absolute {
              background-color: $colorGrayLight;
              height: $mobileRowHeight;
            }
          }
        }
      }

      .plf-zafa-cell {
        &.plf-is-absolute {
          background-color: inherit;
          height: $mobileRowHeight - rem(1);
          position: static;
          text-align: left;
          vertical-align: middle;

          > *:not(.plf-zafa-function) {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }

          &.plf-zafa-collapse-cell {
            padding-left: $tableLeftPaddingSmall !important;
          }

          &.plf-zafa-functions-cell {
            padding: 0 rem(5);
          }
        }
      }
    }
  }

  &::before {
    content: none;
  }
}

.plf-zafa-table {
  width: 100%;

  .plf-zafa-row {
    border-bottom: rem(1) solid $colorWhite;
    border-left: 0;
    border-right: 0;
    height: $mobileRowHeight;
    position: static;

    &[data-internal='true'] {
      .plf-zafa-functions-cell {
        &::before {
          content: none;
        }
      }

      .plf-zafa-collapse-cell {
        &::before {
          color: $colorRedDark;
          content: '\39';
          font-family: 'sbbicons';

          font-size: rem(18);
          left: rem(0);
          pointer-events: none;
          position: absolute;
          right: rem(10);
          top: rem(2);
          transform: none;
        }
      }

      &[data-level='2'] {
        .plf-zafa-collapse-cell {
          &::before {
            left: rem(0);
          }
        }
      }

      &[data-level='3'] {
        .plf-zafa-collapse-cell {
          &::before {
            left: rem(0);
          }
        }
      }

      &[data-level='4'] {
        .plf-zafa-collapse-cell {
          &::before {
            left: rem(0);
          }
        }
      }
    }

    &[data-highlighted='true'] {
      background-color: $colorRedDark !important;

      * {
        color: $colorWhite !important;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }

    // Add padding to first and last cell --> last visible cells
    td {
      &:first-of-type {
        padding-left: $tableLeftPaddingSmall;
        text-align: left;
      }

      &:nth-last-child(3) {
        padding-right: $tableRightPaddingSmall;
        text-align: left;
      }

      &[data-type='position'] {
        padding-left: 0;
      }

      &:last-of-type {
        border: 0;
        padding-right: 0;
      }
    }

    .plf-zafa-cell {
      span {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        font-size: $fontSizeTableValuesSmall;
        -webkit-line-clamp: 3;
        max-height: 100%;
        overflow: hidden;
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    &[data-structure='empty'] {
      display: none;
    }

    // Hide collapsed rows
    &[data-collapsed='true'] {
      display: table-row;
      height: 0;
      position: absolute;
      visibility: hidden;

      * {
        border: 0;
        height: 0;
        margin: 0;
        padding: 0;
      }
    }

    // Head row styling
    &[data-structure='head'] {
      background-color: $colorGrayLight;
      border: 0;
      height: $mobileRowHeight;

      .plf-zafa-cell {
        span {
          font-size: $fontSizeTableHeadSmall;
        }
      }
    }

    // Heading row styling
    &[data-structure^='heading'] {
      background-color: map-get($mobileGrays, dark);
      border: 0;
      height: $mobileRowHeight;

      .plf-zafa-cell {
        span {
          font-size: $fontSizeTableHeadingSmall;
          -webkit-line-clamp: 2;
        }
      }
    }

    &:not([data-structure^='head']) {
      &[data-children-collapsed='false'] {
        *:not(i) {
          font-weight: bold;
        }
      }
    }

    &[data-level='1'] {
      background-color: map-get($mobileGrays, medium);
    }

    &[data-level='2'] {
      background-color: map-get($mobileGrays, light);

      td {
        &[data-type='position'] {
          padding-left: 0;
        }
      }
    }

    &[data-level='3'] {
      background-color: map-get($mobileGrays, lighter);

      td {
        &[data-type='position'] {
          padding-left: 0;
        }
      }
    }

    &[data-level='4'] {
      background-color: map-get($mobileGrays, lightest);

      td {
        &[data-type='position'] {
          padding-left: 0;
        }
      }
    }

    &.odd {
      background-color: transparent; // this is invalid, but it has to be like that to reset the BG
    }
  }

  // Generall cell style
  .plf-zafa-cell {
    vertical-align: middle;

    span {
      display: block;
      line-height: 1.3;
    }

    &.plf-is-absolute {
      position: relative;
    }

    &.plf-zafa-collapse-cell,
    &.plf-zafa-functions-cell {
      max-width: rem(40);
      min-width: rem(40);
      width: rem(40);
    }

    &[data-sort-order='0'] {
      max-width: rem(130);
      min-width: rem(130);
      padding-right: rem(10);
      width: rem(130);

      span {
        word-break: break-word;
        word-wrap: break-word;
      }
    }

    &[data-sort-order='2'] {
      width: rem(40);
    }

    &:not([data-sort-order='0'],[data-sort-order='1'], .plf-zafa-functions-cell, .plf-zafa-collapse-cell) {
      min-width: $smallValueCellWidth;
      text-align: right;

      span {
        //padding: 0 $tableLeftPaddingSmall;
      }
    }
  }

  // Functions cell style
  .plf-zafa-functions-cell {
    text-align: center;

    .plf-zafa-function {
      width: auto;

      button {
        display: table-row;
        height: 100%;
        padding-right: rem(5);

        i {
          font-size: rem(21);
        }

        &:first-of-type {
          padding-top: rem(4);
        }

        &:last-of-type {
          padding-bottom: rem(4);
        }
      }
    }
  }

  // Collaps ctrl cell style
  .plf-zafa-collapse-cell {
    width: $triggerRadiusSmall + $tableLeftPaddingSmall;

    button {
      clear: both;
      float: none;
      height: 100%;
      margin-left: rem(-10);
      margin-right: 0;
      padding-left: rem(10);
      width: 100%;

      i {
        font-size: rem(24);
        left: rem(-2);
        line-height: 100%;
        top: 0;
        vertical-align: middle;
      }

      &[data-collapsed='false'] {
        i::before {
          content: '\54';
        }
      }
    }
  }

}

// Make table full width of screen
//--------------------------------------------------------------------------------------------------
@media (max-width: $screenXsMax) {
  .plf-zafa-head-image,
  .plf-zafa-head,
  .plf-zafa-table-wrapper {
    margin-left: -$gridGutterXSmall;
    margin-right: -$gridGutterXSmall;
  }
}

@media (min-width: $screenSmMin) and (max-width: $screenSmMax) {
  .plf-zafa-head-image,
  .plf-zafa-head,
  .plf-zafa-table-wrapper {
    margin-left: -$gridGutterSmall;
    margin-right: -$gridGutterSmall;
  }
}

@media (min-width: $screenSmlMin) and (max-width: $screenSmlMax) {
  .special-col {
    padding-left: $gridGutterSmallLandscape/2;
    padding-right: $gridGutterSmallLandscape/2;
  }
}

