@viewport {
  width: auto;
}

html {
  box-sizing: border-box;
  font-size: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

* {
  &,
  &::after,
  &::before {
    box-sizing: inherit;
  }
}

body,
html {
  margin: 0;
  padding: 0;
}

body {
  @include font-regular;
  background-color: $color-white;
  color: $color-black;
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

figure {
  margin: 0;
}

::placeholder {
  color: $color-storm;
}

::selection {
  background-color: $color-red;
  color: $color-white;
}

abbr {
  text-decoration: none;
}

/*
 * START reset fieldset
 */
legend {
  display: table;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

legend + * {
  clear: both;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0.01em 0 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}
/*
 * END reset fieldset
 */

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
