@mixin font-regular {
  font-size: $font-size-regular;
  font-weight: $font-light;
  line-height: (26 / 15);
}

@mixin font-h3($line-height: 1.2) {
  font-size: $font-size-h3;
  font-weight: $font-light;
  line-height: $line-height;
  margin: 0;

  @include media-breakpoint-up(mobile) {
    font-size: $font-size-h3-mobile;
  }
}

@mixin lead {
  font-size: $font-size-h3;
  font-weight: $font-light;
  line-height: (26 / 20);

  @include media-breakpoint-up(mobile) {
    font-size: $font-size-h3-mobile;
  }

  @include media-breakpoint-up(tablet) {
    line-height: (32 / 21);
  }
}

@mixin link-hover-animation {
  color: $color-red-125;

  .a-arrows-cta {
    @include arrows-animation;
  }
}

@mixin link {
  color: $color-black;
  cursor: pointer;
  text-decoration: underline;

  @include hover {
    @include link-hover-animation();
  }

  &:focus {
    @include link-hover-animation();
  }
}
