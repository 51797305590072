.a-label {
  color: $color-granite;
  cursor: pointer;
  display: block;
  font-size: em(13, 15);
  font-weight: $font-normal;
  line-height: (16 / 13);
  margin-bottom: em(5, 13);
  padding: 0 em(10, 13);
  text-align: left;
  user-select: none;
}
