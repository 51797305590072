header,
footer,
.sv-main-header,
.plf-page-head,
.plf-sidebar,
.plf-zafa-head {
  display: none;
}

.plf-zafa-table {
  tr {
    border-bottom: rem(2) solid $colorGrayLight !important;
    border-left: 0 !important;
    height: auto !important;

    td {
      max-width: none !important;
      min-width: auto !important;
      padding-bottom: rem(10);
      padding-left: 0 !important;
      padding-top: rem(10);
      width: auto !important;

      span {
        font-weight: normal !important;
      }
    }


    &[data-level='1'] {
      td {
        &:nth-of-type(2) {
          padding-left: rem(15) !important;
        }
      }
    }

    &[data-level='2'] {
      td {
        &:nth-of-type(2) {
          padding-left: rem(30) !important;
        }
      }
    }

    &[data-level='3'] {
      td {
        &:nth-of-type(2) {
          padding-left: rem(45) !important;
        }
      }
    }

    &[data-level='4'] {
      td {
        &:nth-of-type(2) {
          padding-left: rem(60) !important;
        }
      }
    }

    &[data-structure='empty'] {
      display: none;
    }

    &[data-structure='head'] {
      border: 0 !important;
      transform: translateY(100%);

      td {
        padding-top: rem(20);
      }
    }
  }

  .plf-zafa-collapse-cell,
  .plf-zafa-functions-cell {
    display: none;
  }
}
