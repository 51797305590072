.plf-search-result-wrapper {
  .plf-search-title {
    padding-bottom: $smallSearchSpacerBig;
    padding-top: $smallSearchSpacerBig;
  }

  .plf-search-form {
    padding-bottom: $smallSearchSpacerSmall;

    .ym-fbox-check {
      margin-right: $smallSearchSpacerBig;
    }

    .sv-widget-search-input {
      padding-right: rem(16);
      padding-top: $smallSearchSpacerSmall;

      .plf-search-input {
        width: 100%;
      }

      button {
        margin-top: rem(16);
      }
    }
  }

  .plf-result-wrapper {
    .plf-search-entry-wrapper {
      padding-bottom: $smallSearchSpacerSmall;
    }
  }
}
