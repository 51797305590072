.plf-sbb-checkbox {
  display: none;
}

.plf-sbb-checkbox:checked ~ .plf-sbb-checkbox-label .plf-sbb-checkbox-box {
  &::before {
    content: '✓';
  }
}

.plf-sbb-checkbox-label {
  margin: 0;
  pointer-events: all;

  * {
    pointer-events: none;
  }

  > * {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
  }

  .plf-sbb-checkbox-box {
    color: $colorRedDark;
    height: rem(15);
    margin: rem(4) rem(5) rem(4) rem(5);
    outline: rem(2) solid $colorGrayDark;
    overflow: hidden;
    position: relative;
    width: rem(15);

    &::before {
      bottom: 0;
      content: ' ';
      font-size: rem(17);
      left: 0;
      line-height: 1;
      position: absolute;
      right: 0;
      top: 0;
      vertical-align: middle;
    }
  }
}
