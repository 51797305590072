.plf-social-wrapper {
  display: flex;
  gap: rem(5);
  justify-content: center;
  margin-top: rem(20);

  .plf-share-link {
    // why doesn't stylelint know this property? even i know it, and i'm a backend dev...
    aspect-ratio: 1; // stylelint-disable-line
    background-color: $colorGrayAlmostDark;
    border: 0;
    border-radius: rem(50);
    padding: 0;
    text-align: center;
    transition: background-color 0.1s;
    width: rem(32);

    &:hover {
      background-color: $colorCtrlHover;
      cursor: pointer;
    }

    i {
      color: $colorWhite;
      font-size: rem(20);
      position: absolute;

      &::before {
        left: rem(-10);
        position: absolute;
        top: rem(6);
      }

      &.social-icon {
        font-size: rem(16);

        &::before {
          left: rem(-8);
          top: rem(8);
        }
      }
    }
  }
}

.plf-zafa-meta-tab-social-wrapper,
.plf-zafa-deeplink-social-share-wrapper {
  .plf-social-wrapper {
    gap: rem(11);

    .plf-share-link {
      width: rem(40);

      i {
        font-size: rem(28);

        &::before {
          left: rem(-13);
          top: rem(7);
        }

        &.social-icon {
          font-size: rem(24);

          &::before {
            left: rem(-11);
            top: rem(8);
          }
        }

        &.icon-130-facebook-108::before {
          left: rem(-14);
        }
      }
    }
  }
}
