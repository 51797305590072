.a-footer-toggler {
  align-items: center;
  background: $color-white;
  border: 0;
  border-top: 1px solid $color-cloud;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: rem($footer-toggler-height-px + $font-size-regular-px);
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @include hover-focus {
    .a-icon {
      color: $color-red-125;
      transform: scale(1.1);
    }
  }

  .a-icon {
    color: $color-iron;
    display: inline-block;
    height: em($footer-toggler-height-px, $font-size-regular-px);
    pointer-events: none;
    transition: transform 0.3s ease;
    width: em($footer-toggler-height-px, $font-size-regular-px);
  }

  p {
    font-size: $font-size-footer-toggle;
    line-height: 1;
    margin: rem(-8) 0;
  }

  &--active {
    background: $color-cloud;

    @include hover-focus {
      .a-icon {
        transform: scale(1.1) rotate(180deg);
      }
    }

    .a-icon {
      transform: rotate(180deg);
    }

    p {
      display: none;
    }
  }
}
