.m-toTopLabel {
  @extend .a-btn--icon;
  background-color: rgba($color-white, 0.8);
  border: rem(2) solid $color-cloud;
  border-radius: rem(3);
  padding: 0;
  transition: bottom 0.3s ease;
  z-index: 1000;

  &:hover,
  &:focus {
    color: $color-red-125;

    .m-toTopLabel__content {
      color: $color-red-125;
    }
  }

  &__content {
    align-items: center;
    color: $color-black;
    display: flex;
    flex-direction: row;
    padding: 0 rem(10) 0 rem(20);

    .a-icon {
      height: rem(36);
      width: rem(36);
    }
  }
}
