.a-icon {
  height: rem(24);
  width: rem(24);

  path,
  polyline {
    fill: currentColor;
  }

  &--use-stroke {
    path,
    polyline {
      fill: none;
      stroke: currentColor;
    }
  }

  &__colored {
    height: rem(24);
    width: rem(24);
  }
}
