$zIndex100Negative: -100;

$zIndex1: 1;
$zIndex2: 2;
$zIndex3: 3;
$zIndex120: 120;
$zIndex150: 150;
$zIndex200: 200;
$zIndex300: 300;
$zIndex1000: 1000;
$zIndex3000: 3000;
$zIndex10000: 10000;

.sv-view-wrapper {
  > .js-header-wrapper {
    z-index: $zIndex2;
  }

  > .container-fluid {
    z-index: $zIndex1;
  }

  > footer {
    z-index: $zIndex3;
  }
}

