.m-header-brand {
  margin-top: rem(4);
  @media (min-width: $screen-sm-min) {
    margin-top: rem(12);
  }

  &__link {
    display: block;
    height: rem(48);
    margin-left: auto;
    padding: rem(10) rem(4) rem(10) 0;

    svg {
      // just a bit smaller than half view-width so there is no overlapping on tiny screens
      max-width: calc(100vw / 2.56);

      @media (min-width: $screen-xs-header-break-after) {
        max-width: rem(146);
      }

      @media (min-width: $screen-sm-min) {
        max-width: rem(186);
      }
    }
  }
}

