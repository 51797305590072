@charset "UTF-8";
$font-path: '../fonts/sbbicons';

@font-face {
  font-family: 'sbbicons';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}/sbb-icons.eot');
  src:url('#{$font-path}/sbb-icons.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/sbb-icons.woff') format('woff'),
    url('#{$font-path}/sbb-icons.ttf') format('truetype'),
    url('#{$font-path}/sbb-icons.svg#sbb-icons') format('svg');
}

[data-icon] {
  display: inline-block;
  font-size: rem(28);
  height: 1em;
  line-height: 100%;
  vertical-align: middle;
}

[data-icon]::before {
  content: attr(data-icon);
  font-family: 'sbbicons' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 1;
  speak: none;
  text-transform: none !important;
}

[class^='icon-']::before,
[class*=' icon-']::before {
  font-family: 'sbbicons' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 1;
  speak: none;
  text-transform: none !important;
}

.icon-020-pfeil-vor-038::before {
  content: '\61';
}

.icon-030-ausrufezeichen-035::before {
  content: '\62';
}

.icon-030-ok-112::before {
  content: '\63';
}

.icon-010-formular-280::before {
  content: '\64';
}

.icon-010-fabrik-305::before {
  content: '\65';
}

.icon-010-achtung-001::before {
  content: '\66';
}

.icon-010-information-011::before {
  content: '\67';
}

.icon-200-stadt-316::before {
  content: '\69';
}

.icon-190-user-bauarbeit-350::before {
  content: '\6a';
}

.icon-190-weichen-313::before {
  content: '\6b';
}

.icon-180-berge-aussicht-251::before {
  content: '\6c';
}

.icon-170-auto-parkplatz-360::before {
  content: '\6e';
}

.icon-170-bus-240::before {
  content: '\70';
}

.icon-170-container-304::before {
  content: '\72';
}

.icon-170-ersatzbus-242::before {
  content: '\73';
}

.icon-170-flugzeug-285::before {
  content: '\74';
}

.icon-170-gueterwagen-286::before {
  content: '\75';
}

.icon-170-k-r-244::before {
  content: '\76';
}

.icon-170-kombinierte-mobilitaet-324::before {
  content: '\77';
}

.icon-170-ladestation-electro-361::before {
  content: '\78';
}

.icon-170-nahverkehr-243::before {
  content: '\79';
}

.icon-170-p-r-245::before {
  content: '\7a';
}

.icon-170-selbstfahrender-bus-362::before {
  content: '\41';
}

.icon-170-taxi-246::before {
  content: '\42';
}

.icon-170-tram-247::before {
  content: '\43';
}

.icon-170-velo-248::before {
  content: '\44';
}

.icon-170-zug-249::before {
  content: '\45';
}

.icon-160-forward-161::before {
  content: '\46';
}

.icon-160-next-155::before {
  content: '\47';
}

.icon-160-pause-158::before {
  content: '\48';
}

.icon-160-play-132::before {
  content: '\49';
}

.icon-160-previous-157::before {
  content: '\4a';
}

.icon-160-record-153::before {
  content: '\4b';
}

.icon-160-rewind-170::before {
  content: '\4c';
}

.icon-160-stop-133::before {
  content: '\4d';
}

.icon-140-bewoelkt-194::before {
  content: '\4e';
}

.icon-140-bewoelkt-mit-sonne-186::before {
  content: '\4f';
}

.icon-140-dichter-nebel-326::before {
  content: '\50';
}

.icon-140-gewitter-202::before {
  content: '\51';
}

.icon-140-gewitter-bei-nacht-189::before {
  content: '\52';
}

.icon-140-leicht-bewoelkt-197::before {
  content: '\53';
}

.icon-140-mond-151::before {
  content: '\54';
}

.icon-140-nebel-208::before {
  content: '\55';
}

.icon-140-nebel-327::before {
  content: '\56';
}

.icon-140-niesel-195::before {
  content: '\57';
}

.icon-140-regen-199::before {
  content: '\58';
}

.icon-140-regen-bei-nacht-190::before {
  content: '\59';
}

.icon-140-regen-mit-sonne-198::before {
  content: '\5a';
}

.icon-140-schnee-196::before {
  content: '\30';
}

.icon-140-schnee-201::before {
  content: '\31';
}

.icon-140-schnee-gewitter-184::before {
  content: '\32';
}

.icon-140-schnee-mit-sonne-193::before {
  content: '\33';
}

.icon-140-schnee-mond-188::before {
  content: '\34';
}

.icon-140-schnee-sturm-200::before {
  content: '\35';
}

.icon-140-schneeregen-328::before {
  content: '\36';
}

.icon-140-schneeregen-mond-329::before {
  content: '\37';
}

.icon-140-schneeregen-sonne-215::before {
  content: '\38';
}

.icon-140-schneeschauer-330::before {
  content: '\39';
}

.icon-140-schneeschauer-sonne-331::before {
  content: '\21';
}

.icon-140-schneestern-185::before {
  content: '\22';
}

.icon-140-sonne-und-mond-211::before {
  content: '\23';
}

.icon-140-sonnig-149::before {
  content: '\24';
}

.icon-140-starker-regen-bei-nacht-191::before {
  content: '\25';
}

.icon-140-starker-regen-mit-sonne-192::before {
  content: '\26';
}

.icon-140-stratus-150::before {
  content: '\27';
}

.icon-140-wetter-unbekannt-332::before {
  content: '\28';
}

.icon-140-wolke-mond-187::before {
  content: '\29';
}

.icon-130-facebook-108::before {
  content: '\2a';
}

.icon-130-googleplus-110::before {
  content: '\2b';
}

.icon-130-instagram-109::before {
  content: '\2c';
}

.icon-130-twitter-107::before {
  content: '\2d';
}

.icon-130-youtube-111::before {
  content: '\2e';
}

.icon-120-aktenkoffer-2-300::before {
  content: '\2f';
}

.icon-120-aktenkoffer-090::before {
  content: '\3a';
}

.icon-120-anschluesse-154::before {
  content: '\3b';
}

.icon-120-broschuere-236::before {
  content: '\3c';
}

.icon-120-buerostuhl-087::before {
  content: '\3d';
}

.icon-120-drucker-021::before {
  content: '\3e';
}

.icon-120-funkgeraet-177::before {
  content: '\3f';
}

.icon-120-gewicht-301::before {
  content: '\40';
}

.icon-120-kein-aktenkoffer-091::before {
  content: '\5b';
}

.icon-120-laptop-167::before {
  content: '\5d';
}

.icon-120-laptop-smartphone-165::before {
  content: '\5e';
}

.icon-120-lautsprecher-2-302::before {
  content: '\5f';
}

.icon-120-lautsprecher-237::before {
  content: '\60';
}

.icon-120-monitor-085::before {
  content: '\7b';
}

.icon-120-phyische-ablage-303::before {
  content: '\7c';
}

.icon-120-scanner-178::before {
  content: '\7d';
}

.icon-120-schreibtisch-088::before {
  content: '\7e';
}

.icon-120-schreibtisch-verstellbar-089::before {
  content: '\5c';
}

.icon-120-tastatur-086::before {
  content: '\e000';
}

.icon-120-telefon-festnetz-164::before {
  content: '\e001';
}

.icon-120-telefon-gsm-172::before {
  content: '\e002';
}

.icon-120-telefon-smartphone-084::before {
  content: '\e003';
}

.icon-110-aktivitaet-in-der-umgebung-234::before {
  content: '\e004';
}

.icon-110-gps-012::before {
  content: '\e005';
}

.icon-110-gps-aus-013::before {
  content: '\e006';
}

.icon-110-hoeher-123::before {
  content: '\e007';
}

.icon-110-location-ios-235::before {
  content: '\e008';
}

.icon-110-marker-098::before {
  content: '\e009';
}

.icon-110-marker-a-099::before {
  content: '\e00a';
}

.icon-110-marker-b-100::before {
  content: '\e00b';
}

.icon-110-marker-in-der-naehe-207::before {
  content: '\e00c';
}

.icon-110-marker-karte-173::before {
  content: '\e00d';
}

.icon-110-tiefer-122::before {
  content: '\e00e';
}

.icon-100-bahnhof-117::before {
  content: '\e00f';
}

.icon-100-bahnhof-naehe-322::before {
  content: '\e010';
}

.icon-100-bildschirm-im-zug-224::before {
  content: '\e013';
}

.icon-100-billettautomat-225::before {
  content: '\e014';
}

.icon-100-buecher-papeterie-293::before {
  content: '\e015';
}

.icon-100-couponing-134::before {
  content: '\e016';
}

.icon-100-delikatessen-359::before {
  content: '\e017';
}

.icon-100-fastlane-323::before {
  content: '\e018';
}

.icon-100-geldwechsel-120::before {
  content: '\e019';
}

.icon-100-generalanzeiger-227::before {
  content: '\e01a';
}

.icon-100-gesundheit-schoenheit-294::before {
  content: '\e01b';
}

.icon-100-haltestelle-295::before {
  content: '\e01c';
}

.icon-100-hostel-119::before {
  content: '\e01d';
}

.icon-100-kauf-billettautomat-228::before {
  content: '\e01e';
}

.icon-100-lebensmittel-296::before {
  content: '\e01f';
}

.icon-100-perronanzeiger-230::before {
  content: '\e021';
}

.icon-100-rolltreppe-231::before {
  content: '\e022';
}

.icon-100-sbb-service-297::before {
  content: '\e023';
}

.icon-100-schliessfaecher-298::before {
  content: '\e024';
}

.icon-100-service-116::before {
  content: '\e025';
}

.icon-100-shopping-299::before {
  content: '\e026';
}

.icon-100-treffpunkt-118::before {
  content: '\e027';
}

.icon-100-wartesaal-232::before {
  content: '\e028';
}

.icon-100-wc-233::before {
  content: '\e029';
}

.icon-090-billett-event-032::before {
  content: '\e02a';
}

.icon-090-billett-favorit-136::before {
  content: '\e02b';
}

.icon-090-billett-kurzstrecke-143::before {
  content: '\e02c';
}

.icon-090-billett-nicht-verfuegbar-138::before {
  content: '\e02d';
}

.icon-090-billett-qrcode-026::before {
  content: '\e02e';
}

.icon-090-billett-reise-218::before {
  content: '\e02f';
}

.icon-090-billett-tageskarte-135::before {
  content: '\e030';
}

.icon-090-billette-parkieren-291::before {
  content: '\e031';
}

.icon-090-billette-sbb-069::before {
  content: '\e032';
}

.icon-090-gutschein-220::before {
  content: '\e033';
}

.icon-090-qrcode-221::before {
  content: '\e034';
}

.icon-090-qrcode-ungueltig-309::before {
  content: '\e035';
}

.icon-090-swisspass-222::before {
  content: '\e036';
}

.icon-090-uebergangs-swisspass-219::before {
  content: '\e037';
}

.icon-080-abfahrtstabelle-215::before {
  content: '\e038';
}

.icon-080-fussweg-070::before {
  content: '\e039';
}

.icon-080-komfort-288::before {
  content: '\e03a';
}

.icon-080-langsam-289::before {
  content: '\e03b';
}

.icon-080-meine-reise-071::before {
  content: '\e03c';
}

.icon-080-meine-reise-aus-072::before {
  content: '\e03d';
}

.icon-080-perlschnur-ende-139::before {
  content: '\e03e';
}

.icon-080-perlschnur-start-140::before {
  content: '\e03f';
}

.icon-080-promocode-141::before {
  content: '\e040';
}

.icon-080-prozent-078::before {
  content: '\e041';
}

.icon-080-puenktlichhkeit-216::before {
  content: '\e042';
}

.icon-080-rollstuhl-073::before {
  content: '\e043';
}

.icon-080-rollstuhl-reservation-075::before {
  content: '\e044';
}

.icon-080-rollstuhl-teilweise-074::before {
  content: '\e045';
}

.icon-080-rollstuhl-unsicher-076::before {
  content: '\e046';
}

.icon-080-rollstuhl-untauglich-077::before {
  content: '\e047';
}

.icon-080-schnell-290::before {
  content: '\e048';
}

.icon-080-touch-fahrplan-204::before {
  content: '\e049';
}

.icon-080-uhr-010::before {
  content: '\e04a';
}

.icon-080-wagen-307::before {
  content: '\e04b';
}

.icon-080-wechsel-068::before {
  content: '\e04c';
}

.icon-080-zeit-217::before {
  content: '\e04d';
}

.icon-080-zugformation-083::before {
  content: '\e04e';
}

.icon-070-dokument-bild-062::before {
  content: '\e04f';
}

.icon-070-dokument-check-176::before {
  content: '\e050';
}

.icon-070-dokument-gesperrt-354::before {
  content: '\e051';
}

.icon-070-dokument-neu-355::before {
  content: '\e052';
}

.icon-070-dokument-pdf-061::before {
  content: '\e053';
}

.icon-070-dokument-ppt-356::before {
  content: '\e054';
}

.icon-070-dokument-sbb-214::before {
  content: '\e055';
}

.icon-070-dokument-standard-067::before {
  content: '\e056';
}

.icon-070-dokument-text-063::before {
  content: '\e057';
}

.icon-070-dokument-ton-064::before {
  content: '\e058';
}

.icon-070-dokument-video-065::before {
  content: '\e059';
}

.icon-070-dokument-zip-066::before {
  content: '\e05a';
}

.icon-070-metadaten-357::before {
  content: '\e05b';
}

.icon-070-ordner-018::before {
  content: '\e05c';
}

.icon-070-ordner-dateiablage-160::before {
  content: '\e05d';
}

.icon-070-ordner-gesperrt-358::before {
  content: '\e05e';
}

.icon-070-ordner-hinzufuegen-130::before {
  content: '\e05f';
}

.icon-070-ordner-metadaten-131::before {
  content: '\e060';
}

.icon-070-ordner-oeffnen-059::before {
  content: '\e061';
}

.icon-060-berechtigung-162::before {
  content: '\e062';
}

.icon-060-geburtstag-094::before {
  content: '\e063';
}

.icon-060-user-2-093::before {
  content: '\e064';
}

.icon-060-user-3-gruppe-057::before {
  content: '\e065';
}

.icon-060-user-3-reihe-056::before {
  content: '\e066';
}

.icon-060-user-092::before {
  content: '\e067';
}

.icon-060-user-auswechslung-097::before {
  content: '\e068';
}

.icon-060-user-krawatte-351::before {
  content: '\e069';
}

.icon-060-user-polizei-352::before {
  content: '\e06a';
}

.icon-060-user-registrieren-310::before {
  content: '\e06b';
}

.icon-060-user-schulung-169::before {
  content: '\e06c';
}

.icon-060-user-sitzung-353::before {
  content: '\e06d';
}

.icon-060-user-support-156::before {
  content: '\e06e';
}

.icon-060-user-zugriff-124::before {
  content: '\e06f';
}

.icon-050-daumen-hoch-052::before {
  content: '\e070';
}

.icon-050-daumen-runtern-060::before {
  content: '\e071';
}

.icon-050-faq-212::before {
  content: '\e072';
}

.icon-050-gluehbirne-an-048::before {
  content: '\e073';
}

.icon-050-gluehbirne-aus-049::before {
  content: '\e074';
}

.icon-050-handschlag-058::before {
  content: '\e075';
}

.icon-050-netzwerk-055::before {
  content: '\e076';
}

.icon-050-onboarding-213::before {
  content: '\e077';
}

.icon-050-share-025::before {
  content: '\e078';
}

.icon-050-smiley-grinsend-273::before {
  content: '\e079';
}

.icon-050-smiley-lachend-276::before {
  content: '\e07a';
}

.icon-050-smiley-neutral-274::before {
  content: '\e07b';
}

.icon-050-smiley-traurig-275::before {
  content: '\e07c';
}

.icon-050-sprechblase-2-051::before {
  content: '\e07d';
}

.icon-050-sprechblase-3-leer-054::before {
  content: '\e07e';
}

.icon-050-sprechblase-050::before {
  content: '\e07f';
}

.icon-050-uebersetzten-287::before {
  content: '\e080';
}

.icon-040-drag-205::before {
  content: '\e081';
}

.icon-040-drei-punkte-menu-106::before {
  content: '\e082';
}

.icon-040-hamburgermenu-102::before {
  content: '\e083';
}

.icon-040-home-101::before {
  content: '\e084';
}

.icon-040-minus-2-181::before {
  content: '\e085';
}

.icon-040-minus-105::before {
  content: '\e086';
}

.icon-040-plus-2-182::before {
  content: '\e087';
}

.icon-040-plus-103::before {
  content: '\e088';
}

.icon-040-schliessen-104::before {
  content: '\e089';
}

.icon-040-touch-179::before {
  content: '\e08a';
}

.icon-030-anschalten-053::before {
  content: '\e08b';
}

.icon-030-entsperrt-113::before {
  content: '\e08c';
}

.icon-030-fragezeichen-115::before {
  content: '\e08d';
}

.icon-030-gesperrt-114::before {
  content: '\e08e';
}

.icon-030-pruefung-168::before {
  content: '\e08f';
}

.icon-030-sichtbar-096::before {
  content: '\e090';
}

.icon-030-unsichtbar-095::before {
  content: '\e091';
}

.icon-020-aendere-reihenfolge-125::before {
  content: '\e092';
}

.icon-020-aktualisieren-002::before {
  content: '\e093';
}

.icon-020-laden-007::before {
  content: '\e094';
}

.icon-020-pfeil-174::before {
  content: '\e095';
}

.icon-020-pfeil-klein-links-044::before {
  content: '\e096';
}

.icon-020-pfeil-klein-rauf-034::before {
  content: '\e097';
}

.icon-020-pfeil-klein-rechts-045::before {
  content: '\e098';
}

.icon-020-pfeil-klein-runter-043::before {
  content: '\e099';
}

.icon-020-pfeil-links-kreis-041::before {
  content: '\e09a';
}

.icon-020-pfeil-rauf-kreis-046::before {
  content: '\e09b';
}

.icon-020-pfeil-rechts-037::before {
  content: '\e09c';
}

.icon-020-pfeil-rechts-kreis-042::before {
  content: '\e09d';
}

.icon-020-pfeil-unten-kreis-040::before {
  content: '\e09e';
}

.icon-020-pfeil-vor-und-zurueck-039::before {
  content: '\e09f';
}

.icon-020-pfeil-zurueck-036::before {
  content: '\e0a0';
}

.icon-020-rundreise-047::before {
  content: '\e0a1';
}

.icon-020-vergroessern-144::before {
  content: '\e0a2';
}

.icon-020-verkleinern-145::before {
  content: '\e0a3';
}

.icon-020-verschieben-349::before {
  content: '\e0a4';
}

.icon-010-alarm-019::before {
  content: '\e0a5';
}

.icon-010-alarm-licht-334::before {
  content: '\e0a6';
}

.icon-010-apfel-und-bag-308::before {
  content: '\e0a7';
}

.icon-010-ausgang-277::before {
  content: '\e0a8';
}

.icon-010-baum-335::before {
  content: '\e0a9';
}

.icon-010-bearbeiten-016::before {
  content: '\e0aa';
}

.icon-010-benachrichtigung-015::before {
  content: '\e0ab';
}

.icon-010-bild-147::before {
  content: '\e0ac';
}

.icon-010-brieftasche-146::before {
  content: '\e0ad';
}

.icon-010-browser-159::before {
  content: '\e0ae';
}

.icon-010-bueroklammer-121::before {
  content: '\e0af';
}

.icon-010-catering-333::before {
  content: '\e0b0';
}

.icon-010-datenbank-171::before {
  content: '\e0b1';
}

.icon-010-diagramm-2-284::before {
  content: '\e0b2';
}

.icon-010-diagramm-163::before {
  content: '\e0b3';
}

.icon-010-download-027::before {
  content: '\e0b4';
}

.icon-010-download-grosse-datenmenge-128::before {
  content: '\e0b5';
}

.icon-010-download-kleine-datenmenge-129::before {
  content: '\e0b6';
}

.icon-010-e-mail-003::before {
  content: '\e0b7';
}

.icon-010-e-mail-offen-336::before {
  content: '\e0b8';
}

.icon-010-eingang-278::before {
  content: '\e0b9';
}

.icon-010-einstellungen-014::before {
  content: '\e0ba';
}

.icon-010-fakel-337::before {
  content: '\e0bb';
}

.icon-010-favorit-005::before {
  content: '\e0bc';
}

.icon-010-fehler-126::before {
  content: '\e0bd';
}

.icon-010-feldstecher-338::before {
  content: '\e0be';
}

.icon-010-filter-024::before {
  content: '\e0bf';
}

.icon-010-filter-loeschen-279::before {
  content: '\e0c0';
}

.icon-010-filter-regler-029::before {
  content: '\e0c1';
}

.icon-010-geschenk-033::before {
  content: '\e0c2';
}

.icon-010-herz-031::before {
  content: '\e0c3';
}

.icon-010-hilfe-kreis-137::before {
  content: '\e0c4';
}

.icon-010-hund-180::before {
  content: '\e0c5';
}

.icon-010-information-klein-206::before {
  content: '\e0c6';
}

.icon-010-kaffe-2-281::before {
  content: '\e0c7';
}

.icon-010-kaffe-023::before {
  content: '\e0c8';
}

.icon-010-kalender-006::before {
  content: '\e0c9';
}

.icon-010-kamera-203::before {
  content: '\e0ca';
}

.icon-010-kategorie-030::before {
  content: '\e0cb';
}

.icon-010-kontakt-166::before {
  content: '\e0cc';
}

.icon-010-kundenzufriedenheit-339::before {
  content: '\e0cd';
}

.icon-010-leuchtturm-282::before {
  content: '\e0ce';
}

.icon-010-link-152::before {
  content: '\e0cf';
}

.icon-010-link-extern-127::before {
  content: '\e0d0';
}

.icon-010-liste-272::before {
  content: '\e0d1';
}

.icon-010-marktanteil-340::before {
  content: '\e0d2';
}

.icon-010-navigation-017::before {
  content: '\e0d3';
}

.icon-010-news-175::before {
  content: '\e0d4';
}

.icon-010-notification-183::before {
  content: '\e0d5';
}

.icon-010-papierkorb-008::before {
  content: '\e0d6';
}

.icon-010-personalzufriedenheit-341::before {
  content: '\e0d7';
}

.icon-010-pin-148::before {
  content: '\e0d8';
}

.icon-010-restaurant-022::before {
  content: '\e0d9';
}

.icon-010-rss-321::before {
  content: '\e0da';
}

.icon-010-rucksack-348::before {
  content: '\e0db';
}

.icon-010-sbb-app-283::before {
  content: '\e0dc';
}

.icon-010-schraubschluessel-342::before {
  content: '\e0dd';
}

.icon-010-schweiz-343::before {
  content: '\e0de';
}

.icon-010-senden-142::before {
  content: '\e0df';
}

.icon-010-sorglos-344::before {
  content: '\e0e0';
}

.icon-010-statistik-345::before {
  content: '\e0e1';
}

.icon-010-suche-009::before {
  content: '\e0e2';
}

.icon-010-taschenlampe-on-346::before {
  content: '\e0e3';
}

.icon-010-upload-028::before {
  content: '\e0e4';
}

.icon-010-video-209::before {
  content: '\e0e5';
}

.icon-010-warenkorb-004::before {
  content: '\e0e6';
}

.icon-010-webseite-210::before {
  content: '\e0e7';
}

.icon-010-wifi-020::before {
  content: '\e0e8';
}

.icon-010-ziel-347::before {
  content: '\e0e9';
}

.icon-100-lift-229::before {
  content: '\e011';
}

.icon-100-beratung-223::before {
  content: '\e012';
}

.icon-100-bekleidung-292::before {
  content: '\e020';
}

.icon-besetzte-einfahrt::before {
  content: '\e0ea';
}

.icon-170-benzin-239::before {
  content: '\6d';
}

.icon-170-busstop-241::before {
  content: '\6f';
}

.icon-170-auto-238::before {
  content: '\71';
}
