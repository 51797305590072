.carousel {
  $caption-height: rem(60);
  margin-top: rem(10);

  .carousel-inner {
    padding-bottom: $caption-height;

    .item {
      min-height: rem(80);

      img {
        margin: auto;
        max-height: calc(80vh - #{rem(250)});
      }

      .carousel-img-desktop {
        display: block;
      }

      .carousel-img-mobile {
        display: none;
      }

      @media screen and (max-width: $screenSmMax) {
        .carousel-img-desktop {
          display: none;
        }

        .carousel-img-mobile {
          display: block;
          padding-bottom: rem(50);
        }
      }
    }

    .carousel-caption {
      bottom: -$caption-height;
      left: 0;
      padding-bottom: 0;
      right: 0;
      text-shadow: none;

      p {
        font-size: rem(16);
      }
    }
  }

  a.carousel-control {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: rem(50);
    justify-content: center;
    margin-bottom: $caption-height;
    margin-top: rem(50);
    opacity: unset;
    text-decoration: none;
    text-shadow: none;
    width: 10%;

    i {
      color: $colorBlack;
    }

    &:hover {
      i {
        color: $colorCtrlHover;
      }
    }

    &.left {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.0001) 100%);
    }

    &.right {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0.0001) 0%, rgba(255, 255, 255, 0.5) 100%);
    }
  }

  .carousel-indicators {
    bottom: rem(-40);

    .active {
      background-color: $colorBlack;
      height: rem(10);
      margin: rem(1);
      width: rem(10);
    }

    li {
      border-color: $colorBlack;
    }
  }
}
