.a-table {
  overflow: auto;
  width: 100%;

  &__table,
  table {
    background-color: $color-white;
    border: em(1, 15) solid $color-cloud;
    border-collapse: separate;
    border-spacing: 0;
    padding-bottom: 1.0667em;
  }

  thead,
  &__thead {
    background-color: $color-cloud;

    th,
    &__th {
      font-weight: $font-bold;

      &:first-child {
        border-left: $color-cloud solid 1.0667em;
      }

      &:last-child {
        border-right: $color-cloud solid 1.0667em;
      }
    }
  }

  tr,
  &__tr {
    &:nth-child(even) {
      background-color: $color-milk;
    }
  }

  th,
  td,
  &__td,
  &__th {
    border: 0;
    font-weight: $font-normal;
    line-height: (22 / 15);
    padding: em(19, 15) 0.8em;

    @include media-breakpoint-up(mobile) {
      padding-left: 1.0667em;
      padding-right: 1.0667em;
    }

    &:first-child {
      border-left: $color-white solid 1.0667em;
    }

    &:last-child {
      border-right: $color-white solid 1.0667em;
    }
  }

  &--align-center {
    text-align: center;
  }

  &--align-left {
    text-align: left;
  }

  &--align-right {
    text-align: right;
  }
}
