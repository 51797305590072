@import './mixins/alignment';
@import './mixins/arrows-cta';
@import './mixins/download-cta';
@import './mixins/global';
@import './mixins/layout';
@import './mixins/list';
@import './mixins/typo';

@mixin check-radio {
  border: em(2, $font-size-regular-px) solid $color-aluminum;
  display: inline-block;
  height: em(20, $font-size-regular-px);
  min-width: em(20, $font-size-regular-px);
  position: relative;
  width: em(20, $font-size-regular-px);
}

@mixin check-radio-field {
  position: relative;

  &,
  .a-label {
    align-items: center;
    display: flex;
  }

  &__input {
    height: em(20, 15);
    left: 0;
    opacity: 0;
    position: absolute;
    top: em(3, 15);
    width: em(20, 15);
    z-index: 1;
  }

  &__label {
    font-size: em($font-size-regular-px, $font-size-regular-px);
    line-height: line-height(26, $font-size-regular-px);
    margin: 0;
    padding: 0;
    position: relative;

    &::before {
      @include check-radio;

      border-radius: em(2, $font-size-regular-px);
      content: '';
      margin-right: em(8, $font-size-regular-px);
      position: relative;
      z-index: 0;
    }
  }

  &__input:checked + &__label {
    color: $color-black;
  }

  &__input:disabled + &__label {
    &::before {
      background-color: $color-milk;
    }
  }
}

@mixin accordion-btn-icons(
  $base-size,
  $base-size-mobile,
  $icon-color: $color-granite,
  $border-width: false
) {
  .a-accordion-btn__icon-minus,
  .a-accordion-btn__icon-plus {
    @if $border-width {
      $border-width: em(1, $base-size);
    }
    color: $icon-color;
    height: em(24, $base-size);
    right: em(24, $base-size);
    transition: border-color 0.3s ease-out, border-color 0.3s ease-out;
    width: em(24, $base-size);

    @include media-breakpoint-up(mobile) {
      @if $border-width {
        $border-width: em(1, $base-size-mobile);
      }
      height: em(32, $base-size-mobile);
      right: em(32, $base-size-mobile);
      width: em(32, $base-size-mobile);
    }
  }
}

@mixin select {
  //margin-bottom: rem(13);
  //margin-top: rem(8);

  label {
    color: $color-gray40;
    font-size: rem(13);
    font-weight: 400;
  }

  .react-select__control {
    background-color: $color-white;
    border-color: $color-graphite;
    border-radius: 0;
    border-style: solid;
    border-width: rem(2);
    box-shadow: none;
    color: $color-gray40;
    font-weight: 400;
    line-height: rem(16);
    margin-bottom: 0;
    width: 100%;

    &:hover {
      border-color: $color-gray40;
    }
  }

  .react-select__control--menu-is-open {
    border-bottom-width: 1px;
    padding-bottom: 1px;

    .react-select__dropdown-indicator {
      padding-bottom: rem(2);
      transform: rotate(180deg);
    }

  }

  .react-select__control--is-focused,
  .react-select__control--menu-is-open {
    border-color: $color-gray40;
  }

  .react-select__menu {
    border: rem(2) solid $color-gray40;
    border-radius: 0;
    border-top: 0;
    box-shadow: 0 rem(8) 0 rgba(0, 0, 0, 0.15), 0 rem(-3) 0 rem(-2) $color-cloud;
    margin-top: rem(-2);
    padding-top: 0;
  }

  .react-select__value-container,
  .react-select__placeholder,
  .react-select__input,
  .react-select__input input,
  .react-select__single-value {
    color: $color-gray40;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }


  .react-select__indicator-separator,
  .react-select__dropdown-indicator svg {
    display: none;
  }

  .react-select__dropdown-indicator {
    padding-bottom: rem(8);
    transition: transform 120ms cubic-bezier(0, 0, 0.2, 1);
  }

  .react-select__dropdown-indicator::before {
    color: $color-gray40;
    content: '\e099';
    font-family: sbbicons;
    font-size: rem(24);
  }

  .react-select__menu-list {
    border-radius: 0;
    margin: 0;
    padding: 0;
  }

  .react-select__option {
    background-color: $color-white;
    color: $color-gray40;
    font-weight: 400;
  }

  .react-select__option--is-focused {
    color: $color-red-125;
  }
}

@mixin select-mobile {
  @media (max-width: rem(980)) {
    margin-bottom: 0;

    .react-select__control {
      border-bottom: rem(0.5) solid $color-graphite;
      border-left: 0;
      border-right: 0;
      border-top: rem(0.5) solid $color-graphite;
    }

    .react-select__control--menu-is-open {
      background-color: $color-red;
      border: 0;
      flex-direction: row-reverse;
      margin-top: 0;

      .react-select__dropdown-indicator::before {
        color: $color-white;
        content: '\e089';
      }

      .react-select__value-container {
        margin-right: rem(40);
      }

      .react-select__single-value,
      .react-select__placeholder {
        color: $color-white;
        text-align: center;
      }
    }

    .react-select__control--menu-is-open ~ .react-select__menu {
      border: 0;
      box-shadow: rem(8) rem(8) rem(15) $color-cloud;

      .react-select__option {
        border-top: 1px solid $color-aluminum;
        text-align: center;
      }
    }
  }
}
@mixin header-select {
  //max-width: rem(180);

  .react-select__control {
    border: 0;
    height: rem(48);

    .react-select__indicators {
      padding-top: rem(2);
    }

    .a-icon {
      color: $color-gray40;
    }
  }

  .react-select__control:hover .react-select__single-value,
  .react-select__control:hover .a-icon,
  .react-select__control:hover .react-select__dropdown-indicator::before {
    color: $color-red-125;
  }

  .react-select__control--menu-is-open {
    border-color: $color-gray40;
    border-radius: rem(3);
    border-style: solid;
    border-width: rem(2);

    .react-select__single-value,
    .a-icon,
    .react-select__dropdown-indicator::before {
      color: $color-red-125;
    }
  }

  .react-select__menu {
    border-radius: rem(3);
    max-width: rem(180);
    right: 0;
  }

  .react-select__menu:active {
    max-width: rem(288);
  }

  .react-select__menu:focus-visible {
    max-width: rem(288);
  }

  .react-select__menu:active {
    max-width: rem(288);
  }

  .react-select__single-value {
    padding-left: rem(35);

  }

  .react-select__option {
    padding: rem(6) rem(15);
  }

  .react-select__option:first-child {
    padding-top: rem(16);
  }

  .react-select__option:last-child {
    padding-bottom: rem(16);
  }

  .a-circle-avatar .a-circle-avatar__initials {
    top: rem(6);
  }
}
