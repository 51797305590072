.m-headerMetanav {
  @include valign;
  height: 100%;

  ul {
    @include flatten-list();

    li {
      &:not(:first-of-type) {
        padding-left: rem(10);
      }

      &,
      i,
      a {
        color: $color-metal;
        text-decoration: none;
      }

      &:first-of-type,
      &:hover {
        &,
        i,
        a,
        .-homeIcon svg {
          color: $color-red-125;
          cursor: pointer;
        }
      }
    }
  }

  &__link {
    color: $color-metal;
    display: inline-block;
    font-size: $font-size-meta-nav;
    text-decoration: none;

    &:hover {
      color: $color-red;

      * {
        color: $color-red;
      }
    }

    &.-homeIcon {
      svg {
        margin-bottom: rem(-6);
      }
    }

    .-homeIconLabel {
      display: none;
    }
  }
}

//on collapse trigger click
.o-header__wrapper[data-clicked='true'] .m-headerMetanav {
  display: none;
}
