
.plf-zafa-widget-popup-overlay {
  bottom: 0;
  display: none;
  font-size: 0;
  height: 85%;
  left: 0;
  margin: auto;
  max-height: 90vh;
  max-width: rem(700);
  position: fixed;
  right: 0;
  top: 0;
  width: 65%;
  z-index: $zIndex3;

  @media screen and (max-width: $screenSmMax) {
    max-width: 90%;
    width: auto;
  }

  &::before {
    background-color: rgba($colorGrayLight, 0.8);
    content: ' ';
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $zIndex1;
  }

  a {
    text-decoration: none;

    i {
      padding-top: rem(2);
      position: absolute;
    }

    span {
      padding-left: rem(20);
    }


    &:hover {
      span,
      i {
        color: $colorCtrlHover;
      }

    }
  }

  .js-widget-popup-title {
    width: 100%;
  }

  .plf-zafa-widget-popup-ctrls {
    background-color: $colorWhite;
    display: block;
    font-size: rem(16);
    height: 100%;
    padding: rem(20) rem(25);
    position: relative;
    width: 100%;
    z-index: $zIndex120;

    h2 {
      font-weight: 300;
      width: 90%;
    }

    button {
      &.plf-zafa-popup-widget-continue {
        bottom: rem(30);
        display: none;
        margin-top: rem(15);
        position: absolute;
        right: rem(25);
        width: rem(150);

        @media screen and (max-width: $screenXsMax) {
          width: calc(100% - #{rem(50)});
        }

        &[data-visible='true'] {
          display: block;
        }
      }

      &.plf-zafa-popup-widget-previous {
        bottom: rem(30);
        display: none;
        left: rem(25);
        margin-top: rem(15);
        position: absolute;
        width: rem(150);

        @media screen and (max-width: $screenXsMax) {
          bottom: rem(70);
          width: calc(100% - #{rem(50)});
        }

        &[data-visible='true'] {
          display: block;
        }
      }

      &.plf-zafa-popup-widget-close-overlay {
        background: none;
        border: 0;
        position: absolute;
        right: rem(15);
        top: rem(22);

        &:hover {
          color: $color-red-125;
        }
      }
    }



    .plf-zafa-widget-scrollable-container {

      @media screen and (max-height: rem(825)) {
        height: calc(100% - #{rem(180)});
        overflow: auto;
      }

      .plf-zafa-widget-popup-overlay-page {
        display: none;

        &[data-visible='true'] {
          display: block;
        }

        img {
          margin-bottom: rem(20);
          max-height: rem(200);
          max-width: 80%;
        }

        .plf-zafa-widget-popup-checkbox,
        .plf-zafa-widget-popup-radio {
          display: none;

          &[data-visible='true'] {
            display: block;
          }
        }

        .plf-zafa-widget-input-checkbox-wrapper,
        .plf-zafa-widget-input-radio-wrapper {
          cursor: pointer;
          display: block;
          font-size: rem(22);
          margin-bottom: 0;
          padding-left: rem(35);
          position: relative;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          span {
            font-size: rem(16);
          }

          @media screen and (max-width: $screenXsMax) {
            img {
              padding-right: rem(10);
            }
          }

        }

        input[type='radio'],
        input[type='checkbox'] {
          cursor: pointer;
          font-size: rem(16);
          height: 0;
          opacity: 0;
          position: absolute;
          width: 0;
        }

        .radiobutton {
          background-color: $colorWhite;
          border: rem(1) solid $colorBlack;
          border-radius: 50%;
          height: rem(15);
          left: 0;
          margin-top: rem(9);
          position: absolute;
          top: 0;
          width: rem(15);
        }

        input:checked ~ .radiobutton {
          &::after {
            background: $colorRedLight;
            border-radius: 50%;
            content: '';
            font-size: rem(20);
            height: rem(9);
            left: rem(2);
            position: absolute;
            top: rem(2);
            width: rem(9);
          }
        }

        .checkbox {
          background-color: $colorWhite;
          border: rem(1) solid $colorBlack;
          border-radius: rem(2);
          height: rem(15);
          left: 0;
          margin-top: rem(9);
          position: absolute;
          top: 0;
          width: rem(15);

          i {
            display: none;
          }
        }

        input:checked ~ .checkbox {
          i {
            color: $colorRedLight;
            display: block;
            font-size: rem(20);
            margin-left: rem(-4);
            margin-top: rem(-6);
          }
        }

        .widget-controls {
          margin-top: rem(10);
          padding-left: 0;
          padding-right: 0;
          //
          //&.widget-controls-first-after-diagramm {
          //  margin-top: rem(45);
          //}

          .plf-widget-popup-overlay-wrapper {
            max-width: 50%;
          }

          &:first-of-type {
            margin-top: 0;
          }

          @media screen and (max-width: $screenXsMax) {
            padding-right: 0;

            img {
              max-height: none;
              max-width: 100%;
            }
          }

          @media screen and (max-width: $screen-sm-max) {
            .plf-widget-popup-overlay-wrapper {
              max-width: 100%;
            }

          }
        }

        textarea {
          display: flex;
          font-size: rem(14);
          height: rem(110);
          margin-bottom: rem(10);
          margin-top: rem(10);
          width: 100%;
        }

        .plf-zafa-widget-image-selector {
          padding-left: rem(0);

          .thumbnail {
            border: 0;

            &.selected {
              background-color: $colorRed;
              height: rem(162);
            }

            img {
              margin: 0;
              max-height: 100%;
              max-width: 100%;
            }
          }
        }

      }
    }
  }

  &[data-visible='true'] {
    display: block;
  }
}
