.plf-zafa-deeplink-overlay {
  bottom: 0;
  display: none;
  left: 0;
  margin: auto;
  max-height: rem(540);
  position: fixed;
  right: 0;
  top: 0;
  width: 40%;
  z-index: $zIndex200; // smaller than the z-index of the header

  &::before {
    background-color: rgba($colorGrayLight, 0.8);
    content: ' ';
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $zIndex1;
  }

  .plf-zafa-deeplink-msg {
    left: 0;
    margin: 0 auto;
    opacity: 0;
    position: absolute;
    right: 0;
    width: rem(100);

    > div {
      color: $colorRedDark;
      display: block;
      font-size: rem(12);
      text-align: center;
    }
  }

  .plf-zafa-deeplink-ctrls {
    background-color: $colorWhite;
    display: block;
    height: 100%;
    padding: rem(20) rem(25);
    position: relative;
    width: 100%;
    z-index: $zIndex120;

    .margin-top-25 {
      margin-top: rem(25);
    }

    input {
      border: rem(1) solid $colorGrayAlmostDark;
      font-size: rem(16);
      height: rem(40);
      padding: 0 rem(10);
      width: 100%;
    }

    .js-button-wrapper {
      margin-top: rem(20);

      button,
      .plf-tooltip-wrapper.zafa-button {
        display: block;
        margin: 0 auto;

        @media screen and (max-width: $screenXsMax) {
          width: 100%;
        }
      }
    }

    .plf-zafa-deeplink-close-overlay {
      background: none;
      border: 0;
      position: absolute;
      right: rem(15);
      top: rem(15);

      &:hover {
        color: $color-red-125;
      }
    }
  }

  &[data-visible='true'] {
    display: block;
  }
}

.plf-zafa-deeplink {
  height: 80%;
  max-height: rem(540);
  width: 100%;

  .plf-zafa-deeplink-ctrls {
    background-color: $colorWhite;
    height: 100%;
    width: 100%;

    .plf-zafa-deeplink-msg {
      left: 0;
      margin: 0 auto;
      opacity: 0;
      position: absolute;
      right: 0;
      width: rem(100);

      > div {
        color: $colorRedDark;
        display: block;
        font-size: rem(12);
        text-align: center;
      }
    }

    input {
      border: rem(1) solid $colorGrayAlmostDark;
      font-size: rem(16);
      height: rem(40);
      padding: 0 rem(10);
      width: 100%;
    }

    .js-button-wrapper {
      margin-top: rem(20);

      button,
      .plf-tooltip-wrapper.zafa-button {
        display: block;
        margin: 0 auto;

        @media screen and (max-width: $screenXsMax) {
          width: 100%;
        }
      }
    }
  }

  &[data-visible='true'] {
    display: block;
  }
}

.plf-zafa-generate-widget {
  .plf-zafa-generate-widget-ctrls {
    button {
      display: block;
      margin: 0 auto;

      @media screen and (max-width: $screenXsMax) {
        width: 100%;
      }
    }
  }
}
