@mixin flatten-list($exclude-li: false, $margin: 0) {
  list-style-type: none;
  margin: $margin;
  padding: 0;

  li {
    @if $exclude-li == false {
      display: inline-block;
    }
  }
}
