.plf-diagram-wrapper {
  //padding: 50px 20px;
  > div {
    display: inline-block;
  }

  hr {
    padding: 0 rem(4);
  }

  .plf-diagram-text-wrapper {
    h2 {
      color: $colorBlack;
      font-size: $fontSizeCellDiagramTitle;
      font-weight: $fontWeightCellDiagramTitle;
      margin-left: auto;
      margin-right: auto;
      max-width: 80%;
    }
  }

  .plf-diagram-container {
    margin: 0 auto;
    max-width: 80%;
  }
}

@media (max-width: $screenMdMax) {
  @import 'mobile/diagrams';
}
