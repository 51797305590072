.m-teaser {
  background-color: $color-milk;
  display: block;
  height: 100%;
  position: relative;
  transition: background-color 225ms ease-in-out;

  @include hover {
    background-color: $color-aluminum;
    color: $color-black;

    .a-img {
      &__image {
        transform: scale3d(1.07, 1.07, 1.07);
      }
    }

    .a-arrows-cta {
      @include arrows-animation;
    }
  }

  &__body {
    padding: em(24, 15) em(12, 15);

    @include media-breakpoint-up(mobile) {
      padding: em(32, 15) em(24, 15);
    }
  }

  &__title {
    @include font-h3;
  }

  &__block-link {
    color: inherit !important;
    text-decoration: inherit !important;

    &::after {
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  &__text {
    display: none;

    @include media-breakpoint-up(mobile) {
      display: block;
    }
  }

  .a-img {
    padding-top: 62.0155%;

    &__image {
      transform: scaleX(1);
      transition: transform 0.3s ease-out, opacity 0.35s ease-in;
    }
  }

  .a-arrows-cta {
    margin-top: em(9, 15);

    @include media-breakpoint-up(mobile) {
      bottom: 3.2em;
      left: em(24, 15);
      margin-top: 0;
      position: absolute;
    }
  }

  &--centered {
    text-align: center;
  }

  &--cta {
    .m-teaser__body {
      padding-bottom: em(32, 15);

      @include media-breakpoint-up(mobile) {
        padding-bottom: em(104, 15);
      }

      @include media-breakpoint-up(tablet) {
        padding-bottom: em(112, 15);
      }
    }
  }

  &:focus-within {
    background-color: $color-aluminum;
    color: $color-black;

    .a-img {
      &__image {
        transform: scale3d(1.07, 1.07, 1.07);
      }
    }

    .a-arrows-cta {
      @include arrows-animation;
    }
  }
}
