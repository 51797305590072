@keyframes loading_container {
  0% {
    transform: translateX(2.33333em);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes loading_container_tiny {
  0% {
    transform: translateX(0.73333em);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes loading_container_small {
  0% {
    transform: translateX(1.2em);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes loading_container_medium {
  0% {
    transform: translateX(2.3em);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes loading_container_big {
  0% {
    transform: translateX(3.46666em);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes loading_rectangle1 {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loading_rectangle2 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes loading_rectangle3 {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes loading_rectangle4 {
  0% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes loading_rectangle5 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.25;
  }
}

.sbb-loading-animation {
  display: flex;
  height: 6em;
  padding-top: 2.4em;

  > div {
    backface-visibility: hidden;
    height: 1.2em;
    margin: 0 auto;
    perspective: 11.33333em;
    transform: translate3d(-3em, 0, 0);
    transform-origin: center;
    width: 11.33332em;

    > div {
      backface-visibility: hidden;
      position: relative;
      transform: rotateY(50deg) translateZ(1em);
      transform-origin: right;

      > div {
        animation: 0.5s linear infinite loading_container;
        display: flex;
        position: relative;

        > div {
          backface-visibility: hidden;
          background: $color-red;
          height: 1.2em;
          margin-right: 0.33333em;
          outline: transparent solid 1px;
          width: 2em;

          &:nth-child(1) {
            animation: 0.5s linear infinite loading_rectangle1;
          }

          &:nth-child(2) {
            animation: 0.5s linear infinite loading_rectangle2;
          }

          &:nth-child(3) {
            animation: 0.5s linear infinite loading_rectangle3;
          }

          &:nth-child(4) {
            animation: 0.5s linear infinite loading_rectangle4;
          }

          &:nth-child(5) {
            animation: 0.5s linear infinite loading_rectangle5;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.sbb-loading-tiny.sbb-loading-animation {
  height: 1.66665em;
  padding-top: 0.66666em;

  > div {
    height: 0.33333em;
    perspective: 6em;
    width: 3.46665em;

    > div > div {
      animation: 0.5s linear infinite loading_container_tiny;

      > div {
        height: 0.33333em;
        margin-right: 0.2em;
        transform: translate3d(-0.1em, 0, 0);
        width: 0.53333em;
      }
    }
  }
}

.sbb-loading-small.sbb-loading-animation {
  height: 3em;
  padding-top: 1.2em;

  > div {
    height: 0.6em;
    perspective: 8em;
    width: 5.8em;

    > div > div {
      animation: 0.5s linear infinite loading_container_small;

      > div {
        height: 0.6em;
        margin-right: 0.2em;
        width: 1em;
      }
    }
  }
}

.sbb-loading-medium.sbb-loading-animation {
  height: 6em;
  padding-top: 2.4em;

  > div {
    height: 1.2em;
    perspective: 12em;
    width: 11.2em;

    > div > div {
      animation: 0.5s linear infinite loading_container_medium;

      > div {
        height: 1.2em;
        margin-right: 0.3em;
        width: 2em;
      }
    }
  }
}

.sbb-loading-big.sbb-loading-animation {
  height: 9em;
  padding-top: 3.6em;

  > div {
    height: 1.8em;
    perspective: 16.66666em;
    width: 16.86664em;

    > div > div {
      animation: 0.5s linear infinite loading_container_big;

      > div {
        height: 1.8em;
        margin-right: 0.46666em;
        width: 3em;
      }
    }
  }
}
