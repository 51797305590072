$input-height: em(48, 15);
$input-border-width: em(2, 15);
$search-submit-min-width: em(54, 15);

.a-text-input {
  background-color: $color-white;
  border: em(2, 15) solid $color-graphite;
  border-radius: em(2, 15);
  color: $color-granite;
  height: $input-height;
  padding: em(9, 15) em(14, 15) em(9, 15);
  width: 100%;

  @include placeholder {
    color: $color-storm;
  }

  &:focus:not(&--error) {
    border-color: $color-granite;
  }

  &--disabled,
  &:disabled {
    background-color: $color-milk;
    border-color: $color-aluminum;
  }

  &--error {
    border-color: $color-red;
    color: $color-red;
  }

  &--search {
    padding-right: em((54 + 16), 15);
  }
}
