@mixin gradient($c: $colorBlack, $p: 95%) {
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, $c $p);
}

@mixin resetSpacing() {
  margin: 0;
  padding: 0;
}

@mixin resetOutline() {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

@mixin resetLinks() {
  a,
  a:hover {
    border-bottom: 0;
    text-decoration: none;
  }
}

@mixin dynamicCountWidth($siblingTag, $count: 0) {
  @for $i from 1 through $count {
    &:first-child:nth-last-child(#{$i}),
    &:first-child:nth-last-child(#{$i}) ~ #{$siblingTag} {
      width: calc(100% / #{$i});
    }
  }
}
