.m-headerSidebar {
  background-color: $color-white;
  border-right: rem(8) solid rgba(0, 0, 0, 0.15);
  bottom: 0;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  height: unset;
  justify-content: space-between;
  left: 0;
  min-height: 0 !important;
  overflow: auto;
  padding-top: rem(26);
  position: fixed;
  top: rem(48);
  transform: translateX(0%);
  transition: transform 0.8s ease;
  width: 52%;
  z-index: $zIndex120 + 1; // Is set fixed, due to the dynamic z-indexes of the tiles

  @media (min-width: $screen-sm-min) {
    top: rem(64);
    width: 40%;
  }

  @media (min-width: rem(880)) {
    width: 30%;
  }

  @media (min-width: $screen-md-min) {
    width: 25%;
  }

  // Toggling
  &[data-open='false'] {
    transform: translateX(-101%);

    &::after {
      opacity: 0;
    }
  }

  &__mainNavWrapper,
  &__metaNavWrapper {
    .m-header-main-nav,
    .m-headerMetanav {
      display: block;

      ul {
        @include flatten-list(true);
        width: 100%;

        li {
          list-style: none;
        }
      }
    }

    .m-linkList {
      .m-linkList__item {
        cursor: pointer;
        display: inline-block;
        font-size: $font-size-header-sidebar;
        margin: 0;
        padding: rem(10) rem(24);
        position: relative;
        width: 100%;

        .m-header-mainnav-link,
        .m-headerMetanav__link, {
          color: $color-iron;
          font-size: $font-size-header-sidebar;
          text-decoration: none;

          // STORYBOOK CHANGE
          &.a-link__activeLink {
            text-decoration: underline;
          }
        }

        &:hover .m-header-mainnav-link {
          color: $color-red-125;
        }
      }
    }

    .m-headerMetanav .m-linkList .m-linkList__item {
      .m-headerMetanav__link {
        color: $color-metal;

        &.-homeIcon {
          svg {
            color: $color-metal;
          }

          .-homeIconLabel {
            display: inline-block;
            margin-left: rem(4);
          }
        }
      }

      &:hover .m-headerMetanav__link {
        color: $color-red;

        &.-homeIcon {
          svg {
            color: $color-red;
          }
        }
      }
    }
  }

  &__languageSwitchWrapper {
    border-top: 1px solid $color-cloud;
    min-height: rem(100);
    padding: rem(10) rem(24);

    .m-languageSwitch {
      width: rem(120);

      .m-headerActionsDropdownToggle__label {
        font-size: $font-size-header-sidebar;
      }

      .m-headerActionsDropdown {
        padding-top: rem(48);
        width: rem(130);

        &.-closed {
          width: 0;
        }
      }
    }
  }

  .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: rem(-1);
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.m-headerSidebar__overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $zIndex120; // Is set fixed, due to the dynamic z-indexes of the tiles

  &[data-open='false'] {
    display: none;
  }
}

// STORYBOOK CHANGE
.m-headerSidebar {
  .m-header-main-nav {
    .m-linkList__item:hover {
      .m-header-main-nav__subMenu {
        display: block;
      }
    }

    &__subMenu {
      display: none;
      font-size: rem(14);
      padding-left: rem(16);

      .m-header-main-nav__subMenuLink {
        text-decoration: none;

        &:hover,
        &:focus {
          color: $color-red-125;
        }
      }

      li[data-active='true'] .m-header-main-nav__subMenuLink {
        text-decoration: underline;
      }
    }
  }
}
