.a-accordion-panel {
  &--closed {
    display: none;
  }

  &--footer {
    @include media-breakpoint-up(tablet) {
      display: block !important;
      height: auto !important;
    }
  }
}
