/* stylelint-disable meowtec/no-px */

// < 421px
$screenXsMax: 420px;
$gridGutterXSmall: 12px;
$gridViewPortSpacingXSmall: $gridGutterXSmall;

// 421px - 642px
$screenSmMin: 421px;
$screenSmMax: 642px;
$gridGutterSmall: 24px;
$gridViewPortSpacingSmall: $gridGutterSmall;

// 643px - 1024px
$screenSmlMin: 643px;
$screenSmlMax: 1024px;
$gridGutterSmallLandscape: 16px;
$gridViewPortSpacingSmallLandscape: 6.5%;

// 1025px - 1280px
$screenMdMin: 1025px;
$screenMdMax: 1280px;
$gridGutterMedium: 16px;
$gridViewPortSpacingMedium: 7.5%;


// 1281px - 1920px
$screenNormalMin: 1281px;
$screenNormalMax: 1920px;
$gridGutterNormal: 16px;
$gridViewPortSpacingNormal: 8.5%;

// 1921px - 2560px
$screenLgMin: 1921px;
$screenLgMax: 2560px;
$gridGutterLarge: 16px;
$gridViewPortWidthLarge: 1592px;

// 2561px - 3840px
$screenXlgMin: 2561px;
$screenXlgMax: 3840px;
$gridGutterXLarge: 24px;
$gridViewPortSpacingXLarge: 19%;

// 3841px <
$screenXxlMin: 3841px;
$gridGutterXXLarge: 32px;
$gridViewPortWidthXXLarge: 2656px;

// Breakpoints
$screen-xs-max: $screenXsMax;
$screen-sm-min: $screenSmMin;
$screen-sm-max: 980px;
$screen-md-min: 981px;
$screen-md-max: $screenNormalMax;
$screen-lg-min: $screenLgMin;

// STORYBOOK CHANGE
$zafaEndMobileBreakpoint: 1479px;
$zafaBeginDesktopBreakpoint: 1480px;
