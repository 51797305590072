/* stylelint-disable meowtec/no-px */
$grid-gutter: 30px;

.l-grid {
  @include grid-fallback($grid-gutter);

  @for $i from 2 through 4 {
    &--#{$i} {
      @include grid-fallback-cols($i, $grid-gutter);
    }
  }
}

@supports (display: grid) {
  .l-grid {
    grid-gap: $grid-gutter;

    grid-template-rows: auto;
    margin-bottom: 0;
    transform: none;
    @include media-breakpoint-up(medium) {
      display: grid;
    }

    .l-col {
      width: 100%;
    }

    @for $i from 2 through 4 {
      &--#{$i} {
        grid-template-columns: repeat($i, 1fr);
      }
    }
  }

  .l-col {
    grid-column: auto;
    grid-row: auto;
    margin: 0;
  }

  @for $i from 2 through 4 {
    .l-col--#{$i} {
      grid-column: auto / span #{$i};
    }
  }
}
// - 359
$screen-xs-header-break: 359px;
$screen-xs-header-break-after: 360px;

// < 421px
$screen-xs-max: 420px;
$grid-gutter-x-small: 12px;
$grid-view-port-spacing-x-small: $grid-gutter-x-small;

// - 642px
$screen-s-min: 421px;
$screen-s-max: 642px;

// - 720
$screen-sm-header-break: 720px;
$screen-sm-header-break-upper: 721px;
// 721 -

// 880px -

// 643px - 980px
$screen-sm-min: 643px;
$screen-sm-max: 980px;
$grid-gutter-small: 24px;
$grid-viewport-spacing-small: $grid-gutter-small;

// 981px - 1024px
$screen-sml-min: 981px;
$screen-sml-max: 1024px;
$grid-gutter-small-landscape: 16px;
$grid-viewport-spacing-small-landscape: $grid-gutter-small;
//$grid-viewport-spacing-small-landscape: $grid-gutter-small;

// 1025px - 1280px
$screen-md-min: 1025px;
$screen-md-max: 1280px;
$grid-gutter-medium: 16px;
$grid-viewport-spacing-medium: 5.5%;
//$grid-viewport-spacing-medium: $grid-gutter-small;

// 1281px - 1419px
//$screen-mdl-min: 1281px;
//$screen-mdl-max: 1419px;
//$grid-gutter-mdl: 16px;
//$grid-viewport-spacing-mdl: 8.5%;
//$grid-viewport-spacing-mdl: 7.5%;

// 1420px - 1920px
$screen-normal-min: 1281px;
$screen-normal-max: 1920px;
$grid-gutter-normal: 16px;
$grid-viewport-spacing-normal: 8.5%;

// 1921px - 2560px
$screen-lg-min: 1921px;
$screen-lg-max: 2560px;
$grid-gutter-large: 16px;
$grid-viewport-width-large: 1592px;

// 2561px - 3840px
$screen-xlg-min: 2561px;
$screen-xlg-max: 3840px;
$grid-gutter-x-large: 24px;
$grid-viewport-spacing-x-large: 19%;

// 3841px <
$screen-xxl-min: 3841px;
$grid-gutter-xx-large: 32px;
$grid-viewport-width-xx-large: 2656px;

// Breakpoints
$bp-small: 88rem; // 880px
$bp-medium: 102rem; // 1024px
$bp-large: 128rem; // 1280px
$bp-xlarge: 192rem; // 1920px
$bp-xxlarge: 2560rem; // 2560px
$screen-sm-max: 980px;
$screen-md-lower: 981px;
$screen-md-mid: 1300px;

// Max-widths
$max-width-text: $bp-small;
$max-width-content: $bp-large;
$max-width-content-background: $bp-xlarge;
$max-width-title-picture: $bp-xlarge;

// Icons
$nav-icons-li-big: 1300px;
$nav-icons-li-medium: 1240px;
$nav-icons-li-small: 1080px;
