.o-typeahead-search {
  $button-width: rem(53);
  $border-size: rem(2);
  position: relative;

  &__overlay {
    background-color: $color-white;
    height: rem(48);
    left: 0;
    min-width: rem(100);
    opacity: 0;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.4s, right 1s ease;
    width: auto;
    z-index: -100;
  }

  &__form {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__searchInput {
    border: rem(2) solid $color-storm;
    border-right: 0;
    flex: 1;
    outline: none;
    padding: 0 rem(15);
  }

  &__buttonBar {
    border: rem(2) solid $color-storm;
    border-left: 0;
    display: inline-flex;
    height: 100%;
  }

  &__button {
    align-items: center;
    background-color: $color-white;
    border: 0;
    color: $color-metal;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    padding: rem(8) rem(15);
    transition: background 0.3s ease;

    &.-primary {
      background-color: $color-red;
      color: $color-white;

      &:focus,
      &:hover {
        background-color: $color-red-125;
      }
    }
  }

  &__overlayTrigger {
    display: none;

    &:checked {
      ~ .o-typeahead-search__overlay {
        opacity: 1;
        right: 0;
        transition: opacity 0.3s 0.1s ease, right 0.4s ease !important;
        z-index: z-index(search, searchOverlay);
      }

      ~ .o-typeahead-search {
        &__triggerLabel {
          &::before,
          &::after {
            bottom: 0;
            content: ' ';
            height: 100%;
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: z-index(search, searchOverlay);
          }
        }
      }
    }
  }
}

@media (max-width: map-get($breakpoints, tablet)) {
  .o-typeahead-search {
    &__overlay {
      left: 0;
      position: fixed;
      right: 0;
      top: $header-height-mobile;
      transform: translateY(-100vh);
      transition: opacity 0.4s, right 1s ease, transform 0s ease 0.4s;
      width: 100%;
    }

    &__overlayTrigger:checked {
      ~ .o-typeahead-search {
        &__triggerLabel {
          &::before,
          &::after {
            content: none;
          }

          i {
            color: $color-red-125;

            &::before {
              content: '\e97f';
            }
          }
        }
      }

      ~ .o-typeahead-search__overlay {
        opacity: 1;
        transform: translateY(0);
        z-index: z-index(search, searchOverlay);
      }
    }
  }
}
