/* stylelint-disable meowtec/no-px */
$font-size-regular-px: 15px;
$font-size-regular: rem($font-size-regular-px);
$font-size-regular-xlarge: rem(23);
$font-size-regular-xxlarge: rem(30);

$font-size-h1: em(28, $font-size-regular-px);
$font-size-h1-mobile: em(30, $font-size-regular-px);
$font-size-h1-tablet: em(40, $font-size-regular-px);

$font-size-h2: em(24, $font-size-regular-px);
$font-size-h2-tablet: em(32, $font-size-regular-px);

$font-size-h3: em(20, $font-size-regular-px);
$font-size-h3-px: 20px;
$font-size-h3-mobile: em(21, $font-size-regular-px);
$font-size-h3-mobile-px: 21px;

$font-size-metalink: rem(14);

// arrows cta

$arrow-position-x: em(10, $font-size-regular-px);
$arrow-visible-side-padding: em(30, $font-size-regular-px);
$arrow-animation-duration: 0.4s;
$arrow-animation-easing: ease;

// download cta

$download-position-x: $arrow-position-x;
$download-visible-side-padding: $arrow-visible-side-padding;
$download-animation-duration: $arrow-animation-duration;
$download-animation-easing: $arrow-animation-easing;

// Header dimensions

$header-height-small: rem(48);
$header-height-mobile: $header-height-small;
$header-height-tablet: rem(64);
$header-height-desktop: rem(130);
$header-height-ultra: rem(161);

$header-actions-height: rem(48);
$sidebar-trigger-height: $header-actions-height;
$sidebar-shadow-width: rem(8);

$font-size-header-base: rem(14);
$font-size-header-sidebar: rem(16);
$font-size-meta-nav: $font-size-header-base;
$user-detail-padding: rem(7) rem(15);
$font-size-main-nav: rem(18);
$font-weight-main-nav: 300;
$font-size-lead-nav: rem(19);
$font-weight-lead-nav: 200;

// footer

$font-size-footer-toggle: rem(12);
$footer-section-base-size: 14px;
$footer-bottom-height-px: 72px;
$footer-toggler-height-px: 40px;
$footer-bottom-height-mobile-px: 96px;
$footer-fixed-padding-top: 54px;

// Ascending order of z-index items
// The further down the item is placed here, the higher the index
$z-indexes: (
  page-layout: (
    footer,
  ),
  footer: (
    toTopButton,
    sectionsContainer,
    footerBottom,
  ),
  header: (
    headerBottom,
    headerTop,
  ),
  headerTop: (
    headerTopRight,
    headerTopLeft,
  ),
  search: (
    searchOverlay,
  ),
);
