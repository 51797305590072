.o-footer-bottom {
  background: $color-white;
  text-align: center;

  .container-fluid {
    display: flex;
    justify-content: flex-start;
    min-height: rem($footer-bottom-height-mobile-px);

    @include media-breakpoint-up(mobile) {
      justify-content: space-between;
      min-height: rem($footer-bottom-height-px);
    }

    // ie11 bug fixing
    // remove if clock is added
    &::before {
      content: '';
      display: block;
      margin-left: -#{1rem};
      min-height: inherit;
      width: rem(1);
    }

    .m-linkList--footer-bottom {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: rem(30) 0 rem(30) rem(30);

      @include media-breakpoint-up(mobile) {
        flex: 1 1 auto;
        flex-direction: row;
        justify-content: flex-end;
      }

      li {
        @include media-breakpoint-up(mobile) {
          margin-left: em(48, $font-size-regular-px);
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
