.o-header-top {
  display: flex;
  height: 50%;
  justify-content: space-between;
  z-index: z-index(header, headerTop);

  > * {
    height: 100%;
  }

  &__left,
  &__right {
    display: flex;
  }

  &__collapse-trigger {
    display: none;
  }

  &__actions {
    justify-self: flex-end;
    margin-right: rem(40);
  }

  &__brand-container {
    justify-self: flex-end;
  }

  @media (max-width: $screen-s-max) {
    justify-content: normal;

    &__right {
      flex: 8;
    }

    &__collapse-trigger {
      flex: 1;
    }

    &__actions {
      flex: 7;
      justify-self: normal;
      margin-right: 0;
    }
  }
}
