// 2561 - MAX Enlarged Mode               - ultra
// 1921 - 2560                            - wide
// 1281 - 1920                            - desktop-lg
// 1025 - 1280                            - desktop
// 721 - 1024 Dropdown left is shown      - tablet
// 643 - 720 Header Labels removed        - tablet-sm
// 360 - 642 Body switched to mobile view - mobile
// MIN - 359 Header Spacing removed       - mobile-sm

.o-header {
  height: $header-height-mobile;
  // STORYBOOK CHANGE
  position: fixed;
  z-index: 300;

  @media (min-width: $screen-md-min) {
    height: $header-height-desktop;
  }

  .o-header__wrapper {
    background-color: $color-white;
    border-bottom: rem(1) solid $color-cloud;
    height: $header-height-mobile;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: height 0.5s ease 0.2s;

    @media (min-width: $screen-sm-min) {
      height: $header-height-tablet;
    }

    // STORYBOOK CHANGE
    @media (min-width: $zafaBeginDesktopBreakpoint) {
      height: $header-height-desktop;
    }

    .o-header__skip-lines {
      background: transparent;
      left: 0;
      position: fixed;
      top: 0;
      z-index: 400200;
    }
  }

  //collapsed header
  .o-header__wrapper[data-collapsed='true'] {
    height: $header-height-tablet;

    .o-header-top {
      height: 100%;
    }

    .o-header-bottom {
      opacity: 0;
      pointer-events: none;
      transition-delay: -0.3s;
      z-index: -100;
    }

    .o-header-top__metaNav {
      display: none;
    }
  }

  .o-header__wrapper[data-collapsed='false'] {
    .o-header-top__collapse-trigger {
      display: none;
    }
  }

  // STORYBOOK CHANGE
  @media (min-width: $zafaEndMobileBreakpoint) {
    .o-header__wrapper[data-collapsed='true'] {
      .o-header-top__collapse-trigger {
        display: block;
      }
    }
  }

  .o-header__wrapper[data-clicked='true'] .o-header-top__collapse-trigger {
    display: block;

    button {
      i {
        color: $color-red-125;
      }

      span {
        display: none;
      }
    }
  }

  //on collapse trigger click
  .o-header__wrapper[data-clicked='true'] .o-header-top__metaNav {
    display: none;
  }

  // STORYBOOK CHANGE
  @media (max-width: $zafaEndMobileBreakpoint) {
    .o-header__wrapper {
      &[data-collapsed='true'] {
        height: rem(65);
      }

      .o-header-bottom,
      .o-header-top__metaNav {
        display: none;
      }
    }

    .m-header-actions li:last-child.-isLanguageSwitch {
      display: none;
    }
  }
}


