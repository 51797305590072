.o-header-top__sidebar-trigger {
  display: block;
  @media (min-width: $zafaBeginDesktopBreakpoint) {
    display: none;
  }

  .m-headerSidebarTrigger {
    height: 100%;
    padding: rem(8) rem(8) rem(8) 0;

    @media (min-width: $screen-sm-min) {
      padding: rem(14) rem(8) rem(14) 0;
    }

    button {
      background: none;
      border: 0;
      color: $color-iron;
      display: flex;
      padding: 0;

      svg {
        height: rem(32);
        width: rem(32);
      }
    }

    &__label {
      padding: rem(4) 0 0 rem(8);
    }

    @media (max-width: $screen-s-max) {
      &__label {
        display: none;
      }
    }
  }
}
