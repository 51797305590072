.plf-accordion {
  .plf-accordion-head {
    height: $accordionHeadHeightSmall;
    padding: $accordionHeadPaddingSmall;

    .plf-accordion-title {
      font-size: $fontSizeAccordionTitleSmall;
    }

    .plf-accordion-indicator {
      .plf-accordion-circle {
        height: $accordionCircleDiameterSmall;
        width: $accordionCircleDiameterSmall;

        * {
          font-size: $fontSizeAccordionIconSmall;
          position: relative;
          top: rem(2);
        }
      }
    }

    &:hover {
      .plf-accordion-indicator {
        .plf-accordion-circle {
          * {
            font-size: $fontSizeAccordionIconSmall;
          }
        }
      }
    }
  }

  .plf-accordion-body {
    .plf-accordion-content-wrapper {
      padding: $accordionBodyPaddingSmall;
    }
  }
}
