.a-arrows-cta {
  cursor: pointer;
  display: inline-block;
  padding-right: $arrow-visible-side-padding;
  position: relative;
  text-decoration: none !important;
  text-overflow: ellipsis;
  transition: color $arrow-animation-duration $arrow-animation-easing,
    padding-left $arrow-animation-duration $arrow-animation-easing,
    padding-right $arrow-animation-duration $arrow-animation-easing;
  white-space: nowrap;

  .a-icon {
    color: $color-red;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity $arrow-animation-duration $arrow-animation-easing;

    &:last-child {
      margin-left: em(6, $font-size-regular-px);
      opacity: 1;
    }

    &:first-child {
      margin-left: em(30, $font-size-regular-px) * -1;
      opacity: 0;
    }
  }

  .no-touchdevice & {
    @include hover {
      @include arrows-animation;
    }
  }

  &:focus {
    @include arrows-animation;
  }
}
