.o-login {
  text-align: center;

  @include media-breakpoint-up(mobile) {
    .a-tab-panel {
      margin: 0 auto;
      max-width: 21.33333em;
    }
  }
}
