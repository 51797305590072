.a-circleAvatar {
  background: $color-cloud;
  border-radius: 100%;
  contain: none;
  display: inline-block;
  float: left;
  font-size: inherit;
  height: rem(30);
  opacity: 0.9999;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: rem(30);
  will-change: opacity,transform;

  &__initials {
    color: $color-anthracite;
    display: block;
    font-size: $font-size-header-base;
    font-weight: $font-bold;
    line-height: rem(30);
  }
}
