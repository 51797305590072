.plf-zafa-row[data-structure='heading1'] + .plf-meta-row,
.plf-zafa-row.odd + .plf-meta-row {
  background-color: $colorGrayLighter;

  .plf-meta-tab-wrapper {
    .plf-meta-tab-trigger-wrapper {
      .plf-meta-tab-trigger {
        background-color: $colorWhite;
        border-left: rem(3) solid $colorGrayLighter;
        border-right: rem(3) solid $colorGrayLighter;

        &[data-internal='true'] {
          position: relative;

          &::before {
            color: $colorRedDark;
            content: '\39';
            font-family: 'sbbicons';
            font-size: rem(22);
            position: absolute;
            right: rem(10);
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &[data-selected='true'],
        &:hover {
          background-color: $colorGrayLight;
        }
      }
    }
  }
}


.plf-meta-tab-trigger {
  &[data-internal='true'] {
    position: relative;

    &::before {
      color: $colorRedDark;
      content: '\39';
      font-family: 'sbbicons';
      font-size: rem(22);
      position: absolute;
      right: rem(10);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.plf-zafa-row[data-structure='heading1'] + .plf-meta-row {
  border: 0;

  td {
    border: 0;
  }
}

.plf-meta-row {
  border-left: $tableRowOffset solid $colorWhite;

  td {
    border-right: $tableRowOffset solid $colorWhite;

    &:first-of-type {
      padding-left: $tableLeftPadding;
    }

    &:last-of-type {
      padding-right: $tableRightPadding;
    }
  }

  &[data-level='2'] {
    td {
      &:first-of-type {
        padding-left: $tableLeftPadding + $tableIndent;
      }
    }
  }

  &[data-level='3'] {
    td {
      &:first-of-type {
        padding-left: $tableLeftPadding + 2 * $tableIndent;
      }
    }
  }

  &[data-level='4'] {
    td {
      &:first-of-type {
        padding-left: $tableLeftPadding + 3 * $tableIndent;
      }
    }
  }

  &[data-active='false'] {
    display: none;
  }

  // Tabs
  .plf-meta-tab-wrapper {
    padding-bottom: rem(20);
    position: relative;

    .plf-meta-tab-trigger-wrapper {
      font-size: 0;
      margin: 0  rem(-3);
      padding-left: 30%;
      text-align: center;
      width: auto;

      .plf-meta-tab-trigger {

        @include dynamicCountWidth(button, 9);
        background-color: $colorGrayLighter;
        border: 0;
        border-left: rem(3) solid $colorWhite;
        border-right: rem(3) solid $colorWhite;
        display: inline-block;
        font-size: rem(13);
        height: rem(30);
        position: relative;
        transition: 0.1s linear 0.1s;
        vertical-align: middle;

        &[data-selected='true'],
        &:hover {
          background-color: $colorGrayLight;
        }
      }
    }

    .plf-meta-tab {
      background-color: $colorWhite;
      border: rem(2) solid $colorGrayLight;
      padding: rem(20);

      hr {
        background-color: $colorGrayMedium;
        border: 0;
        height: rem(1);
        //padding: 0;
      }

      &[data-selected='false'] {
        display: none;
      }
    }
  }

  .plf-file-list {
    @include resetSpacing();
    list-style: none;

    li {
      a {
        display: table;
        text-decoration: none;
        width: 100%;

        * {
          clear: both;
          display: table-cell;
          float: none;
          height: 100%;
          vertical-align: middle;
        }

        i {
          font-size: $fontSizeTriggerIcon;
          position: relative;
          top: rem(2);
          width: $fontSizeTriggerIcon + rem(5);
        }

        &:hover {
          * {
            color: $colorRedDark;
          }
        }
      }
    }
  }
}


@media (max-width: $screenMdMax) {
  @import 'mobile/meta-row';
}
