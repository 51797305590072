.plf-accordion-wid {
  margin-bottom: rem(2);
  max-width: 75%;

  .plf-accordion-wid-trigger {
    align-items: center;
    background-color: $colorGrayLighter;
    border: 0;
    display: flex;
    flex-direction: row;
    font-size: rem(18);
    font-weight: 300;
    height: 100%;
    justify-content: space-between;
    padding: $accordionHeadPadding;
    position: relative;
    text-decoration: none;
    width: 100%;

    &:hover {
      background-color: $colorGrayLighter;
      color: $colorBlack;
      text-decoration: none;
    }

    &[aria-expanded='true'] {
      background-color: $colorWhite;
      border: rem(1) solid $colorGrayLight;
      border-bottom: 0;

      &::before {
        background-color: $colorGrayLight;
        bottom: rem(10);
        content: '';
        height: 1px;
        left: rem(25);
        position: absolute;
        right: rem(25);
        width: calc(100% - #{rem(50)});
      }

      .icon {
        &::before {
          content: '\28';
        }
      }
    }

    .icon {

      float: right;

      &::before {
        border: rem(1) solid $colorGrayDark;
        border-radius: 60%;
        content: '\29';
        font-size: $fontSizeAccordionTitle;
        padding: rem(4) rem(5);
      }
    }

    @media (min-width: $screenSmlMin) {
      font-size: $fontSizeAccordionTitle;
    }
  }

  .plf-accordion-wid-top {
    background-color: $colorGrayLighter;
    font-weight: 300;
  }

  .card-body {
    padding-bottom: rem(15);
    padding-top: rem(20);
  }

  .plf-accordion-wid-body {
    border: rem(1) solid $colorGrayLight;
    border-top: 0;
    overflow: hidden;
    padding: 0 rem(25) 0 rem(25);

    &.collapse {
      display: none;

      &.in {
        display: block;
      }
    }
  }

  /* Accordions nested inside another accordion */
  .sv-widget-accordion {
    &:first-of-type {
      margin-top: rem(20);
    }
  }

  .plf-accordion-wid {
    max-width: 100%;

    .plf-accordion-wid-trigger {
      &[aria-expanded='true'] {
        &::before {
          width: calc(100% - #{rem(50)});
        }
      }
    }
  }
}

@media screen and (max-width: $screenMdMax) {
  .plf-accordion-wid {
    max-width: 100%;

    .plf-accordion-wid-trigger {
      &[aria-expanded='true'] {
        &::before {
          width: calc(100% - #{rem(50)});
        }
      }
    }
  }
}

.sv-widget-accordion-arrowed {
  border: rem(1) solid $colorGrayLight;
  position: relative;

  &:not(:last-child) {
    border-bottom-style: none;
  }

  .plf-accordion-wid-arrowed {
    background-color: $colorWhite;

    margin-bottom: 0;
    overflow-y: hidden; //Prevents p tags adding margin to the bottom

    &.plf-accordion-wid-arrowed-blue {
      background-color: $colorGrayLighter;
    }

    .plf-accordion-wid-trigger {
      align-items: center;
      background-color: inherit;
      color: inherit;
      display: flex;
      height: rem(43); //Bigger than 30 * sqrt(2)
      justify-content: space-between;

      &:hover {
        background-color: $colorGrayLighter;
      }
    }


    &.plf-accordion-wid-arrowed-show-arrow::after {
      background-color: inherit;
      border: rem(1) $colorGrayLight;
      border-bottom-style: solid;
      border-left-style: solid;
      bottom: rem(-15);
      content: '';
      display: block;
      height: rem(30);
      position: absolute;
      right: 5em;
      transform: rotate(-45deg);
      width: rem(30);
      z-index: 1;
    }

    .card-body {
      padding-left: rem(10);
      padding-right: rem(10);

      position: relative;
      z-index: 2;
    }
  }
}
