$headerHeight: rem(125);
$headerHeightSmall: $headerHeight / 2;
$sidebarShadowWidth: rem(8);
$footerHeight: rem(95);
$footerHeightSmall: rem(180);
$mnuDropdownWidth: rem(220);
$mnuSidebarItemHeight: rem(40);
$mnuSidebarDropdownItemHeight: rem(30);

$sliderArrowWidth: rem(50);
$sliderArrowHeight: rem(68);
$sliderCtrlWidth: rem(50);
$sliderCtrlHeight: rem(50);
$sliderCtrlWidthSmall: rem(40);
$sliderCtrlHeightSmall: rem(40);

$accordionHeadHeight: rem(100);
$accordionHeadHeightSmall: rem(70);
$accordionCircleDiameter: rem(32);
$accordionCircleDiameterSmall: rem(24);

$loginButtonHeight: rem(48);
$loginButtonHeightSmall: rem(35);
$loginButtonMaxWidth: rem(400);
$loginButtonWidth: rem(270);

$searchButtonHeight: rem(35);
$searchButtonWidth: rem(420);

$sidebarItemTitleHeight: rem(40);
$sidebarItemTitleHeightSmall: rem(35);

$socialTooltipWidth: rem(145);
$socialTooltipWidthSmall: rem(150);

$commentsButtonHeight: rem(35);
