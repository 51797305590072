.m-headerSearchForm {
  padding: rem(11) 0 rem(11) 0;
  position: relative;

  @media (max-width: $screen-sml-max) {
    position: initial;
  }

  &__toggle {
    cursor: pointer;
    padding: 0;

    @media (max-width: $screen-sm-header-break) {
      .a-meta-link__label {
        display: none;
      }
    }
  }

  &__overlay {
    background: $color-white;
    display: block;
    height: rem(48);
    left: rem(12);
    opacity: 1;
    overflow: hidden;
    position: absolute;
    right: rem(12);
    top: rem(12);
    transition-duration: 400ms;
    transition-property: width, opacity, left, right;
    transition-timing-function: ease;
    width: calc(100vw - #{rem(24)});
    z-index: 4;

    .a-text-input {
      border: rem(2) solid $color-graphite;
      border-radius: 0;
      outline: none;

      + button {
        border: rem(2) solid $color-graphite;
        border-left: 0;
      }

      &:focus {
        border: rem(2) solid $color-gray40;

        + button {
          border: rem(2) solid $color-gray40;
          border-left: 0;
        }
      }
    }

    &.-closed {
      border-color: transparent;
      left: rem(48);
      right: rem(96);
      width: 0;
    }

    @media (min-width: $screen-sm-min) {
      /* stylelint-disable meowtec/no-px */
      $distance-logo: 224px;
      $width-search-form: 312px;
      /* stylelint-enable meowtec/no-px */

      left: calc(100vw - #{rem($distance-logo + $width-search-form)});
      right: rem($distance-logo);
      width: auto;

      &.-closed {
        left: calc(100vw - #{rem($distance-logo + ($width-search-form / 2))});
        right: rem($distance-logo * 2);
        width: 0;
      }
    }

    @media (min-width: $screen-md-min) {
      left: rem(-150);
      right: rem(-236);
      top: 0;
      width: auto;

      &.-closed {
        left: 0;
        right: 0;
        width: 0;
      }
    }
  }

  &__searchButton {
    background-color: $color-red;
    cursor: pointer;
    height: rem(48);
    margin: 0;
    padding: rem(5);
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: rem(56);

    &.a-meta-link > .a-icon {
      margin: auto;
    }

    svg {
      color: $color-white;
    }

    &:hover {
      background-color: $color-red-125;
    }
  }
}

.m-headerSearchButton {
  padding: rem(10.5) 0 rem(11.5) 0;

  .a-meta-link__label {
    display: none;

    @media (min-width: $screen-sm-header-break-upper) {
      display: block;
    }
  }
}

.m-headerSearchForm,
.m-headerSearchButton {
  cursor: pointer;
  height: rem(48);
}
