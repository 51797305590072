.a-img {
  display: block;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;

  &__image {
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}
