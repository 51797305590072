.a-accordion-btn {
  @include font-h3(line-height(28, $font-size-h3-px));

  @include accordion-btn-icons(
    $font-size-h3-px,
    $font-size-h3-mobile-px,
    $color-anthracite
  );

  background: $color-milk;
  border: 0;
  cursor: pointer;
  display: block;
  padding: em(25, $font-size-h3-px) em(24 * 3, $font-size-h3-px)
    em(25, $font-size-h3-px) em(24, $font-size-h3-px);
  position: relative;
  text-align: left;
  transition: background 0.3s ease-out;
  user-select: none;
  width: 100%;

  @include media-breakpoint-up(mobile) {
    padding: em(36, $font-size-h3-mobile-px) em(88, $font-size-h3-mobile-px)
      em(36, $font-size-h3-mobile-px) em(32, $font-size-h3-mobile-px);
  }

  .a-icon {
    height: (16 / 24) * 100%;
    width: (16 / 24) * 100%;

    @include media-breakpoint-up(mobile) {
      height: (24 / 32) * 100%;
      width: (24 / 32) * 100%;
    }
  }

  .a-accordion-btn__icon-minus,
  .a-accordion-btn__icon-plus {
    align-items: center;
    border: em(1, $font-size-h3-px) solid $color-storm;
    border-radius: 50%;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include media-breakpoint-up(mobile) {
      border-width: em(1, $font-size-h3-mobile-px);
    }
  }

  &:focus {
    .a-accordion-btn__icon-minus,
    .a-accordion-btn__icon-plus {
      border-color: $color-red-125;

      .a-icon {
        color: $color-red-125;
      }
    }
  }

  &:not(&--active) {
    .a-accordion-btn__icon-minus {
      display: none;
    }
  }

  &--active {
    .a-accordion-btn__icon-plus {
      display: none;
    }
  }

  &--footer {
    @include accordion-btn-icons(16, 18, $border-width: true);

    background-color: $color-cloud;
    color: $color-anthracite;
    font-size: em(16, $footer-section-base-size);
    line-height: (19 / 16);
    padding: em(23, 16) em(24 * 3, 16) em(22, 16) em(24, 16);

    @include media-breakpoint-up(mobile) {
      font-size: em(18, $footer-section-base-size);
      line-height: (24 / 18);
      padding: em(28, 18) em((48 * 2) + 32, 18) em(28, 18) em(48, 18);
    }

    @include media-breakpoint-up(tablet) {
      display: none;
    }

    &:hover {
      background-color: $color-milk;
    }
  }
}
