.plf-zafa-table .plf-zafa-row .plf-zafa-cell .plf-tooltip-wrapper {
  position: relative;

  // do an ugly important... otherwise there is no fix for IE :-(
  // tooltip (three dots) for download, export, etc. data will shown
  // in IE correctly.
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .plf-tooltip {
      left: rem(-104) !important;
    }
  }

  .plf-tooltip {
    @extend .plf-tooltip;
  }
}

.plf-zafa-row[data-function-active='true'] {
  .plf-zafa-function .plf-tooltip-wrapper.zafa-button i.icon-040-drei-punkte-menu-106,
  .plf-zafa-function button i.icon-040-drei-punkte-menu-106 {
    color: $color-red-125;
  }
}
