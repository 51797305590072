$quiz-question-icon: 48;

.o-quiz-question {
  background-color: $color-milk;
  margin-top: em(25, $font-size-regular-px);
  min-height: em($quiz-question-icon, $font-size-h3-px);
  padding: em(8, $font-size-regular-px);

  @include media-breakpoint-up(mobile) {
    min-height: em($quiz-question-icon, $font-size-h3-mobile-px);
  }

  &--correct {
    .a-icon {
      color: $color-green;
    }
  }

  &--incorrect {
    .a-icon {
      color: $color-red;
    }
  }

  &__legend {
    @include font-h3;
    display: block;
    position: relative;

    .a-icon {
      height: em($quiz-question-icon, $font-size-h3-px);
      position: absolute;
      right: 0;
      top: -#{em(8, $font-size-h3-px)};
      width: em($quiz-question-icon, $font-size-h3-px);

      @include media-breakpoint-up(mobile) {
        height: em($quiz-question-icon, $font-size-h3-mobile-px);
        top: -#{em(8, $font-size-h3-mobile-px)};
        width: em($quiz-question-icon, $font-size-h3-mobile-px);
      }
    }
  }

  &__answers {
    @include no-bullets;

    li {
      margin-top: em(8, $font-size-regular-px);
    }
  }
}
