.m-typeahead-overlay {
  background-color: $color-white;
  border-bottom: rem(2) solid $color-storm;
  border-left: rem(2) solid $color-storm;
  border-right: rem(2) solid $color-storm;
  box-shadow: 0 rem(8) 0 0 rgba($color-black, 0.15), 0 -#{rem(3)} 0 -#{rem(2)} $color-cloud;
  padding: rem(12);

  &__list {
    padding: 0;
  }

  &__listItem {
    display: block;
    text-align: left;
  }

  &__query {
    color: $color-black;
  }

  &__completion {
    display: inline;
    margin: 0;
  }

  &__suggestionLabel {
    color: $color-metal;
    cursor: auto;
    font-size: rem(14);
    margin: 0 0 rem(8);
    text-align: left;
  }

  &__divider {
    border-bottom: 1px solid $color-storm;
    margin: rem(8) -#{rem(12)};
  }
}
