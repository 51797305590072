$transition-duration: 300ms;

@mixin left-position ($mobile-menu-toggle-with, $indicator-position-value-per-element) {
  &.-leftPosition {
    &_1 {
      $left: $indicator-position-value-per-element + $mobile-menu-toggle-with;
      left: $left;
      right: calc(100vw - #{rem($left)} - #{rem($indicator-position-value-per-element)});
    }

    &_2 {
      $left: (2 * $indicator-position-value-per-element) + $mobile-menu-toggle-with;
      left: $left;
      right: calc(100vw - #{rem($left)} - #{rem($indicator-position-value-per-element)});
    }

    &_3 {
      $left: (3 * $indicator-position-value-per-element) + $mobile-menu-toggle-with;
      left: $left;
      right: calc(100vw - #{rem($left)} - #{rem($indicator-position-value-per-element)});
    }
  }
}

@mixin mobile-left-position() {
  $mobile-menu-toggle-with: rem(48);

  @media (max-width: $screen-xs-header-break) {
    @include left-position($mobile-menu-toggle-with, rem(30));
  }

  @media (min-width: $screen-xs-header-break-after) {
    @include left-position($mobile-menu-toggle-with, rem(48));
  }
}

.m-usermenuAvatar {
  height: rem(48);
  margin-left: rem(-10);

  @media (min-width: $screen-sm-min) {
    margin-left: rem(-18);
    position: relative;
    width: rem(77);
  }

  &__anonymous {
    background: transparent;
    border: 0;
    color: $color-metal;
    display: flex;
    font-size: $font-size-header-base;
    text-decoration: none;

    > .a-icon {
      margin-right: em(4, 14);
    }

    span {
      display: flex;
    }

    &:hover {
      color: $color-red;
    }
  }

  .m-usermenuDropdown {
    &:not(.-closed) {
      max-height: rem(250);
    }
  }

  .m-usermenuDropdown__dropdownItem {
    height: auto;
    padding: rem(5) 0 0 0;

    &:first-child {
      padding-top: rem(16);
    }

    &:last-child {
      padding-bottom: rem(16);
    }
  }
}

.m-usermenuDropdown {
  background-color: $color-white;
  border: rem(2) solid $color-gray40;
  box-shadow: 0 0.57143em 0 rgba(0, 0, 0, 0.15);
  display: block;
  list-style: none;
  margin: 0;
  max-height: rem(190);
  overflow: hidden;
  padding: rem(46) 0 0;
  z-index: 2;

  &.-closed {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    max-height: 0;
    width: 0;
  }

  &__dropdownItem {
    background-color: $color-white;
    font-size: $font-size-header-base;
    height: rem(30);
    overflow: hidden;
    padding: rem(8) 0 rem(10) 0;
    width: 100%;

    &.-sectionDivider {
      border-top: rem(1) solid $color-cloud;
    }

    a {
      color: $color-gray40;
      display: flex;
      padding: 0 rem(15) 0 rem(15);
      text-decoration: none;
    }
  }

  // Min -> 642px
  @media (max-width: $screen-s-max) {
    left: rem(12);
    position: fixed;
    right: rem(12);
    top: rem(6);
    transition:
      left 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      right 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      width 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      max-height 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      box-shadow 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      border-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      background-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      opacity 0ms linear 0.3s,
      z-index 0ms linear 0.3s;
    width: calc(100vw - #{rem(24)});

    &.-closed {
      @include mobile-left-position();
    }
  }

  // 643px -> Max
  @media (min-width: $screen-sm-min) {
    position: absolute;
    right: rem(-16);
    top: 0;
    transition:
      width 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      max-height 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      box-shadow 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      border-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      background-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      opacity 0ms linear 0.3s,
      z-index 0ms linear 0.3s;
    width: rem(290);
  }
}

.m-usermenuDropdownToggle {
  background-color: $color-white;
  border: 0;
  cursor: pointer;
  display: block;
  height: rem(46);
  overflow: hidden;
  padding: rem(10.5) rem(8.5);
  position: fixed;
  width: rem(80);
  z-index: 3;

  &__username {
    color: $color-gray40;
    float: left;
    opacity: 0;
    padding: rem(2) 0 0 rem(10);
    transition: opacity $transition-duration cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s;

    &[aria-expanded='true'] {
      display: inline;
      opacity: 1;
      overflow: hidden;
    }
  }

  &__toggleIndicator {
    color: $color-gray40;
    position: absolute;
    top: rem(8);
    transition: left $transition-duration cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s;

    svg {
      height: rem(28);
      transform: none;
      transition: transform 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
      width: rem(28);
    }

    &[aria-expanded='true'] {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  &:hover &__toggleIndicator {
    color: $color-red;
  }

  // Min -> 642px
  @media (max-width: $screen-s-max) {
    padding: rem(9) 0 rem(9) rem(8);
    top: 0;
    transition:
      top 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      right 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      left 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
      width 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s;

    &[aria-expanded='false'] {
      @include mobile-left-position();
    }

    &[aria-expanded='true'] {
      left: rem(22);
      right: rem(15);
      top: rem(8);
      width: calc(100vw - #{rem(37)});
    }

    &__toggleIndicator {
      display: block;
      right: rem(5);
      transition:
        right $transition-duration cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
        display $transition-duration cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s;

      &[aria-expanded='false'] {
        display: none;
        right: 0;
      }
    }
  }

  // 643px -> Max
  @media (min-width: $screen-sm-min) {
    padding: rem(7) 0 rem(11) rem(16);
    position: absolute;
    right: rem(-14);
    top: rem(2);
    transition: width $transition-duration cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s;

    &[aria-expanded='true'] {
      width: rem(286);
    }

    &__toggleIndicator {
      left: rem(46);

      &[aria-expanded='true'] {
        left: rem(253);
      }
    }
  }
}
