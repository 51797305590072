.m-accordion {
  .m-accordion__layer {
    li {
      background-color: $color-milk;
      list-style: none;

      ul {
        display: none;

        .mm-show {
          display: block;
        }
      }

      &.mm-active > ul {
        display: block;
      }
    }
  }
}
