//------------------------------------------------------------------------
// Accordion style
//------------------------------------------------------------------------
.plf-accordion {
  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:checked {
    ~ .plf-accordion-body {
      border: rem(1) solid $colorGrayLight;
      border-top: 0;
      display: block;
    }

    ~ .plf-accordion-head {
      background-color: transparent;
      border: rem(1) solid $colorGrayLight;
      border-bottom: 0;
      margin-bottom: 0;

      > span {
        border-bottom: rem(1) solid $colorGrayLight;

        .plf-accordion-circle {
          &::before {
            content: '\28';
          }
        }
      }
    }
  }

  label {
    margin-bottom: 0;
    width: 100%;
  }

  .plf-accordion-head {
    background-color: $colorGrayLighter;
    display: table;
    height: $accordionHeadHeight;
    margin: 0 0 rem(10) 0;
    padding: $accordionHeadPadding;
    width: 100%;

    > * {
      display: table-cell;
      line-height: 1.4;
      vertical-align: middle;
    }

    .plf-accordion-title {
      font-family: $sbb;
      font-size: $fontSizeAccordionTitle;
      font-weight: normal;
    }

    .plf-accordion-indicator {
      .plf-accordion-circle {
        border: rem(1) solid $colorGray;
        border-radius: 50%;
        display: block;
        float: right;
        height: $accordionCircleDiameter;

        text-align: center;
        width: $accordionCircleDiameter;

        * {
          color: $colorGrayDark;
          font-size: $fontSizeAccordionIcon;
          position: relative;
          top: rem(2);
        }

        &::before {
          content: '\29';
        }
      }
    }

    &:hover {
      background-color: $colorGrayLight;
      cursor: pointer;

      .plf-accordion-indicator {
        .plf-accordion-circle {
          border-color: $colorRedDark;

          * {
            color: $colorRedDark;
            font-size: $fontSizeAccordionIcon;
          }
        }
      }
    }
  }

  .plf-accordion-body {
    display: none;

    .plf-accordion-content-wrapper {
      padding: $accordionBodyPadding;
    }
  }
}

.plf-help-overlay-content {
  .plf-accordion-body {
    .plf-accordion-content-wrapper {
      padding: 0 rem(25);

      p {
        margin-bottom: rem(15);
      }
    }
  }
}

.accordion {
  $iconSize: rem(32);
  $iconFontSize: rem(24);
  $accordionPadding: rem(30);

  .accordion-trigger-label {
    display: block;
    margin: 0;
  }

  .accordion-title {
    background-color: $colorGrayLighter;
    padding: $accordionPadding;
    width: 100%;

    .accordion-title-inner {
      width: calc(100% - #{$iconSize});
    }

    .accordion-icon {
      border: rem(1) solid $colorGrayDark;
      border-radius: 100%;
      display: inline-block;
      float: right;
      height: $iconSize;
      text-align: center;
      width: $iconSize;

      &,
      i {
        //line-height: $iconSize;
        line-height: rem(24);
      }
    }

    h2 {
      line-height: $iconSize;
      margin: 0;
    }
  }

  .accordion-container {
    margin: rem(3) 0;
  }

  .accordion-icon {
    height: 100%;
    position: relative;
    width: $iconSize;

    &::after {
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    img {
      height: $iconSize;
      width: $iconSize;

      &.opened {
        display: none;
      }
    }
  }

  .accordion-trigger:checked {
    ~ .accordion-trigger-label .accordion-title {
      background: none;
      border: rem(1) solid $colorGrayLight;
      border-bottom: 0;
      padding: $accordionPadding - rem(1); //- border size
      position: relative;

      &::before {
        background-color: $colorGrayLight;
        bottom: 0;
        content: '';
        display: block;
        height: rem(1);
        left: $accordionPadding;
        position: absolute;
        right: $accordionPadding;
        width: calc(100% - (#{$accordionPadding * 2}));
      }

      .accordion-icon img {
        &.opened {
          display: inline;
        }

        &.closed {
          display: none;
        }
      }
    }

    ~ .accordion-content {
      border: rem(1) solid $colorGrayLight;
      border-top: 0;
    }
  }

  .accordion-content {
    display: none;
    padding: $accordionPadding;
  }

  .accordion-trigger {
    display: none;

    &:checked {
      ~ .accordion-content {
        display: block;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  @import 'mobile/accordion';
}
