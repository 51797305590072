.m-languageSwitch {
  display: flex;
  height: rem(48);
  position: relative;

  ul.m-headerActionsDropdown {
    @media (max-width: $screen-sml-max) {
      display: none;
    }
    right: rem(-2);
    top: rem(0);
    width: rem(132);
    z-index: 1;

    &:not(.-closed) {
      z-index: 3;
    }

    li:first-child {
      border-top: 1px solid $color-cloud;
    }
  }

  button.m-headerActionsDropdownToggle {
    @media (max-width: $screen-sml-max) {
      display: none;
    }
    z-index: 2;

    &[aria-expanded='true'] {
      z-index: 4;
    }
  }

  .m-languageSwitch-sidebar {
    align-items: center;
    color: $color-metal;
    display: flex;

    @media (min-width: $screen-md-min) {
      display: none;
    }

    select {
      border: 0;
      outline: none;
    }
  }
}
