$transition-base-duration: 300ms;

.m-headerActionsDropdown {
  background-color: $color-white;
  border: rem(2) solid $color-gray40;
  box-shadow: 0 rem(8) 0 rgba($color-black, 0.15), 0 rem(-3) 0 rem(-2) $color-cloud;
  display: block;
  list-style: none;
  margin: 0;
  max-height: rem(190);
  overflow: hidden;
  padding: rem(46) 0 0;
  position: absolute;
  right: rem(-16);
  transition:
    width 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
    max-height 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
    box-shadow 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
    border-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s,
    opacity 0ms linear 0.3s,
    z-index 0ms linear 0.3s;
  width: rem(290);
  z-index: 2;

  &.-closed {
    border-color: transparent;
    box-shadow: none;
    max-height: 0;
    width: 0;
  }

  .m-headerActionsDropdownEntry {
    background-color: $color-white;
    font-size: $font-size-header-base;
    height: rem(30);
    overflow: hidden;
    padding: rem(8) 0 rem(10) 0;
    width: 100%;

    &:last-child {
      margin-bottom: rem(16);
    }

    a,
    button {
      color: $color-gray40;
      display: flex;
      padding: 0 rem(15) 0 rem(15);
      text-decoration: none;
    }

    button {
      background: 0;
      border: 0;
      cursor: pointer;
    }
  }
}
