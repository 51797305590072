.m-context-menu {
  background: $color-white;
  border: rem(2) solid $color-gray40;
  box-shadow: 0 rem(8) 0 rgba($color-black, 0.15), 0 rem(-3) 0 rem(-2) $color-cloud;
  box-sizing: border-box;
  min-width: rem(180);
  position: fixed;
  user-select: none;

  &__option {
    color: $color-gray40;
    cursor: pointer;
    display: block;
    outline: 0;
    overflow: hidden;
    padding: rem(8) rem(20);
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &:hover {
      color: $color-red;
    }
  }

  &__separator {
    background-color: $color-aluminum;
    height: 1px;
    width: 100%;
  }
}
