.tippy-content {
  background: $color-white;
  border: rem(2, 16) solid $color-gray40;
  border-radius: rem(2);
  box-shadow: 0 0.53333em 0 0 rgba(102, 102, 102, 0.15);
  color: $color-gray40;
  display: block;
  font-size: $font-size-regular-px;
  height: 100%;
  letter-spacing: 0;
  margin-top: 0.8em;
  max-width: rem(460);
  position: relative;
  text-align: left;
  width: 100%;

  .m-tooltip-content {
    padding: rem(24) rem(72) rem(24) rem(24);
  }
}

.tippy-arrow {
  color: $color-white;
  height: rem(26);
  width: rem(26);

  &::before,
  &::after {
    border-color: transparent;
    border-style: solid;
    content: '';
    position: absolute;
  }
}

.tippy-box[data-placement^='top'] {
  > .tippy-arrow {
    bottom: 0;

    &::before {
      border-bottom-width: 0;
      border-left-width: rem(13);
      border-right-width: rem(13);
      border-top-color: $color-gray40;
      border-top-width: rem(13);
      bottom: rem(-11);
      left: 0;
    }

    &::after {
      border-top-color: $color-white;
      border-width: rem(10) rem(10) 0;
      bottom: rem(-8);
      left: rem(3);
    }
  }
}

.tippy-box[data-placement^='bottom'] {
  > .tippy-arrow {
    top: 0;

    &::before {
      border-bottom-color: $color-gray40;
      border-bottom-width: rem(13);
      border-left-width: rem(13);
      border-right-width: rem(13);
      border-top-width: 0;
      left: 0;
      top: 1px;
      transform-origin: center top;
    }

    &::after {
      border-bottom-color: $color-white;
      border-bottom-width: rem(10);
      border-left-width: rem(10);
      border-right-width: rem(10);
      border-top-width: 0;
      left: rem(3);
      top: rem(4);
    }
  }
}

.a-btn {
  &--close {
    float: right;
    position: absolute;
    right: rem(24);
    top: rem(24);
  }
}
