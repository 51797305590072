.plf-diagram-wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;

  &.-hidden {
    display: none;
  }

  .plf-diagram {
    height: 100%;
    width: 100%;

    .plf-diagram-container {
      margin: 0;
    }
  }
}

.plf-dashboard-tile-flip-wrapper {
  .flip-tile {
    background-color: transparent;
    perspective: rem(1000);
  }

  .flip-tile-inner {
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }

  .flip-tile.hover .flip-tile-inner {
    transform: rotateY(180deg);
  }

  .flip-tile-front,
  .flip-tile-back {
    backface-visibility: hidden;
    position: absolute;
  }

  .flip-tile-inner .flip-tile-back {
    overflow: visible;
    transform: rotateY(180deg);

    .flip-tile-backdrop {
      background-color: rgba(255, 255, 255, 0.92);
      height: 100%;
      position: absolute;
      width: 100%;
    }

    .flip-tile-background {
      border-radius: rem(8);
      overflow: hidden;
      position: absolute;
      transform: rotateY(180deg);

      img {
        transform: scale(1.07);
      }
    }

    .plf-diagram-tile {
      background-color: transparent;
      border: rem(1) solid $colorGrayLight;
      border-radius: rem(8);
      padding: 1rem 0.5rem;
      text-align: center;

      .plf-diagram-container {
        margin: 0 auto;
      }

      .plf-dashboard-tile-unit {
        display: inline-block;
        font-size: $fontSizeDashboardUnit + rem(4);
        margin-bottom: 1rem;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      .plf-diagram-tile-link {
        padding: 1rem;
      }
    }
  }

  @media screen and (max-width: $screenSmlMax) {
    .flip-tile-front {
      width: 100%;
    }

    .flip-tile-inner .flip-tile-back {
      width: 100%;

      .plf-diagram-tile {
        padding: 0.5rem;

        .plf-dashboard-tile-unit {
          margin-bottom: 0.5rem;
        }
      }
    }

    .plf-dashboard-tile-wrapper {
      width: 100%;
    }
  }
}
