.plf-login {
  .plf-login-title {
    font-size: $fontSizeLoginTitleSmall;
  }

  .plf-login-content {
    a,
    input {
      max-width: none;
    }

    a {
      margin-top: rem(15);
    }

    a,
    button {
      height: $loginButtonHeightSmall;
      line-height: $loginButtonHeightSmall;

    }

    span {
      font-size: $fontSizeLoginTextSmall;
    }

    div:not(.plf-login-external) h3 {
      margin-top: rem(35);
    }

    i {
      font-size: rem(20);
      position: relative;
      top: rem(3);
    }

    .plf-login-form {
      padding-top: rem(35);

      label {
        font-size: $fontSizeLoginTextSmall;
      }

      input {
        height: $loginButtonHeightSmall;
      }

      button {
        width: 100%;
      }

      h3,
      > div:first-of-type {
        margin-bottom: rem(15);
      }
    }
  }
}
