.plf-dashboard-wrapper {
  background-color: $color-white;

  &[data-highlighted]::before {
    border: rem(1) solid $colorRed;
    bottom: rem(-1);
    content: '';
    display: block;
    left: rem(-9);
    pointer-events: none;
    position: absolute;
    right: rem(-9);
    top: rem(-1);
  }

  >.plf-tooltip-wrapper {
    position: absolute;
    right: rem(-37);
    top: 0;
  }

  .plf-tooltip-wrapper {
    .icon-040-drei-punkte-menu-106 {
      font-size: rem(40);

      &::before {
        height: rem(35);
      }
    }

    .plf-tooltip {
      left: rem(8);
    }
  }

  // Horzintal tile spacing
  .plf-dashboard-tile-wrapper {
    @media (max-width: $screenXsMax) {
      padding-bottom: $gridGutterXSmall / 4;
      padding-top: $gridGutterXSmall / 4;
    }

    @media (min-width: $screenSmMin) and (max-width: $screenSmMax) {
      padding-bottom: $gridGutterSmall / 4;
      padding-top: $gridGutterSmall / 4;
    }

    @media (min-width: $screenSmlMin) and (max-width: $screenSmlMax) {
      padding-bottom: $gridGutterSmallLandscape / 2;
      padding-top: $gridGutterSmallLandscape / 2;
    }

    @media (min-width: $screenMdMin) and (max-width: $screenMdMax) {
      padding-bottom: $gridGutterMedium / 2;
      padding-top: $gridGutterMedium / 2;
    }

    @media (min-width: $screenNormalMin) and (max-width: $screenNormalMax) {
      padding-bottom: $gridGutterNormal / 2;
      padding-top: $gridGutterNormal / 2;
    }

    @media (min-width: $screenLgMin) and (max-width: $screenLgMax) {
      padding-bottom: $gridGutterLarge / 2;
      padding-top: $gridGutterLarge / 2;
    }

    @media (min-width: $screenXlgMin) and (max-width: $screenXlgMax) {
      padding-bottom: $gridGutterXLarge / 2;
      padding-top: $gridGutterXLarge / 2;
    }

    @media (min-width: $screenXxlMin) {
      padding-bottom: $gridGutterXXLarge / 2;
      padding-top: $gridGutterXXLarge / 2;
    }
  }

  .plf-dashboard-title {
    padding: $dashboardTitleSpacingTop 0 $dashboardTitleSpacingBottom;
    text-align: center;

    h1 {
      margin: 0;
    }
  }

  .plf-dashboard-tiles-wrapper {
    position: relative;

    .plf-dashboard-tile-wrapper {
      background-color: $color-white;

      .plf-tooltip-wrapper {
        bottom: rem(36);
        color: $color-black;
        position: absolute;
        right: rem(8);
      }

      @import '../presets';

      .plf-dashboard-tile {
        border-radius: rem(8);
        overflow: hidden;
        position: relative;

        &.-hidden {
          display: none;
        }

        &:hover img {
          transform: scale(1.07);
        }

        &[data-highlighted]::before {
          border: rem(1) solid $colorRed;
          bottom: rem(-8);
          content: '';
          display: block;
          left: rem(-8);
          pointer-events: none;
          position: absolute;
          right: rem(-8);
          top: rem(-8);
        }

        picture {
          img {
            transition: transform 0.3s ease-out;
            width: 100%;
          }
        }

        .plf-dashboard-tile-meta-wrapper {
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 50%;

          .plf-dashboard-tile-meta {
            bottom: $dashboardTileSpacingV;
            left: $dashboardTileSpacingH;
            position: absolute;
            right: 2.25rem;

            span {
              display: block;
              line-height: 1.2;
            }

            .plf-dashboard-tile-number {
              font-size: $fontSizeDashboardNumber;
            }

            .plf-dashboard-tile-unit {
              font-size: $fontSizeDashboardUnit;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  @import 'mobile/dashboard';
}
