.plf-comments-wrapper {
  padding-top: $commentsTopSpacingSmall;

  label,
  .plf-comment-submit {
    margin-top: $commentsItemSpacingSmall;

    &,
    * {
      font-size: $fontSizeCommentsMetaSmall;
    }
  }

  .plf-comments-title {
    font-size: $fontSizeCommentsTitleSmall;
    padding-bottom: $commentsTitleSpacingSmall;
  }

  .plf-comments-form-wrapper {
    form {
      &:not([data-error='']) {
        &::before {
          font-size: $fontSizeCommentsTextSmall;
          margin-bottom: rem(10);
        }
      }
    }

    .plf-comment-input {
      font-size: $fontSizeCommentsTextSmall;
    }
  }

  .plf-comment-list-wrapper {
    padding-top: $commentsItemSpacingSmall;

    .plf-comment-list {
      .plf-comment {
        margin-bottom: $commentsItemSpacingSmall;
        padding: $commentsItemPaddingSmall;

        .plf-comment-info {
          font-size: $fontSizeCommentsMetaSmall;
          padding-bottom: $commentsItemTitleSpacingSmall;
        }

        .plf-comment-text {
          font-size: $fontSizeCommentsTextSmall;
        }
      }

      &.plf-all-comments {
        .plf-comment {
          &:first-of-type {
            margin-top: $commentsItemSpacingSmall;
          }
        }
      }
    }
  }
}
