@import 'sbbicons';

$font-primary: 'SBB';
$font-secondary: Helvetica, Arial, sans-serif;
$icons: 'sbbicons';

$font-thin: 200;
$font-light: 300;
$font-normal: 400;
$font-bold: 700;

/* stylelint-disable */
:export {
  fontPrimary: $font-primary;
}
/* stylelint-enable */
