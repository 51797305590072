@mixin arrows-animation($padding-right-hover: 0) {
  color: $color-red-125;
  padding-left: $arrow-visible-side-padding;
  padding-right: $padding-right-hover;

  > .a-icon {
    color: currentColor;
    opacity: 0;

    &:first-child {
      opacity: 1;
    }
  }
}
