$fontPath: '../../../../fonts/social-icons/';

@font-face {
  font-family: 'social-icons';
  font-style: normal;
  font-weight: normal;
  /* stylelint-disable function-url-quotes */
  src: url($fontPath + 'social-icons.eot?emf2t7');
  src:  url($fontPath + 'social-icons.eot?emf2t7#iefix') format('embedded-opentype'),
    url($fontPath + 'social-icons.ttf?emf2t7') format('truetype'),
    url($fontPath + 'social-icons.woff?emf2t7') format('woff'),
    url($fontPath + 'social-icons.svg?emf2t7#social-icons') format('svg');
}

.social-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'social-icons' !important;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.social-facebook::before {
  content: '\e900';
}

.social-linkedin::before {
  content: '\e901';
}

.social-twitter::before {
  content: '\e902';
}

.social-whatsapp::before {
  content: '\e903';
}

.social-xing::before {
  content: '\e904';
}

