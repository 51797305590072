.plf-comments-wrapper {
  padding-top: $commentsTopSpacing;

  label,
  .plf-comment-submit {
    background-color: $colorRed;
    border: 0;
    display: block;
    height: $commentsButtonHeight;
    line-height: 1;
    margin-top: $commentsItemSpacing;
    text-align: center;
    vertical-align: middle;
    width: 100%;

    &,
    * {
      color: $colorWhite;
      font-family: $sbb;
      font-size: $fontSizeCommentsMeta;
      font-weight: 200;
      text-decoration: none;
    }

    &:hover {
      background-color: $colorRedDark;
      cursor: pointer;
    }
  }

  .plf-comments-title {
    display: block;
    font-family: $sbb;
    font-size: $fontSizeCommentsTitle;
    font-weight: 200;
    margin: 0;
    padding-bottom: $commentsTitleSpacing;
    width: 100%;
  }

  .plf-comments-form-wrapper {

    position: relative;

    form {
      height: 100%;

      &[data-disabled='true'] {
        > * {
          pointer-events: none;
        }

        button {
          background-color: $colorGray;
        }
      }

      &:not([data-error='']) {
        .plf-comment-input {
          border-color: $colorRedDark;
        }

        &::before {
          color: $colorRedDark;
          content: attr(data-error) ' ';
          display: block;
          font-size: $fontSizeCommentsText;
          margin-bottom: rem(20);
        }
      }
    }

    textarea,
    button {
      display: block;
      width: 100%;
    }

    .plf-comment-input {
      border: rem(2) solid $colorAlmostGray;
      border-radius: rem(3);
      font-size: $fontSizeCommentsText;
      padding: rem(10);

      &:focus {
        outline: 0;
      }
    }
  }

  .plf-comment-list-wrapper {
    input[type='checkbox'] {
      display: none;

      &:checked {
        ~ .plf-all-comments,
        ~ .plf-disable-all-comments {
          display: block;

        }

        ~ .plf-show-all-comments {
          display: none;
        }
      }
    }

    label {
      line-height: $commentsButtonHeight;
      margin-left: auto;

      &.plf-disable-all-comments {
        display: none;
      }
    }

    .plf-comment-list {
      @include resetSpacing();
      list-style: none;

      .plf-comment {
        background-color: $colorGrayLighter;
        margin-bottom: $commentsItemSpacing;
        padding: $commentsItemPadding;

        .plf-comment-info {
          font-size: $fontSizeCommentsMeta;
          font-weight: 700;
          padding-bottom: $commentsItemTitleSpacing;
        }

        .plf-comment-text {
          font-size: $fontSizeCommentsText;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &.plf-all-comments {
        display: none;

        .plf-comment {
          &:first-of-type {
            margin-top: $commentsItemSpacing;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  @import 'mobile/comments';
}
