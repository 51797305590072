.m-headerActionsDropdownToggle {
  background-color: $color-white;
  border: 0;
  color: $color-metal;
  cursor: pointer;
  display: flex;
  height: rem(46);
  overflow: hidden;
  padding: rem(8.5) 0 rem(11.5) 0;
  position: relative;
  top: rem(2);
  transition: z-index 0ms linear 0.3s;
  width: 100%;
  z-index: 2;

  > .a-icon {
    display: block;
    margin-top: rem(2);
  }

  &__label {
    display: block;
    margin-left: rem(3);
  }

  &__toggleIndicator {
    display: block;
    margin-left: rem(5);
    margin-top: rem(-1);

    svg {
      height: rem(28);
      transform: none;
      transition: transform 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
      width: rem(28);
    }

    &[aria-expanded='true'] {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  &:hover {
    .a-icon,
    .m-headerActionsDropdownToggle__label,
    .m-headerActionsDropdownToggle__toggleIndicator {
      color: $color-red-125;
    }
  }
}
