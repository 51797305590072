.o-form {
  &__list {
    @include no-bullets();
  }

  &__list-item + &__list-item {
    margin-top: em(24, 15);
  }

  &__footer {
    @include divider-medium;
  }

  &__notification + &__list {
    @include divider-medium;
  }
}
