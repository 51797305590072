.o-footer-sections {
  $grid-gap: em(48, 15);

  @include media-breakpoint-up(tablet) {
    display: -ms-grid;
    -ms-grid-columns: 1fr $grid-gap 1fr $grid-gap 1fr $grid-gap 1fr;
    -ms-grid-rows: 1fr;

    .m-footer-section {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        grid-row: 1;
      }

      &:nth-of-type(2) {
        -ms-grid-column: 3;
        grid-row: 1;
      }

      &:nth-of-type(3) {
        -ms-grid-column: 5;
        grid-row: 1;
      }

      &:nth-of-type(4) {
        -ms-grid-column: 7;
        grid-row: 1;
      }
    }

    @supports (display: grid) {
      display: grid;
      grid-gap: $grid-gap;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }
  }
}
