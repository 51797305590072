.plf-search-result-wrapper {
  .plf-search-title {
    padding-bottom: $searchSpacerSmall;

    * {
      color: $colorGrayAlmostDark;

      &.plf-search-keyword {
        color: $colorRed;
      }
    }
  }

  .plf-search-form {
    padding-bottom: $searchSpacerSmall;

    .ym-fbox-check {
      display: inline-block;
      margin-right: $searchSpacerBig;

      &:last-of-type {
        margin-right: 0;
      }

      > label {
        display: none;
      }

      .field {
        input {
          display: none;

          &:checked ~ label {
            &::before {
              background-color: $colorAlmostGray;
            }
          }
        }

        label {
          display: inline-block;
          line-height: rem(20);
          vertical-align: middle;

          &::before {
            border: rem(2) solid $colorWhite;
            content: ' ';
            display: inline-block;
            height: rem(14);
            margin: 1px rem(5) 1px 1px;
            outline: 1px solid $colorGrayDark;
            position: relative;
            top: (rem(20) - rem(16))/2;
            transition: 0.3s ease;
            width: rem(14);
          }
        }
      }
    }

    .sv-widget-search-input {
      padding: $searchSpacerSmall 0 0 rem(16);

      .plf-search-input {
        border: rem(2) solid $colorAlmostGray;
        border-radius: rem(3);
        height: $searchButtonHeight;
        padding: 0 rem(10);
        width: $searchButtonWidth;
      }

      .js-button-wrapper {
        button {
          padding: rem(4) rem(32) rem(3);
        }

        @media (min-width: $screenSmMax) {
          display: inline-block;

          button {
            margin-left: rem(16);
          }
        }
      }
    }
  }

  .plf-result-wrapper {
    .plf-search-entry-wrapper {
      border-bottom: 1px solid $colorGrayLight;
      padding-bottom: $searchSpacerSmall / 2;
      padding-top: $searchSpacerSmall / 2;

      &:last-of-type {
        padding-bottom: 0;
      }

      .plf-search-entry {
        h4 {
          i {
            position: relative;
            top: rem(2);
          }
        }

        a {
          text-decoration: none;

          &:hover {
            text-decoration: none;

            * {
              color: $colorRedDark;
            }

            p {
              &,
              span {
                color: $colorRedLight;
              }
            }
          }
        }

        .sv-search-marked {
          color: $colorRedVeryDark;
        }
      }
    }
  }

  .plf-search-paging {
    padding-top: $searchSpacerBig;

    * {
      color: $colorGrayAlmostDark;
      font-family: $sbb;
      font-size: $fontSizeSearchPaging;
    }

    a {
      text-decoration: none;

      &:hover {
        color: $colorRedDark;
      }
    }
  }
}

@media (max-width: $screenSmMax) {
  @import 'mobile/search';
}
