.systeminfo {
  .systeminfo-entry {
    clear: both;
    display: block;

    .systeminfo-title {
      font-size: rem(18);
      font-weight: bold;
    }

    .systeminfo-entry-title {
      color: $colorGrayMedium;
      font-size: rem(15);
      font-weight: normal;
    }

    .systeminfo-entry-content {
      ul,
      ol {
        li {
          padding-bottom: rem(10);
          padding-left: rem(15);
        }
      }
    }

    @media (max-width: $screen-sm-max) {
      ul,
      ol {
        max-width: rem(400);
        padding-left: rem(18);
      }
    }

    + .systeminfo-entry {
      margin-top: rem(30);
    }
  }

  .systeminfo-buttons-container {
    float: right;
    margin-left: $content-padding/2;
    margin-right: $content-padding/2;
    width: auto;

    .systeminfo-buttons {
      background: $colorRed;
      border: 0;
      height: rem(36);
      line-height: rem(36);
      outline: 0;

      &,
      * {
        color: $colorWhite;
      }
    }

    .systeminfo-ok {
      min-width: rem(100);

      &:hover {
        background: $colorRedDark;
      }
    }

    .systeminfo-show-later {
      background-color: $colorGrayLight;
      border: 0;
      color: $colorGrayDark;
      min-width: rem(143);
      outline: 0;

      &:hover {
        background: $colorAlmostGray;
      }
    }
  }
}
