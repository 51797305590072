
&.plf-xl {
  .plf-dashboard-tile {
    .plf-dashboard-tile-meta-wrapper {
      .plf-dashboard-tile-meta {
        .plf-dashboard-tile-number {
          font-size: $fontSizeDashboardNumber + rem(4);
        }

        .plf-dashboard-tile-unit {
          font-size: $fontSizeDashboardUnit  + rem(4);
        }
      }
    }
  }
}

&.plf-l {
  .plf-dashboard-tile {
    .plf-dashboard-tile-meta-wrapper {
      .plf-dashboard-tile-meta {
        .plf-dashboard-tile-number {
          font-size: $fontSizeDashboardNumber;
        }

        .plf-dashboard-tile-unit {
          font-size: $fontSizeDashboardUnit;
        }
      }
    }
  }
}

&.plf-m {
  .plf-dashboard-tile {
    .plf-dashboard-tile-meta-wrapper {
      .plf-dashboard-tile-meta {
        .plf-dashboard-tile-number {
          font-size: $fontSizeDashboardNumber - rem(4);
        }

        .plf-dashboard-tile-unit {
          font-size: $fontSizeDashboardUnit - rem(2);
        }
      }
    }
  }
}

&.plf-s {
  .plf-dashboard-tile {
    .plf-dashboard-tile-meta-wrapper {
      .plf-dashboard-tile-meta {
        .plf-dashboard-tile-number {
          font-size: $fontSizeDashboardNumber - rem(8);
        }

        .plf-dashboard-tile-unit {
          font-size: $fontSizeDashboardUnit - rem(4);
        }
      }
    }
  }
}

&.plf-bw {
  .plf-dashboard-tile {
    .plf-dashboard-tile-meta-wrapper {
      @include gradient();

      .plf-dashboard-tile-meta {
        span {
          color: $colorWhite;
        }
      }
    }
  }
}

&.plf-wb {
  .plf-dashboard-tile {
    .plf-dashboard-tile-meta-wrapper {
      @include gradient($colorWhite);

      .plf-dashboard-tile-meta {
        span {
          color: $colorBlack;
        }
      }
    }
  }
}

&.plf-w {
  .plf-dashboard-tile {
    .plf-dashboard-tile-meta-wrapper {
      .plf-dashboard-tile-meta {
        span {
          color: $colorWhite;
        }
      }
    }
  }
}

&.plf-b {
  .plf-dashboard-tile {
    .plf-dashboard-tile-meta-wrapper {
      @include gradient(transparent);

      .plf-dashboard-tile-meta {
        span {
          color: $colorBlack;
        }
      }
    }
  }
}
