@mixin block-margin {
  margin: 1em 0 0;
}

h1,
.h1 {
  font-size: $font-size-h1;
  font-weight: $font-thin;
  line-height: 1.2;

  @include media-breakpoint-up(mobile) {
    font-size: $font-size-h1-mobile;
  }

  @include media-breakpoint-up(tablet) {
    font-size: $font-size-h1-tablet;
  }
}

h2,
.h2 {
  font-size: $font-size-h2;
  font-weight: $font-light;
  line-height: 1.2;

  @include media-breakpoint-up(tablet) {
    font-size: $font-size-h2-tablet;
  }
}

h3,
.h3 {
  @include font-h3;
}

h4,
.h4 {
  font-size: 1em;
  font-weight: $font-bold;
  line-height: 1.5;
}

p,
ul,
ol {
  @include block-margin;
}

input,
select,
textarea,
button {
  font: inherit;
}

a {
  @include link;
}

b,
strong {
  font-weight: 700;
}
