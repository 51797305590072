$button-min-width: rem(60);
$button-padding-x: em(40, 15);
$button-arrow-padding-x: em(10, 15);

.a-btn {
  background-color: $color-silver;
  border: em(1, 15) solid $color-silver;
  border-radius: em(2, 15);
  color: $color-iron;
  cursor: pointer;
  display: inline-block;
  min-width: $button-min-width;
  overflow: hidden;
  padding: em(10, 15) $button-padding-x;
  position: relative;
  text-align: center;
  text-decoration: none !important;
  width: 100%;

  @include media-breakpoint-up(mobile) {
    width: auto;
  }

  > .a-icon {
    margin-top: em(12, 15) * -1;
    position: absolute;
    top: 50%;
  }

  .a-arrows-cta {
    color: currentColor !important;
    margin: 0 ($button-arrow-padding-x * -1);
    padding-left: $button-arrow-padding-x;

    > .a-icon {
      color: currentColor;
    }
  }

  .no-touchdevice & {
    @include hover {
      .a-arrows-cta {
        @include arrows-animation($button-arrow-padding-x);
      }
    }
  }

  &:focus {
    .a-arrows-cta {
      @include arrows-animation(em(10, 15));
    }
  }

  &--primary {
    background-color: $color-red;
    border-color: $color-red;
    color: $color-white;

    @mixin hover-animation() {
      background-color: $color-red-125;
      border-color: $color-red-125;
      color: $color-white;
    }

    @include hover {
      @include hover-animation;
    }

    &:focus {
      @include hover-animation;
    }
  }

  &--search {
    @extend .a-btn--primary;
    border-radius: 0;
    height: 100%;
    min-width: auto;
    padding: 0;
    width: 100%;

    > .a-icon {
      left: 50%;
      margin-left: em(12, 15) * -1;
    }
  }

  &--footer {
    background-color: $color-granite;
    border-color: $color-granite;
    color: $color-white;
    display: block;
    width: 100%;

    @mixin hover-animation() {
      background-color: $color-red-125;
      border-color: $color-red-125;
      color: $color-white;
    }

    @include hover {
      @include hover-animation;
    }

    &:focus {
      @include hover-animation;
    }
  }

  &--icon {
    background: none;
    border: 0;
    color: $color-metal;
    display: flex;
    min-width: initial;
    padding: 0;
    width: initial;

    > .a-icon {
      margin: 0;
      position: static;
    }

    &:hover,
    &:focus {
      color: $color-red;
    }

    &__label {
      padding-right: rem(10);
    }
  }
}
